import {createStyles} from '@mantine/core'

export const useStyles = createStyles(() => ({
    formCol: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: 16,
    },
}))
