import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActions, useAppSelector } from '@/store/hooks';
import ArticleItem from './ArticleItem/ArticleItem';
import styles from './ArticleList.module.scss';
import { useTranslation } from 'react-i18next';

const ArticleList: FC = () => {
  const { fetchArticles } = useActions();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { articles, isLoading, isError, errorMessage } = useAppSelector((state) => state.articles.articleList);

  useEffect(() => {
    fetchArticles();
  }, []);

  return (
    <div className={styles.articleList}>
      <h1>{t('articleList.title')}</h1>
      {articles.length === 0 ? (
        <p className={styles.noArticlesMessage}>{t('articleList.noArticles')}</p>
      ) : (
        <div className={styles.articleContainer}>
          {articles.map((article: any) => (
            <ArticleItem 
              key={article.id} 
              article={article} 
              onReadMore={() => navigate(`/article/${article.id}`)}
            />
          ))}
        </div>
      )}
      {isError && <p className={styles.errorMessage}>{errorMessage || t('articleList.error')}</p>}
    </div>
  );
};

export default ArticleList;