import React, {FC} from 'react'
import {Box, Footer, Group, Text} from '@mantine/core'

const LayoutFooter: FC = () => {
    return (
        <Footer height='auto' p='md'>
            <Box mx='auto' display={'flex'} style={{justifyContent: 'center'}}>
                <Group position='center' spacing='xs'>
                    <Text size='sm'>© Авторизация. Личный кабинет</Text>
                </Group>
            </Box>
        </Footer>
    )
}

export default LayoutFooter
