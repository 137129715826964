import {useEffect, useState} from 'react'

import { RolesId} from '@/constants/roles/roles'
import {
    FORBIDDEN_ADMIN,
    FORBIDDEN_SUPERVISOR,
} from '@/routes/routes'

import {useAppSelector} from '@/store/hooks'

export const useGetForbiddenPaths = () => {
    const [forbiddenElements, setForbiddenElements] = useState<string[]>([])
    const {user} = useAppSelector((state) => state.auth)

    const handleForbiddenPath = (path: string) => {
        return !forbiddenElements.find((value) => path.includes(value))
    }

    useEffect(() => {
        if (user?.user_info.role_id === RolesId.super_admin) setForbiddenElements(FORBIDDEN_SUPERVISOR)
        if (user?.user_info.role_id === RolesId.admin) setForbiddenElements(FORBIDDEN_ADMIN)
    }, [user?.user_info.role_id])

    return {forbiddenElements, handleForbiddenPath}
}

export default useGetForbiddenPaths
