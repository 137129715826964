import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createArticle } from './actions';
import { IBaseStateField } from '@/types/type';

interface ArticleFormState extends IBaseStateField {
  errorMessage: string;
}

const initialState: ArticleFormState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
};

export const articleFormSlice = createSlice({
  name: 'articleForm',
  initialState,
  reducers: {
    resetArticleFormState: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = '';
    },
  },
  extraReducers: {
    [createArticle.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = '';
    },
    [createArticle.fulfilled.type]: (state) => {
      state.isLoading = false;
      state.isSuccess = true;
    },
    [createArticle.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload || 'Ошибка при создании статьи';
    },
  },
});

export const { resetArticleFormState } = articleFormSlice.actions;
export default articleFormSlice.reducer;