import {JwtPayload} from '@/types/type'

import {FC, useEffect} from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {Center, Loader} from '@mantine/core'
import jwtDecode from 'jwt-decode'

import {RolesId} from '@/constants/roles/roles'

import {AppPaths} from './routes-enums'

import {logoutUser} from '@/store/entities/auth/authSlice'
import {useAppDispatch, useAppSelector} from '@/store/hooks'

interface Props {
    children: JSX.Element
}

export const AdminRoutes: FC<Props> = ({children}) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const {isLoading, isSuccessProfileLoad, token} = useAppSelector((state) => state.auth)
    useEffect(() => {
        if (isSuccessProfileLoad && token) {
            const user = jwtDecode<JwtPayload>(token)
            if (user?.user_info.role_id === RolesId.worker) {
                navigate(AppPaths.HOME)
            }
        }
    }, [isSuccessProfileLoad, token])
    if (isLoading) {
        return (
            <Center style={{height: '100vh'}}>
                <Loader variant='dots' />
            </Center>
        )
    }

    if (!token) {
        dispatch(logoutUser())
    }

    return token ? children : <Navigate to={AppPaths.LOGIN} />
}
