import React, {FC} from 'react'
import {ColorScheme, ColorSchemeProvider, MantineProvider} from '@mantine/core'
import {useLocalStorage} from '@mantine/hooks'
import {NotificationsProvider} from '@mantine/notifications'

import {appTheme} from '@/constants/mantine/appTheme'

interface MantineProvidersProps {
    children: React.ReactNode
}

const MantineProviders: FC<MantineProvidersProps> = ({children}) => {
    const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
        key: 'mantine-color-scheme',
        defaultValue: 'light',
        getInitialValueInEffect: true,
    })

    const toggleColorScheme = (value?: ColorScheme) =>
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

    return (
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                theme={{
                    ...appTheme,
                    colorScheme,
                    fontFamily: 'Gilroy, sans-serif',
                    headings: {fontFamily: 'Gilroy, sans-serif', fontWeight: 500},
                }}
            >
                <NotificationsProvider>{children}</NotificationsProvider>
            </MantineProvider>
        </ColorSchemeProvider>
    )
}

export default MantineProviders
