import React, { FC } from 'react';
import styles from './ArticleItem.module.scss';
import { useTranslation } from 'react-i18next';

interface ArticleProps {
  article: {
    id: number;
    title: string;
    content: string;
  };
  onReadMore: (id: number) => void;
}

const max_characters = 150;

const ArticleItem: FC<ArticleProps> = ({ article, onReadMore }) => {
  const { t } = useTranslation();

  const shortContent =
    article.content.length > max_characters
      ? article.content.substring(0, max_characters) + '...'
      : article.content;

  return (
    <div className={styles.articleItem}>
      <h2>{article.title}</h2>
      <p className={styles.shortContent}>{shortContent}</p>
      <button className={styles.linkButton} onClick={() => onReadMore(article.id)}>
        {t('articleItem.readMore')}
      </button>
    </div>
  );
};

export default ArticleItem;