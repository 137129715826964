import {Api} from '@/api/apiService'
import {createAsyncThunk} from '@reduxjs/toolkit'
import {AxiosError} from 'axios'

export const getBalance = createAsyncThunk('balance/getBalance', async (arg, thunkAPI) => {
    try {
        const response = await Api.Balance.getUserBalanceBalanceGet()
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})
