import {FC} from 'react'

import CenterPage from '@/ui/CenterPage/CenterPage'

import RegistrationForm from '@/pages/Registration/RegistrationForm/RegistrationForm'

const Registration: FC = () => {
    return(
        <CenterPage>
            <RegistrationForm />
        </CenterPage>
    )
}

export default Registration
