import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useActions, useAppSelector } from '@/store/hooks';
import styles from './Article.module.scss';
import { useTranslation } from 'react-i18next';

const Article: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { fetchArticleById } = useActions();
  const { article, isLoading, isError } = useAppSelector((state) => state.articles.article);
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      fetchArticleById(id);
    }
  }, [id]);

  if (isError || !article) {
    return <p className={styles.errorMessage}>{t('article.notFound')}</p>;
  }

  return (
    <div className={styles.article}>
      <h1>{article.title}</h1>
      <p>{article.content}</p>
    </div>
  );
};

export default Article;