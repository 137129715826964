import {createAsyncThunk} from '@reduxjs/toolkit'
import {AxiosError} from 'axios'

import {Api} from '@/api/apiService'
import {
    ConfirmTokenData,
    EmailRegisterData,
    LoginData,
    ResendTokenData,
} from '@/api/authApi'

export const login = createAsyncThunk('auth/login', async (arg: LoginData, thunkAPI) => {
    try {
        const response = await Api.Auth.loginAuthLoginPost({
            email: arg.email,
            password: arg.password,
        })
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const registration = createAsyncThunk('auth/register', async (arg: EmailRegisterData, thunkAPI) => {
    try {
        const response = await Api.Registration.registrationRegistrationPost({
            email: arg.email,
            password: arg.password,
            repeat_password: arg.repeat_password
        })
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})

export const validateTokenConfirmEmail = createAsyncThunk(
    'auth/validateTokenConfirmEmail',
    async (arg: ConfirmTokenData, thunkAPI) => {
        try {
            const response = await Api.Registration.confirmationRegistrationConfirmationCodePatch(
                arg.token
            )
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)

export const resendTokenConfirmEmail = createAsyncThunk(
    'auth/resendTokenConfirmEmail',
    async (arg: ResendTokenData, thunkAPI) => {
        try {
            const response = await Api.Registration.resendCodeRegistrationResendCodePost({
                email: arg.email
            })
            return response.data
        } catch (e) {
            const error = e as AxiosError
            if (error.response?.data) {
                return thunkAPI.rejectWithValue(error.response.data)
            } else {
                return thunkAPI.rejectWithValue(error.response?.statusText)
            }
        }
    }
)

// export const validateTokenResetEmail = createAsyncThunk(
//     'auth/validateTokenResetEmail',
//     async (arg: ConfirmTokenData, thunkAPI) => {
//         try {
//             const response = await Api.Auth.validateResetTokenAuthResetPasswordResetTokenGet(
//                 arg.token
//             )
//             return response.data
//         } catch (e) {
//             const error = e as AxiosError
//             if (error.response?.data) {
//                 return thunkAPI.rejectWithValue(error.response.data)
//             } else {
//                 return thunkAPI.rejectWithValue(error.response?.statusText)
//             }
//         }
//     }
// )

// export const setNewPassword = createAsyncThunk(
//     'auth/setNewPassword',
//     async (arg: SetNewPasswordData, thunkAPI) => {
//         try {
//             const response = await Api.Auth.resetPasswordAuthResetPasswordResetTokenPost(
//                 arg.token,
//                 {
//                     password1: arg.password1,
//                     password2: arg.password2,
//                 }
//             )
//             return response.data
//         } catch (e) {
//             const error = e as AxiosError
//             if (error.response?.data) {
//                 return thunkAPI.rejectWithValue(error.response.data)
//             } else {
//                 return thunkAPI.rejectWithValue(error.response?.statusText)
//             }
//         }
//     }
// )

// export const logoutUserAction = createAsyncThunk('auth/logout', async (arg, thunkAPI) => {
//     try {
//         const response = await Api.Auth.logoutAuthLogoutGet()
//         return response.data
//     } catch (e) {
//         const error = e as AxiosError
//         if (error.response?.data) {
//             return thunkAPI.rejectWithValue(error.response.data)
//         } else {
//             return thunkAPI.rejectWithValue(error.response?.statusText)
//         }
//     }
// })

// export const resetPasswordSendEmail = createAsyncThunk(
//     'auth/resetPasswordSendEmail',
//     async (arg: ResetPasswordData, thunkAPI) => {
//         try {
//             const response = await Api.Auth.resetAuthResetGet(arg.email)
//             return response.data
//         } catch (e) {
//             const error = e as AxiosError
//             if (error.response?.data) {
//                 return thunkAPI.rejectWithValue(error.response.data)
//             } else {
//                 return thunkAPI.rejectWithValue(error.response?.statusText)
//             }
//         }
//     }
// )
