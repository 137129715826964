

import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {
 getPaymentMethod
} from './actions'

import { IPayment, IPaymentsResponse } from '@/store/actionTypes/payments'
import { PaymentsState } from '@/store/actionTypes/payments'

const initialState: PaymentsState = {
    payments: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: null,
    pagination: null,
    query: '',
}

export const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
    },
    extraReducers: {
        [getPaymentMethod.pending.type]: (state) => {
            state.isLoading = true
            state.isError = false
        },
        [getPaymentMethod.fulfilled.type]: (state, action: PayloadAction<IPaymentsResponse<IPayment>>) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.payments = action.payload.data.list
            state.pagination = action.payload.pagination
        },
        [getPaymentMethod.rejected.type]: (state) => {
            state.isError = true
            state.isLoading = false
            state.isSuccess = false
        },
    }
})

export default paymentsSlice.reducer