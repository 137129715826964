/* tslint:disable */
/* eslint-disable */
/**
 * Auth0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BasicUser
 */
export interface BasicUser {
    /**
     * User\'s phone number
     * @type {any}
     * @memberof BasicUser
     */
    'phone'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof BasicUser
     */
    'email'?: any | null;
    /**
     * User\'s email verification status
     * @type {any}
     * @memberof BasicUser
     */
    'email_verified'?: any;
    /**
     * User\'s phone verification status
     * @type {any}
     * @memberof BasicUser
     */
    'phone_verified'?: any;
    /**
     * User\'s role (worker, admin, super_admin) [from 1 to 3]
     * @type {any}
     * @memberof BasicUser
     */
    'role_id'?: any | null;
}
/**
 * 
 * @export
 * @interface ClientCreationResponse
 */
export interface ClientCreationResponse {
    /**
     * 
     * @type {any}
     * @memberof ClientCreationResponse
     */
    'client_id': any;
    /**
     * 
     * @type {any}
     * @memberof ClientCreationResponse
     */
    'client_secret': any;
}
/**
 * 
 * @export
 * @interface ClientInfoResponse
 */
export interface ClientInfoResponse {
    /**
     * 
     * @type {any}
     * @memberof ClientInfoResponse
     */
    'success'?: any;
    /**
     * 
     * @type {BasicUser}
     * @memberof ClientInfoResponse
     */
    'result': BasicUser;
}
/**
 * 
 * @export
 * @interface ClientListResponse
 */
export interface ClientListResponse {
    /**
     * 
     * @type {any}
     * @memberof ClientListResponse
     */
    'success'?: any;
    /**
     * 
     * @type {PageClientResponse}
     * @memberof ClientListResponse
     */
    'result': PageClientResponse;
}
/**
 * 
 * @export
 * @interface ClientResponse
 */
export interface ClientResponse {
    /**
     * 
     * @type {any}
     * @memberof ClientResponse
     */
    'client_id': any;
    /**
     * 
     * @type {any}
     * @memberof ClientResponse
     */
    'application_name': any;
    /**
     * 
     * @type {any}
     * @memberof ClientResponse
     */
    'homepage_url': any;
    /**
     * 
     * @type {any}
     * @memberof ClientResponse
     */
    'authorization_callback_url': any;
    /**
     * 
     * @type {any}
     * @memberof ClientResponse
     */
    'created_at': any;
    /**
     * 
     * @type {any}
     * @memberof ClientResponse
     */
    'updated_at': any;
}
/**
 * 
 * @export
 * @interface ClientUpdateRequest
 */
export interface ClientUpdateRequest {
    /**
     * 
     * @type {any}
     * @memberof ClientUpdateRequest
     */
    'application_name': any;
    /**
     * 
     * @type {any}
     * @memberof ClientUpdateRequest
     */
    'homepage_url': any;
    /**
     * 
     * @type {any}
     * @memberof ClientUpdateRequest
     */
    'authorization_callback_url': any;
}
/**
 * 
 * @export
 * @interface ClientUpdateResponse
 */
export interface ClientUpdateResponse {
    /**
     * 
     * @type {any}
     * @memberof ClientUpdateResponse
     */
    'client_id': any;
    /**
     * 
     * @type {any}
     * @memberof ClientUpdateResponse
     */
    'application_name': any;
    /**
     * 
     * @type {any}
     * @memberof ClientUpdateResponse
     */
    'homepage_url': any;
    /**
     * 
     * @type {any}
     * @memberof ClientUpdateResponse
     */
    'authorization_callback_url': any;
}
/**
 * 
 * @export
 * @interface ConfirmationCodeResponseScheme
 */
export interface ConfirmationCodeResponseScheme {
    /**
     * 
     * @type {any}
     * @memberof ConfirmationCodeResponseScheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ConfirmationCodeResponseScheme
     */
    'message'?: any;
    /**
     * 
     * @type {ResetCodeSchemaOut}
     * @memberof ConfirmationCodeResponseScheme
     */
    'result': ResetCodeSchemaOut;
}
/**
 * 
 * @export
 * @interface CreateUserScheme
 */
export interface CreateUserScheme {
    /**
     * User\'s phone number
     * @type {any}
     * @memberof CreateUserScheme
     */
    'phone'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof CreateUserScheme
     */
    'email'?: any | null;
    /**
     * User\'s email verification status
     * @type {any}
     * @memberof CreateUserScheme
     */
    'email_verified'?: any;
    /**
     * User\'s phone verification status
     * @type {any}
     * @memberof CreateUserScheme
     */
    'phone_verified'?: any;
    /**
     * User\'s role (worker, admin, super_admin) [from 1 to 3]
     * @type {any}
     * @memberof CreateUserScheme
     */
    'role_id'?: any | null;
    /**
     * User\'s deleted status: delete or not
     * @type {any}
     * @memberof CreateUserScheme
     */
    'deleted_at'?: any | null;
    /**
     * User\'s created status: delete or not
     * @type {any}
     * @memberof CreateUserScheme
     */
    'created_at'?: any | null;
    /**
     * User\'s updated status: delete or not
     * @type {any}
     * @memberof CreateUserScheme
     */
    'updated_at'?: any | null;
    /**
     * User\'s password
     * @type {any}
     * @memberof CreateUserScheme
     */
    'password'?: any | null;
}
/**
 * 
 * @export
 * @interface EditUserScheme
 */
export interface EditUserScheme {
    /**
     * User\'s phone number
     * @type {any}
     * @memberof EditUserScheme
     */
    'phone'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof EditUserScheme
     */
    'email'?: any | null;
    /**
     * User\'s email verification status
     * @type {any}
     * @memberof EditUserScheme
     */
    'email_verified'?: any;
    /**
     * User\'s phone verification status
     * @type {any}
     * @memberof EditUserScheme
     */
    'phone_verified'?: any;
    /**
     * User\'s role (worker, admin, super_admin) [from 1 to 3]
     * @type {any}
     * @memberof EditUserScheme
     */
    'role_id'?: any | null;
}
/**
 * 
 * @export
 * @interface EmailConfirmationOutScheme
 */
export interface EmailConfirmationOutScheme {
    /**
     * 
     * @type {any}
     * @memberof EmailConfirmationOutScheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof EmailConfirmationOutScheme
     */
    'message'?: any;
    /**
     * 
     * @type {EmailConfirmationScheme}
     * @memberof EmailConfirmationOutScheme
     */
    'result': EmailConfirmationScheme;
}
/**
 * 
 * @export
 * @interface EmailConfirmationScheme
 */
export interface EmailConfirmationScheme {
    /**
     * 
     * @type {any}
     * @memberof EmailConfirmationScheme
     */
    'email': any;
    /**
     * 
     * @type {any}
     * @memberof EmailConfirmationScheme
     */
    'email_verified': any;
}
/**
 * 
 * @export
 * @interface EmailLoginScheme
 */
export interface EmailLoginScheme {
    /**
     * 
     * @type {any}
     * @memberof EmailLoginScheme
     */
    'email': any;
    /**
     * 
     * @type {any}
     * @memberof EmailLoginScheme
     */
    'password': any;
}
/**
 * 
 * @export
 * @interface EmailRecoverySchema
 */
export interface EmailRecoverySchema {
    /**
     * 
     * @type {any}
     * @memberof EmailRecoverySchema
     */
    'email': any;
}
/**
 * 
 * @export
 * @interface EmailRegisterScheme
 */
export interface EmailRegisterScheme {
    /**
     * 
     * @type {any}
     * @memberof EmailRegisterScheme
     */
    'email': any;
    /**
     * 
     * @type {any}
     * @memberof EmailRegisterScheme
     */
    'password': any;
    /**
     * 
     * @type {any}
     * @memberof EmailRegisterScheme
     */
    'repeat_password': any;
}
/**
 * 
 * @export
 * @interface EmailResendCodeScheme
 */
export interface EmailResendCodeScheme {
    /**
     * 
     * @type {any}
     * @memberof EmailResendCodeScheme
     */
    'email': any;
}
/**
 * 
 * @export
 * @interface ExceptionScheme
 */
export interface ExceptionScheme {
    /**
     * 
     * @type {any}
     * @memberof ExceptionScheme
     */
    'detail': any;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {any}
     * @memberof HTTPValidationError
     */
    'detail'?: any;
}
/**
 * 
 * @export
 * @interface JWTScheme
 */
export interface JWTScheme {
    /**
     * 
     * @type {any}
     * @memberof JWTScheme
     */
    'auth_token': any;
    /**
     * 
     * @type {any}
     * @memberof JWTScheme
     */
    'refresh_token': any;
}
/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {any}
     * @memberof LoginResponse
     */
    'auth_token': any;
    /**
     * 
     * @type {any}
     * @memberof LoginResponse
     */
    'refresh_token': any;
    /**
     * 
     * @type {any}
     * @memberof LoginResponse
     */
    'continue_uri': any | null;
}
/**
 * 
 * @export
 * @interface OAuthTokenResponse
 */
export interface OAuthTokenResponse {
    /**
     * 
     * @type {any}
     * @memberof OAuthTokenResponse
     */
    'state': any;
    /**
     * 
     * @type {any}
     * @memberof OAuthTokenResponse
     */
    'redirect_uri': any;
    /**
     * 
     * @type {any}
     * @memberof OAuthTokenResponse
     */
    'token_type': any;
    /**
     * 
     * @type {any}
     * @memberof OAuthTokenResponse
     */
    'scope': any;
    /**
     * 
     * @type {JWTScheme}
     * @memberof OAuthTokenResponse
     */
    'user_tokens': JWTScheme;
}
/**
 * 
 * @export
 * @interface PageClientResponse
 */
export interface PageClientResponse {
    /**
     * 
     * @type {any}
     * @memberof PageClientResponse
     */
    'items': any;
    /**
     * 
     * @type {any}
     * @memberof PageClientResponse
     */
    'total': any | null;
    /**
     * 
     * @type {any}
     * @memberof PageClientResponse
     */
    'page': any | null;
    /**
     * 
     * @type {any}
     * @memberof PageClientResponse
     */
    'size': any | null;
    /**
     * 
     * @type {any}
     * @memberof PageClientResponse
     */
    'pages'?: any | null;
}
/**
 * 
 * @export
 * @interface PageUserScheme
 */
export interface PageUserScheme {
    /**
     * 
     * @type {any}
     * @memberof PageUserScheme
     */
    'items': any;
    /**
     * 
     * @type {any}
     * @memberof PageUserScheme
     */
    'total': any | null;
    /**
     * 
     * @type {any}
     * @memberof PageUserScheme
     */
    'page': any | null;
    /**
     * 
     * @type {any}
     * @memberof PageUserScheme
     */
    'size': any | null;
    /**
     * 
     * @type {any}
     * @memberof PageUserScheme
     */
    'pages'?: any | null;
}
/**
 * 
 * @export
 * @interface PasswordSchema
 */
export interface PasswordSchema {
    /**
     * 
     * @type {any}
     * @memberof PasswordSchema
     */
    'password': any;
    /**
     * 
     * @type {any}
     * @memberof PasswordSchema
     */
    'repeat_password': any;
}
/**
 * 
 * @export
 * @interface PhoneCodeLoginScheme
 */
export interface PhoneCodeLoginScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneCodeLoginScheme
     */
    'phone_number': any;
    /**
     * 
     * @type {any}
     * @memberof PhoneCodeLoginScheme
     */
    'code': any;
}
/**
 * 
 * @export
 * @interface PhoneCodeScheme
 */
export interface PhoneCodeScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneCodeScheme
     */
    'code': any;
}
/**
 * 
 * @export
 * @interface PhoneConfirmationOutScheme
 */
export interface PhoneConfirmationOutScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneConfirmationOutScheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof PhoneConfirmationOutScheme
     */
    'message'?: any;
    /**
     * 
     * @type {PhoneConfirmationScheme}
     * @memberof PhoneConfirmationOutScheme
     */
    'result': PhoneConfirmationScheme;
}
/**
 * 
 * @export
 * @interface PhoneConfirmationScheme
 */
export interface PhoneConfirmationScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneConfirmationScheme
     */
    'phone': any;
    /**
     * 
     * @type {any}
     * @memberof PhoneConfirmationScheme
     */
    'phone_verified': any;
}
/**
 * 
 * @export
 * @interface PhoneLoginScheme
 */
export interface PhoneLoginScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneLoginScheme
     */
    'phone_number': any;
    /**
     * 
     * @type {any}
     * @memberof PhoneLoginScheme
     */
    'password': any;
}
/**
 * 
 * @export
 * @interface PhoneRecoverySchema
 */
export interface PhoneRecoverySchema {
    /**
     * 
     * @type {any}
     * @memberof PhoneRecoverySchema
     */
    'phone': any;
}
/**
 * 
 * @export
 * @interface PhoneRegisterScheme
 */
export interface PhoneRegisterScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneRegisterScheme
     */
    'phone': any;
    /**
     * 
     * @type {any}
     * @memberof PhoneRegisterScheme
     */
    'password': any;
    /**
     * 
     * @type {any}
     * @memberof PhoneRegisterScheme
     */
    'repeat_password': any;
}
/**
 * 
 * @export
 * @interface PhoneResendCodeScheme
 */
export interface PhoneResendCodeScheme {
    /**
     * 
     * @type {any}
     * @memberof PhoneResendCodeScheme
     */
    'phone': any;
}
/**
 * 
 * @export
 * @interface RedirectModel
 */
export interface RedirectModel {
    /**
     * 
     * @type {any}
     * @memberof RedirectModel
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface ResetCodeSchemaOut
 */
export interface ResetCodeSchemaOut {
    /**
     * 
     * @type {any}
     * @memberof ResetCodeSchemaOut
     */
    'expired_at': any;
}
/**
 * 
 * @export
 * @interface Response200Scheme
 */
export interface Response200Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response200Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response200Scheme
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response400Scheme
 */
export interface Response400Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response400Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response400Scheme
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response401Scheme
 */
export interface Response401Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response401Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response401Scheme
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response403Scheme
 */
export interface Response403Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response403Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response403Scheme
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response404Scheme
 */
export interface Response404Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response404Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response404Scheme
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response422Scheme
 */
export interface Response422Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response422Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response422Scheme
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response500Scheme
 */
export interface Response500Scheme {
    /**
     * 
     * @type {any}
     * @memberof Response500Scheme
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response500Scheme
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface SessionSchema
 */
export interface SessionSchema {
    /**
     * 
     * @type {any}
     * @memberof SessionSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof SessionSchema
     */
    'user_id': any;
    /**
     * 
     * @type {any}
     * @memberof SessionSchema
     */
    'ip': any;
    /**
     * 
     * @type {any}
     * @memberof SessionSchema
     */
    'is_active'?: any;
}
/**
 * 
 * @export
 * @interface SessionsListSchema
 */
export interface SessionsListSchema {
    /**
     * 
     * @type {any}
     * @memberof SessionsListSchema
     */
    'sessions': any;
}
/**
 * 
 * @export
 * @interface UserFilterScheme
 */
export interface UserFilterScheme {
    /**
     * Filter by phone number
     * @type {any}
     * @memberof UserFilterScheme
     */
    'phone'?: any | null;
    /**
     * Filter by email address
     * @type {any}
     * @memberof UserFilterScheme
     */
    'email'?: any | null;
    /**
     * Filter by email verification status
     * @type {any}
     * @memberof UserFilterScheme
     */
    'email_verified'?: any | null;
    /**
     * Filter by phone verification status
     * @type {any}
     * @memberof UserFilterScheme
     */
    'phone_verified'?: any | null;
    /**
     * Filter by user\'s role (worker, admin, super_admin) [from 1 to 3]
     * @type {any}
     * @memberof UserFilterScheme
     */
    'role_id'?: any | null;
    /**
     * Filter by user\'s created status
     * @type {any}
     * @memberof UserFilterScheme
     */
    'created_at'?: any | null;
    /**
     * Filter by user\'s updated status
     * @type {any}
     * @memberof UserFilterScheme
     */
    'updated_at'?: any | null;
}
/**
 * 
 * @export
 * @interface UserListResponseInput
 */
export interface UserListResponseInput {
    /**
     * 
     * @type {any}
     * @memberof UserListResponseInput
     */
    'success'?: any;
    /**
     * 
     * @type {PageUserScheme}
     * @memberof UserListResponseInput
     */
    'result': PageUserScheme;
    /**
     * 
     * @type {UserFilterScheme}
     * @memberof UserListResponseInput
     */
    'filters'?: UserFilterScheme | null;
}
/**
 * 
 * @export
 * @interface UserListResponseOutput
 */
export interface UserListResponseOutput {
    /**
     * 
     * @type {any}
     * @memberof UserListResponseOutput
     */
    'success'?: any;
    /**
     * 
     * @type {PageUserScheme}
     * @memberof UserListResponseOutput
     */
    'result': PageUserScheme;
    /**
     * 
     * @type {UserFilterScheme}
     * @memberof UserListResponseOutput
     */
    'filters'?: UserFilterScheme | null;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * 
     * @type {any}
     * @memberof UserResponse
     */
    'success'?: any;
    /**
     * 
     * @type {UserScheme}
     * @memberof UserResponse
     */
    'result': UserScheme;
}
/**
 * 
 * @export
 * @interface UserScheme
 */
export interface UserScheme {
    /**
     * User\'s phone number
     * @type {any}
     * @memberof UserScheme
     */
    'phone'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof UserScheme
     */
    'email'?: any | null;
    /**
     * User\'s email verification status
     * @type {any}
     * @memberof UserScheme
     */
    'email_verified'?: any;
    /**
     * User\'s phone verification status
     * @type {any}
     * @memberof UserScheme
     */
    'phone_verified'?: any;
    /**
     * User\'s role (worker, admin, super_admin) [from 1 to 3]
     * @type {any}
     * @memberof UserScheme
     */
    'role_id'?: any | null;
    /**
     * User\'s deleted status: delete or not
     * @type {any}
     * @memberof UserScheme
     */
    'deleted_at'?: any | null;
    /**
     * User\'s created status: delete or not
     * @type {any}
     * @memberof UserScheme
     */
    'created_at'?: any | null;
    /**
     * User\'s updated status: delete or not
     * @type {any}
     * @memberof UserScheme
     */
    'updated_at'?: any | null;
    /**
     * User\'s ID
     * @type {any}
     * @memberof UserScheme
     */
    'id': any | null;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {any}
     * @memberof ValidationError
     */
    'loc': any;
    /**
     * 
     * @type {any}
     * @memberof ValidationError
     */
    'msg': any;
    /**
     * 
     * @type {any}
     * @memberof ValidationError
     */
    'type': any;
}

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Closes all user\'s sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeSessionsAuthCloseSessionsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/close-sessions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generates and returns user\'s auth code to use with phone number
         * @param {any} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhoneCodeAuthPhoneCodeGet: async (phoneNumber: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'phoneNumber' is not null or undefined
            assertParamExists('getPhoneCodeAuthPhoneCodeGet', 'phoneNumber', phoneNumber)
            const localVarPath = `/auth/phone-code/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (phoneNumber !== undefined) {
                localVarQueryParameter['phone_number'] = phoneNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of user\'s sessions
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsAuthSessionListGet: async (page?: any, size?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/auth/session-list/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Authenticates user
         * @param {any} body 
         * @param {any | null} [continueUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAuthLoginPost: async (body: any, continueUri?: any | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('loginAuthLoginPost', 'body', body)
            const localVarPath = `/auth/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (continueUri !== undefined) {
                localVarQueryParameter['continue_uri'] = continueUri;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Checks refresh token and returns new tokens
         * @param {any} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokensAuthRefreshPost: async (refreshToken: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('refreshTokensAuthRefreshPost', 'refreshToken', refreshToken)
            const localVarPath = `/auth/refresh/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (refreshToken !== undefined) {
                localVarQueryParameter['refresh_token'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Closes all user\'s sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeSessionsAuthCloseSessionsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeSessionsAuthCloseSessionsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generates and returns user\'s auth code to use with phone number
         * @param {any} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPhoneCodeAuthPhoneCodeGet(phoneNumber: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PhoneCodeScheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPhoneCodeAuthPhoneCodeGet(phoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of user\'s sessions
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSessionsAuthSessionListGet(page?: any, size?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionsListSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSessionsAuthSessionListGet(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Authenticates user
         * @param {any} body 
         * @param {any | null} [continueUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginAuthLoginPost(body: any, continueUri?: any | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginAuthLoginPost(body, continueUri, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Checks refresh token and returns new tokens
         * @param {any} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshTokensAuthRefreshPost(refreshToken: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JWTScheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshTokensAuthRefreshPost(refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Closes all user\'s sessions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeSessionsAuthCloseSessionsPost(options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.closeSessionsAuthCloseSessionsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generates and returns user\'s auth code to use with phone number
         * @param {any} phoneNumber 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPhoneCodeAuthPhoneCodeGet(phoneNumber: any, options?: any): AxiosPromise<PhoneCodeScheme> {
            return localVarFp.getPhoneCodeAuthPhoneCodeGet(phoneNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of user\'s sessions
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSessionsAuthSessionListGet(page?: any, size?: any, options?: any): AxiosPromise<SessionsListSchema> {
            return localVarFp.getSessionsAuthSessionListGet(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Authenticates user
         * @param {any} body 
         * @param {any | null} [continueUri] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAuthLoginPost(body: any, continueUri?: any | null, options?: any): AxiosPromise<LoginResponse> {
            return localVarFp.loginAuthLoginPost(body, continueUri, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Checks refresh token and returns new tokens
         * @param {any} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshTokensAuthRefreshPost(refreshToken: any, options?: any): AxiosPromise<JWTScheme> {
            return localVarFp.refreshTokensAuthRefreshPost(refreshToken, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @summary Closes all user\'s sessions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public closeSessionsAuthCloseSessionsPost(options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).closeSessionsAuthCloseSessionsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generates and returns user\'s auth code to use with phone number
     * @param {any} phoneNumber 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getPhoneCodeAuthPhoneCodeGet(phoneNumber: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getPhoneCodeAuthPhoneCodeGet(phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of user\'s sessions
     * @param {any} [page] 
     * @param {any} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public getSessionsAuthSessionListGet(page?: any, size?: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).getSessionsAuthSessionListGet(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Authenticates user
     * @param {any} body 
     * @param {any | null} [continueUri] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginAuthLoginPost(body: any, continueUri?: any | null, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).loginAuthLoginPost(body, continueUri, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Checks refresh token and returns new tokens
     * @param {any} refreshToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public refreshTokensAuthRefreshPost(refreshToken: any, options?: AxiosRequestConfig) {
        return AuthApiFp(this.configuration).refreshTokensAuthRefreshPost(refreshToken, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ClientUsersApi - axios parameter creator
 * @export
 */
export const ClientUsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Safely deletes the user by their ID from jwt payloads  Args:     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted
         * @summary Deletes the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserClientUsersDelete: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/client/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the user by their id from jwt payload
         * @summary Get the user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserClientUsersGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/client/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user information by user\'s ID from jwt payload  Args:     updated_user (ClientEditUserSchema): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     ClientInfoResponse: The updated user\'s information.
         * @summary Change the user info
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserClientUsersPut: async (body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateUserClientUsersPut', 'body', body)
            const localVarPath = `/client/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientUsersApi - functional programming interface
 * @export
 */
export const ClientUsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientUsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Safely deletes the user by their ID from jwt payloads  Args:     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted
         * @summary Deletes the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserClientUsersDelete(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserClientUsersDelete(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the user by their id from jwt payload
         * @summary Get the user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserClientUsersGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserClientUsersGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user information by user\'s ID from jwt payload  Args:     updated_user (ClientEditUserSchema): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     ClientInfoResponse: The updated user\'s information.
         * @summary Change the user info
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserClientUsersPut(body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserClientUsersPut(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientUsersApi - factory interface
 * @export
 */
export const ClientUsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientUsersApiFp(configuration)
    return {
        /**
         * Safely deletes the user by their ID from jwt payloads  Args:     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted
         * @summary Deletes the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserClientUsersDelete(options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.deleteUserClientUsersDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the user by their id from jwt payload
         * @summary Get the user info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserClientUsersGet(options?: any): AxiosPromise<ClientInfoResponse> {
            return localVarFp.getUserClientUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Update user information by user\'s ID from jwt payload  Args:     updated_user (ClientEditUserSchema): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     ClientInfoResponse: The updated user\'s information.
         * @summary Change the user info
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserClientUsersPut(body: any, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateUserClientUsersPut(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientUsersApi - object-oriented interface
 * @export
 * @class ClientUsersApi
 * @extends {BaseAPI}
 */
export class ClientUsersApi extends BaseAPI {
    /**
     * Safely deletes the user by their ID from jwt payloads  Args:     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted
     * @summary Deletes the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientUsersApi
     */
    public deleteUserClientUsersDelete(options?: AxiosRequestConfig) {
        return ClientUsersApiFp(this.configuration).deleteUserClientUsersDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the user by their id from jwt payload
     * @summary Get the user info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientUsersApi
     */
    public getUserClientUsersGet(options?: AxiosRequestConfig) {
        return ClientUsersApiFp(this.configuration).getUserClientUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user information by user\'s ID from jwt payload  Args:     updated_user (ClientEditUserSchema): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     ClientInfoResponse: The updated user\'s information.
     * @summary Change the user info
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientUsersApi
     */
    public updateUserClientUsersPut(body: any, options?: AxiosRequestConfig) {
        return ClientUsersApiFp(this.configuration).updateUserClientUsersPut(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OAuthApi - axios parameter creator
 * @export
 */
export const OAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Validates auth code from provider and returns user\'s tokens
         * @param {CallbackOauthProviderCallbackGetProviderEnum} provider 
         * @param {any} [state] 
         * @param {any} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackOauthProviderCallbackGet: async (provider: CallbackOauthProviderCallbackGetProviderEnum, state?: any, code?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('callbackOauthProviderCallbackGet', 'provider', provider)
            const localVarPath = `/oauth/{provider}/callback/`
                .replace(`{${"provider"}}`, encodeURIComponent(String(provider)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets authentication code and returns tokens for client
         * @summary Validates auth code and returns tokens
         * @param {any} code 
         * @param {any} [responseType] 
         * @param {any} [clientId] 
         * @param {any} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        exchangeCodeForTokensOauthTokenPost: async (code: any, responseType?: any, clientId?: any, clientSecret?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('exchangeCodeForTokensOauthTokenPost', 'code', code)
            const localVarPath = `/oauth/token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();


            if (responseType !== undefined) { 
                localVarFormParams.set('response_type', responseType as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (code !== undefined) { 
                localVarFormParams.set('code', code as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Writes parameters to session and redirects to login page or returns tokens
         * @param {any} [responseType] 
         * @param {any} [clientId] 
         * @param {any} [redirectUri] 
         * @param {any} [scope] 
         * @param {any} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantAccessOauthGrantAccessGet: async (responseType?: any, clientId?: any, redirectUri?: any, scope?: any, state?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/grant-access/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (responseType !== undefined) {
                localVarQueryParameter['response_type'] = responseType;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirect_uri'] = redirectUri;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This endpoint is protected with external service OAuth2
         * @summary Hello
         * @param {any} [autoError] 
         * @param {any | null} [body] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        helloOauthHelloGet: async (autoError?: any, body?: any | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/hello/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (autoError !== undefined) {
                localVarQueryParameter['auto_error'] = autoError;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns uri for user to redirect to OAuth provider
         * @param {RedirectToProviderOauthLoginGetProviderEnum} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToProviderOauthLoginGet: async (provider: RedirectToProviderOauthLoginGetProviderEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'provider' is not null or undefined
            assertParamExists('redirectToProviderOauthLoginGet', 'provider', provider)
            const localVarPath = `/oauth/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validates refresh token and returns new pair of tokens
         * @param {any} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshOauthRefreshPost: async (refreshToken: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refreshToken' is not null or undefined
            assertParamExists('refreshOauthRefreshPost', 'refreshToken', refreshToken)
            const localVarPath = `/oauth/refresh/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (refreshToken !== undefined) {
                localVarQueryParameter['refresh_token'] = refreshToken;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Validates auth token and returns response with status code 200
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateTokenOauthValidateTokenPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/validate-token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OAuthApi - functional programming interface
 * @export
 */
export const OAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Validates auth code from provider and returns user\'s tokens
         * @param {CallbackOauthProviderCallbackGetProviderEnum} provider 
         * @param {any} [state] 
         * @param {any} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async callbackOauthProviderCallbackGet(provider: CallbackOauthProviderCallbackGetProviderEnum, state?: any, code?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JWTScheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.callbackOauthProviderCallbackGet(provider, state, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets authentication code and returns tokens for client
         * @summary Validates auth code and returns tokens
         * @param {any} code 
         * @param {any} [responseType] 
         * @param {any} [clientId] 
         * @param {any} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async exchangeCodeForTokensOauthTokenPost(code: any, responseType?: any, clientId?: any, clientSecret?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exchangeCodeForTokensOauthTokenPost(code, responseType, clientId, clientSecret, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Writes parameters to session and redirects to login page or returns tokens
         * @param {any} [responseType] 
         * @param {any} [clientId] 
         * @param {any} [redirectUri] 
         * @param {any} [scope] 
         * @param {any} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async grantAccessOauthGrantAccessGet(responseType?: any, clientId?: any, redirectUri?: any, scope?: any, state?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.grantAccessOauthGrantAccessGet(responseType, clientId, redirectUri, scope, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This endpoint is protected with external service OAuth2
         * @summary Hello
         * @param {any} [autoError] 
         * @param {any | null} [body] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async helloOauthHelloGet(autoError?: any, body?: any | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.helloOauthHelloGet(autoError, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns uri for user to redirect to OAuth provider
         * @param {RedirectToProviderOauthLoginGetProviderEnum} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async redirectToProviderOauthLoginGet(provider: RedirectToProviderOauthLoginGetProviderEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.redirectToProviderOauthLoginGet(provider, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validates refresh token and returns new pair of tokens
         * @param {any} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refreshOauthRefreshPost(refreshToken: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JWTScheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refreshOauthRefreshPost(refreshToken, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Validates auth token and returns response with status code 200
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateTokenOauthValidateTokenPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateTokenOauthValidateTokenPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OAuthApi - factory interface
 * @export
 */
export const OAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Validates auth code from provider and returns user\'s tokens
         * @param {CallbackOauthProviderCallbackGetProviderEnum} provider 
         * @param {any} [state] 
         * @param {any} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackOauthProviderCallbackGet(provider: CallbackOauthProviderCallbackGetProviderEnum, state?: any, code?: any, options?: any): AxiosPromise<JWTScheme> {
            return localVarFp.callbackOauthProviderCallbackGet(provider, state, code, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets authentication code and returns tokens for client
         * @summary Validates auth code and returns tokens
         * @param {any} code 
         * @param {any} [responseType] 
         * @param {any} [clientId] 
         * @param {any} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        exchangeCodeForTokensOauthTokenPost(code: any, responseType?: any, clientId?: any, clientSecret?: any, options?: any): AxiosPromise<OAuthTokenResponse> {
            return localVarFp.exchangeCodeForTokensOauthTokenPost(code, responseType, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Writes parameters to session and redirects to login page or returns tokens
         * @param {any} [responseType] 
         * @param {any} [clientId] 
         * @param {any} [redirectUri] 
         * @param {any} [scope] 
         * @param {any} [state] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        grantAccessOauthGrantAccessGet(responseType?: any, clientId?: any, redirectUri?: any, scope?: any, state?: any, options?: any): AxiosPromise<any> {
            return localVarFp.grantAccessOauthGrantAccessGet(responseType, clientId, redirectUri, scope, state, options).then((request) => request(axios, basePath));
        },
        /**
         * This endpoint is protected with external service OAuth2
         * @summary Hello
         * @param {any} [autoError] 
         * @param {any | null} [body] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        helloOauthHelloGet(autoError?: any, body?: any | null, options?: any): AxiosPromise<any> {
            return localVarFp.helloOauthHelloGet(autoError, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns uri for user to redirect to OAuth provider
         * @param {RedirectToProviderOauthLoginGetProviderEnum} provider 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectToProviderOauthLoginGet(provider: RedirectToProviderOauthLoginGetProviderEnum, options?: any): AxiosPromise<any> {
            return localVarFp.redirectToProviderOauthLoginGet(provider, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validates refresh token and returns new pair of tokens
         * @param {any} refreshToken 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshOauthRefreshPost(refreshToken: any, options?: any): AxiosPromise<JWTScheme> {
            return localVarFp.refreshOauthRefreshPost(refreshToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Validates auth token and returns response with status code 200
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateTokenOauthValidateTokenPost(options?: any): AxiosPromise<any> {
            return localVarFp.validateTokenOauthValidateTokenPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OAuthApi - object-oriented interface
 * @export
 * @class OAuthApi
 * @extends {BaseAPI}
 */
export class OAuthApi extends BaseAPI {
    /**
     * 
     * @summary Validates auth code from provider and returns user\'s tokens
     * @param {CallbackOauthProviderCallbackGetProviderEnum} provider 
     * @param {any} [state] 
     * @param {any} [code] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public callbackOauthProviderCallbackGet(provider: CallbackOauthProviderCallbackGetProviderEnum, state?: any, code?: any, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).callbackOauthProviderCallbackGet(provider, state, code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets authentication code and returns tokens for client
     * @summary Validates auth code and returns tokens
     * @param {any} code 
     * @param {any} [responseType] 
     * @param {any} [clientId] 
     * @param {any} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public exchangeCodeForTokensOauthTokenPost(code: any, responseType?: any, clientId?: any, clientSecret?: any, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).exchangeCodeForTokensOauthTokenPost(code, responseType, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Writes parameters to session and redirects to login page or returns tokens
     * @param {any} [responseType] 
     * @param {any} [clientId] 
     * @param {any} [redirectUri] 
     * @param {any} [scope] 
     * @param {any} [state] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public grantAccessOauthGrantAccessGet(responseType?: any, clientId?: any, redirectUri?: any, scope?: any, state?: any, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).grantAccessOauthGrantAccessGet(responseType, clientId, redirectUri, scope, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This endpoint is protected with external service OAuth2
     * @summary Hello
     * @param {any} [autoError] 
     * @param {any | null} [body] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public helloOauthHelloGet(autoError?: any, body?: any | null, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).helloOauthHelloGet(autoError, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns uri for user to redirect to OAuth provider
     * @param {RedirectToProviderOauthLoginGetProviderEnum} provider 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public redirectToProviderOauthLoginGet(provider: RedirectToProviderOauthLoginGetProviderEnum, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).redirectToProviderOauthLoginGet(provider, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validates refresh token and returns new pair of tokens
     * @param {any} refreshToken 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public refreshOauthRefreshPost(refreshToken: any, options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).refreshOauthRefreshPost(refreshToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Validates auth token and returns response with status code 200
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OAuthApi
     */
    public validateTokenOauthValidateTokenPost(options?: AxiosRequestConfig) {
        return OAuthApiFp(this.configuration).validateTokenOauthValidateTokenPost(options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const CallbackOauthProviderCallbackGetProviderEnum = {
    Github: 'Github',
    Google: 'Google',
    Facebook: 'Facebook',
    Instagram: 'Instagram'
} as const;
export type CallbackOauthProviderCallbackGetProviderEnum = typeof CallbackOauthProviderCallbackGetProviderEnum[keyof typeof CallbackOauthProviderCallbackGetProviderEnum];
/**
 * @export
 */
export const RedirectToProviderOauthLoginGetProviderEnum = {
    Github: 'Github',
    Google: 'Google',
    Facebook: 'Facebook',
    Instagram: 'Instagram'
} as const;
export type RedirectToProviderOauthLoginGetProviderEnum = typeof RedirectToProviderOauthLoginGetProviderEnum[keyof typeof RedirectToProviderOauthLoginGetProviderEnum];


/**
 * OauthClientApi - axios parameter creator
 * @export
 */
export const OauthClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Client
         * @param {any} applicationName 
         * @param {any} homepageUrl 
         * @param {any} authorizationCallbackUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientOauthClientPost: async (applicationName: any, homepageUrl: any, authorizationCallbackUrl: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'applicationName' is not null or undefined
            assertParamExists('createClientOauthClientPost', 'applicationName', applicationName)
            // verify required parameter 'homepageUrl' is not null or undefined
            assertParamExists('createClientOauthClientPost', 'homepageUrl', homepageUrl)
            // verify required parameter 'authorizationCallbackUrl' is not null or undefined
            assertParamExists('createClientOauthClientPost', 'authorizationCallbackUrl', authorizationCallbackUrl)
            const localVarPath = `/oauth_client/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (applicationName !== undefined) {
                localVarQueryParameter['application_name'] = applicationName;
            }

            if (homepageUrl !== undefined) {
                localVarQueryParameter['homepage_url'] = homepageUrl;
            }

            if (authorizationCallbackUrl !== undefined) {
                localVarQueryParameter['authorization_callback_url'] = authorizationCallbackUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Client
         * @param {any} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientOauthClientClientIdDelete: async (clientId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('deleteClientOauthClientClientIdDelete', 'clientId', clientId)
            const localVarPath = `/oauth_client/{client_id}`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Client
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientOauthClientGet: async (page?: any, size?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth_client/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Client
         * @param {any} clientId 
         * @param {ClientUpdateRequest} clientUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientOauthClientClientIdPost: async (clientId: any, clientUpdateRequest: ClientUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('updateClientOauthClientClientIdPost', 'clientId', clientId)
            // verify required parameter 'clientUpdateRequest' is not null or undefined
            assertParamExists('updateClientOauthClientClientIdPost', 'clientUpdateRequest', clientUpdateRequest)
            const localVarPath = `/oauth_client/{client_id}`
                .replace(`{${"client_id"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OauthClientApi - functional programming interface
 * @export
 */
export const OauthClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OauthClientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Client
         * @param {any} applicationName 
         * @param {any} homepageUrl 
         * @param {any} authorizationCallbackUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createClientOauthClientPost(applicationName: any, homepageUrl: any, authorizationCallbackUrl: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientCreationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createClientOauthClientPost(applicationName, homepageUrl, authorizationCallbackUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Client
         * @param {any} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteClientOauthClientClientIdDelete(clientId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteClientOauthClientClientIdDelete(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Client
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getClientOauthClientGet(page?: any, size?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getClientOauthClientGet(page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Client
         * @param {any} clientId 
         * @param {ClientUpdateRequest} clientUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateClientOauthClientClientIdPost(clientId: any, clientUpdateRequest: ClientUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateClientOauthClientClientIdPost(clientId, clientUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OauthClientApi - factory interface
 * @export
 */
export const OauthClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OauthClientApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Client
         * @param {any} applicationName 
         * @param {any} homepageUrl 
         * @param {any} authorizationCallbackUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createClientOauthClientPost(applicationName: any, homepageUrl: any, authorizationCallbackUrl: any, options?: any): AxiosPromise<ClientCreationResponse> {
            return localVarFp.createClientOauthClientPost(applicationName, homepageUrl, authorizationCallbackUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Client
         * @param {any} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClientOauthClientClientIdDelete(clientId: any, options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.deleteClientOauthClientClientIdDelete(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Client
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientOauthClientGet(page?: any, size?: any, options?: any): AxiosPromise<ClientListResponse> {
            return localVarFp.getClientOauthClientGet(page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Client
         * @param {any} clientId 
         * @param {ClientUpdateRequest} clientUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClientOauthClientClientIdPost(clientId: any, clientUpdateRequest: ClientUpdateRequest, options?: any): AxiosPromise<ClientUpdateResponse> {
            return localVarFp.updateClientOauthClientClientIdPost(clientId, clientUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OauthClientApi - object-oriented interface
 * @export
 * @class OauthClientApi
 * @extends {BaseAPI}
 */
export class OauthClientApi extends BaseAPI {
    /**
     * 
     * @summary Create Client
     * @param {any} applicationName 
     * @param {any} homepageUrl 
     * @param {any} authorizationCallbackUrl 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthClientApi
     */
    public createClientOauthClientPost(applicationName: any, homepageUrl: any, authorizationCallbackUrl: any, options?: AxiosRequestConfig) {
        return OauthClientApiFp(this.configuration).createClientOauthClientPost(applicationName, homepageUrl, authorizationCallbackUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Client
     * @param {any} clientId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthClientApi
     */
    public deleteClientOauthClientClientIdDelete(clientId: any, options?: AxiosRequestConfig) {
        return OauthClientApiFp(this.configuration).deleteClientOauthClientClientIdDelete(clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Client
     * @param {any} [page] 
     * @param {any} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthClientApi
     */
    public getClientOauthClientGet(page?: any, size?: any, options?: AxiosRequestConfig) {
        return OauthClientApiFp(this.configuration).getClientOauthClientGet(page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Client
     * @param {any} clientId 
     * @param {ClientUpdateRequest} clientUpdateRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OauthClientApi
     */
    public updateClientOauthClientClientIdPost(clientId: any, clientUpdateRequest: ClientUpdateRequest, options?: AxiosRequestConfig) {
        return OauthClientApiFp(this.configuration).updateClientOauthClientClientIdPost(clientId, clientUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PasswordRecoveryApi - axios parameter creator
 * @export
 */
export const PasswordRecoveryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Password update via confirmation code. Args:     confirmation_code: str, code from message.     request: PasswordSchema, two passwords.     db_session: AsyncSession, database session.
         * @summary Password Recovery
         * @param {any} confirmationCode 
         * @param {PasswordSchema} passwordSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecoveryPasswordRecoveryConfirmationCodePatch: async (confirmationCode: any, passwordSchema: PasswordSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationCode' is not null or undefined
            assertParamExists('passwordRecoveryPasswordRecoveryConfirmationCodePatch', 'confirmationCode', confirmationCode)
            // verify required parameter 'passwordSchema' is not null or undefined
            assertParamExists('passwordRecoveryPasswordRecoveryConfirmationCodePatch', 'passwordSchema', passwordSchema)
            const localVarPath = `/password-recovery/{confirmation_code}`
                .replace(`{${"confirmation_code"}}`, encodeURIComponent(String(confirmationCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Password recovery request via email or phone. Args:     credentials: EmailRecoverySchema | PhoneRecoverySchema, user data.     db_session: AsyncSession, database session.
         * @summary Password Recovery Request
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecoveryRequestPasswordRecoveryRequestPost: async (body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('passwordRecoveryRequestPasswordRecoveryRequestPost', 'body', body)
            const localVarPath = `/password-recovery/request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordRecoveryApi - functional programming interface
 * @export
 */
export const PasswordRecoveryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordRecoveryApiAxiosParamCreator(configuration)
    return {
        /**
         * Password update via confirmation code. Args:     confirmation_code: str, code from message.     request: PasswordSchema, two passwords.     db_session: AsyncSession, database session.
         * @summary Password Recovery
         * @param {any} confirmationCode 
         * @param {PasswordSchema} passwordSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordRecoveryPasswordRecoveryConfirmationCodePatch(confirmationCode: any, passwordSchema: PasswordSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordRecoveryPasswordRecoveryConfirmationCodePatch(confirmationCode, passwordSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Password recovery request via email or phone. Args:     credentials: EmailRecoverySchema | PhoneRecoverySchema, user data.     db_session: AsyncSession, database session.
         * @summary Password Recovery Request
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async passwordRecoveryRequestPasswordRecoveryRequestPost(body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfirmationCodeResponseScheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.passwordRecoveryRequestPasswordRecoveryRequestPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordRecoveryApi - factory interface
 * @export
 */
export const PasswordRecoveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordRecoveryApiFp(configuration)
    return {
        /**
         * Password update via confirmation code. Args:     confirmation_code: str, code from message.     request: PasswordSchema, two passwords.     db_session: AsyncSession, database session.
         * @summary Password Recovery
         * @param {any} confirmationCode 
         * @param {PasswordSchema} passwordSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecoveryPasswordRecoveryConfirmationCodePatch(confirmationCode: any, passwordSchema: PasswordSchema, options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.passwordRecoveryPasswordRecoveryConfirmationCodePatch(confirmationCode, passwordSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Password recovery request via email or phone. Args:     credentials: EmailRecoverySchema | PhoneRecoverySchema, user data.     db_session: AsyncSession, database session.
         * @summary Password Recovery Request
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        passwordRecoveryRequestPasswordRecoveryRequestPost(body: any, options?: any): AxiosPromise<ConfirmationCodeResponseScheme> {
            return localVarFp.passwordRecoveryRequestPasswordRecoveryRequestPost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordRecoveryApi - object-oriented interface
 * @export
 * @class PasswordRecoveryApi
 * @extends {BaseAPI}
 */
export class PasswordRecoveryApi extends BaseAPI {
    /**
     * Password update via confirmation code. Args:     confirmation_code: str, code from message.     request: PasswordSchema, two passwords.     db_session: AsyncSession, database session.
     * @summary Password Recovery
     * @param {any} confirmationCode 
     * @param {PasswordSchema} passwordSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordRecoveryApi
     */
    public passwordRecoveryPasswordRecoveryConfirmationCodePatch(confirmationCode: any, passwordSchema: PasswordSchema, options?: AxiosRequestConfig) {
        return PasswordRecoveryApiFp(this.configuration).passwordRecoveryPasswordRecoveryConfirmationCodePatch(confirmationCode, passwordSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Password recovery request via email or phone. Args:     credentials: EmailRecoverySchema | PhoneRecoverySchema, user data.     db_session: AsyncSession, database session.
     * @summary Password Recovery Request
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordRecoveryApi
     */
    public passwordRecoveryRequestPasswordRecoveryRequestPost(body: any, options?: AxiosRequestConfig) {
        return PasswordRecoveryApiFp(this.configuration).passwordRecoveryRequestPasswordRecoveryRequestPost(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RegistrationApi - axios parameter creator
 * @export
 */
export const RegistrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Determine if the confirmation is for email or phone and call the appropriate method.  Args:     confirmation_code: str, confirmation code from email or phone.     db_session: AsyncSession, database session.
         * @summary Confirmation
         * @param {any} confirmationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationRegistrationConfirmationCodePatch: async (confirmationCode: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'confirmationCode' is not null or undefined
            assertParamExists('confirmationRegistrationConfirmationCodePatch', 'confirmationCode', confirmationCode)
            const localVarPath = `/registration/{confirmation_code}`
                .replace(`{${"confirmation_code"}}`, encodeURIComponent(String(confirmationCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registers a new user using either email or phone, depending on the provided data.  Args:     request (EmailRegisterScheme | PhoneRegisterScheme): The registration         data provided by the user. This can either be an email-based         registration scheme or a phone-based registration scheme.     db_session (AsyncSession): The database session used to interact with         the database.     app (str, optional): The name of the application from which the         registration is initiated.  Returns:     Response200Scheme: A response object containing a success message.  Raises:     HTTPException: If the user is already registered, a 400 error is         returned with the relevant exception scheme.
         * @summary Registration
         * @param {any} body 
         * @param {any} [app] Name of the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationRegistrationPost: async (body: any, app?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('registrationRegistrationPost', 'body', body)
            const localVarPath = `/registration/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (app !== undefined) {
                localVarQueryParameter['app'] = app;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Resend a confirmation code to the user via email or phone.  This endpoint handles the resending of confirmation codes based on the user\'s email or phone number. If a valid user is found, it marks the old confirmation code as used and generates a new one. The new code is then sent to the user.  Args:     request (EmailResendCodeScheme | PhoneResendCodeScheme): A request     object containing either email or phone details.     db_session (AsyncSession): The database session dependency.  Raises:     HTTPException: If the user does not exist or if there is an issue     sending the email.  Returns:     Response200Scheme: A response indicating the success of the operation.
         * @summary Resend Code
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendCodeRegistrationResendCodePost: async (body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('resendCodeRegistrationResendCodePost', 'body', body)
            const localVarPath = `/registration/resend_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistrationApi - functional programming interface
 * @export
 */
export const RegistrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistrationApiAxiosParamCreator(configuration)
    return {
        /**
         * Determine if the confirmation is for email or phone and call the appropriate method.  Args:     confirmation_code: str, confirmation code from email or phone.     db_session: AsyncSession, database session.
         * @summary Confirmation
         * @param {any} confirmationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmationRegistrationConfirmationCodePatch(confirmationCode: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmationRegistrationConfirmationCodePatch(confirmationCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Registers a new user using either email or phone, depending on the provided data.  Args:     request (EmailRegisterScheme | PhoneRegisterScheme): The registration         data provided by the user. This can either be an email-based         registration scheme or a phone-based registration scheme.     db_session (AsyncSession): The database session used to interact with         the database.     app (str, optional): The name of the application from which the         registration is initiated.  Returns:     Response200Scheme: A response object containing a success message.  Raises:     HTTPException: If the user is already registered, a 400 error is         returned with the relevant exception scheme.
         * @summary Registration
         * @param {any} body 
         * @param {any} [app] Name of the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registrationRegistrationPost(body: any, app?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registrationRegistrationPost(body, app, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Resend a confirmation code to the user via email or phone.  This endpoint handles the resending of confirmation codes based on the user\'s email or phone number. If a valid user is found, it marks the old confirmation code as used and generates a new one. The new code is then sent to the user.  Args:     request (EmailResendCodeScheme | PhoneResendCodeScheme): A request     object containing either email or phone details.     db_session (AsyncSession): The database session dependency.  Raises:     HTTPException: If the user does not exist or if there is an issue     sending the email.  Returns:     Response200Scheme: A response indicating the success of the operation.
         * @summary Resend Code
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendCodeRegistrationResendCodePost(body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendCodeRegistrationResendCodePost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegistrationApi - factory interface
 * @export
 */
export const RegistrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegistrationApiFp(configuration)
    return {
        /**
         * Determine if the confirmation is for email or phone and call the appropriate method.  Args:     confirmation_code: str, confirmation code from email or phone.     db_session: AsyncSession, database session.
         * @summary Confirmation
         * @param {any} confirmationCode 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmationRegistrationConfirmationCodePatch(confirmationCode: any, options?: any): AxiosPromise<any> {
            return localVarFp.confirmationRegistrationConfirmationCodePatch(confirmationCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Registers a new user using either email or phone, depending on the provided data.  Args:     request (EmailRegisterScheme | PhoneRegisterScheme): The registration         data provided by the user. This can either be an email-based         registration scheme or a phone-based registration scheme.     db_session (AsyncSession): The database session used to interact with         the database.     app (str, optional): The name of the application from which the         registration is initiated.  Returns:     Response200Scheme: A response object containing a success message.  Raises:     HTTPException: If the user is already registered, a 400 error is         returned with the relevant exception scheme.
         * @summary Registration
         * @param {any} body 
         * @param {any} [app] Name of the application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrationRegistrationPost(body: any, app?: any, options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.registrationRegistrationPost(body, app, options).then((request) => request(axios, basePath));
        },
        /**
         * Resend a confirmation code to the user via email or phone.  This endpoint handles the resending of confirmation codes based on the user\'s email or phone number. If a valid user is found, it marks the old confirmation code as used and generates a new one. The new code is then sent to the user.  Args:     request (EmailResendCodeScheme | PhoneResendCodeScheme): A request     object containing either email or phone details.     db_session (AsyncSession): The database session dependency.  Raises:     HTTPException: If the user does not exist or if there is an issue     sending the email.  Returns:     Response200Scheme: A response indicating the success of the operation.
         * @summary Resend Code
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendCodeRegistrationResendCodePost(body: any, options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.resendCodeRegistrationResendCodePost(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegistrationApi - object-oriented interface
 * @export
 * @class RegistrationApi
 * @extends {BaseAPI}
 */
export class RegistrationApi extends BaseAPI {
    /**
     * Determine if the confirmation is for email or phone and call the appropriate method.  Args:     confirmation_code: str, confirmation code from email or phone.     db_session: AsyncSession, database session.
     * @summary Confirmation
     * @param {any} confirmationCode 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public confirmationRegistrationConfirmationCodePatch(confirmationCode: any, options?: AxiosRequestConfig) {
        return RegistrationApiFp(this.configuration).confirmationRegistrationConfirmationCodePatch(confirmationCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registers a new user using either email or phone, depending on the provided data.  Args:     request (EmailRegisterScheme | PhoneRegisterScheme): The registration         data provided by the user. This can either be an email-based         registration scheme or a phone-based registration scheme.     db_session (AsyncSession): The database session used to interact with         the database.     app (str, optional): The name of the application from which the         registration is initiated.  Returns:     Response200Scheme: A response object containing a success message.  Raises:     HTTPException: If the user is already registered, a 400 error is         returned with the relevant exception scheme.
     * @summary Registration
     * @param {any} body 
     * @param {any} [app] Name of the application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public registrationRegistrationPost(body: any, app?: any, options?: AxiosRequestConfig) {
        return RegistrationApiFp(this.configuration).registrationRegistrationPost(body, app, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Resend a confirmation code to the user via email or phone.  This endpoint handles the resending of confirmation codes based on the user\'s email or phone number. If a valid user is found, it marks the old confirmation code as used and generates a new one. The new code is then sent to the user.  Args:     request (EmailResendCodeScheme | PhoneResendCodeScheme): A request     object containing either email or phone details.     db_session (AsyncSession): The database session dependency.  Raises:     HTTPException: If the user does not exist or if there is an issue     sending the email.  Returns:     Response200Scheme: A response indicating the success of the operation.
     * @summary Resend Code
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationApi
     */
    public resendCodeRegistrationResendCodePost(body: any, options?: AxiosRequestConfig) {
        return RegistrationApiFp(this.configuration).resendCodeRegistrationResendCodePost(body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new user.  Args:     new_user (UserCreateSchema): data for the new user.     db_session (CustomSession).     jwt_payload (dict).  Returns:     UserResponse: Newly created user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Creates a new user
         * @param {CreateUserScheme} createUserScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserCreateUserUserIdPost: async (createUserScheme: CreateUserScheme, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserScheme' is not null or undefined
            assertParamExists('createUserCreateUserUserIdPost', 'createUserScheme', createUserScheme)
            const localVarPath = `/create-user/{user_id}/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserScheme, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a user from the system  Args:     user_id (UUID): The ID of the user to be deleted.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Delete a user
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDeleteUserUserIdDelete: async (userId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserDeleteUserUserIdDelete', 'userId', userId)
            const localVarPath = `/delete-user/{user_id}/`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a user by their ID.  Args:     user_id (UUID): The user ID.     db_session (CustomAsyncSession): The database session.     jwt_payload (dict): The JWT payload obtained by the `JWTBearerAdmin`     dependency.  Returns:     UserResponse: A response object containing the user\'s information.  Raises:     HTTPException: If the user with the specified ID is not found.
         * @summary Get the user info
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUserUserIdGet: async (userId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserUserUserIdGet', 'userId', userId)
            const localVarPath = `/user/{user_id}/`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a list of users filtered by various options. This endpoint allows you to retrieve a list of users based on various filter criteria. The following filters are available:  Args:     phone: Filter by phone number (string)     email: Filter by email address (string)     email_verified: Filter by email verification status (boolean, example:     true)     phone_verified: Filter by phone verification status (boolean, example:     true)     role_id: Filter by user role (integer)     created_at: Filter by creation date (datetime)     updated_at: Filter by update date (datetime)     db_session (CustomSession)     params (UserCreateSchema): Filter params the same as args     jwt_payload (dict)  Returns:     UserListResponse: A response object containing the list of users and     the applied filters.
         * @summary Get users
         * @param {any | null} [phone] Filter by phone number
         * @param {any | null} [email] Filter by email address
         * @param {any | null} [emailVerified] Filter by email verification status
         * @param {any | null} [phoneVerified] Filter by phone verification status
         * @param {any | null} [roleId] Filter by user role
         * @param {any | null} [createdAt] Filter by creation date
         * @param {any | null} [updatedAt] Filter by update date
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersUsersGet: async (phone?: any | null, email?: any | null, emailVerified?: any | null, phoneVerified?: any | null, roleId?: any | null, createdAt?: any | null, updatedAt?: any | null, page?: any, size?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (emailVerified !== undefined) {
                localVarQueryParameter['email_verified'] = emailVerified;
            }

            if (phoneVerified !== undefined) {
                localVarQueryParameter['phone_verified'] = phoneVerified;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['role_id'] = roleId;
            }

            if (createdAt !== undefined) {
                localVarQueryParameter['created_at'] = createdAt;
            }

            if (updatedAt !== undefined) {
                localVarQueryParameter['updated_at'] = updatedAt;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user information  Args:     user_id(UUID): user id for update     updated_user (EditUserScheme): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     UserResponse: The updated user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Change the user info
         * @param {any} userId 
         * @param {EditUserScheme} editUserScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUpdateUserUserIdPost: async (userId: any, editUserScheme: EditUserScheme, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserUpdateUserUserIdPost', 'userId', userId)
            // verify required parameter 'editUserScheme' is not null or undefined
            assertParamExists('updateUserUpdateUserUserIdPost', 'editUserScheme', editUserScheme)
            const localVarPath = `/update-user/{user_id}/`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearerAdmin required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(editUserScheme, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new user.  Args:     new_user (UserCreateSchema): data for the new user.     db_session (CustomSession).     jwt_payload (dict).  Returns:     UserResponse: Newly created user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Creates a new user
         * @param {CreateUserScheme} createUserScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserCreateUserUserIdPost(createUserScheme: CreateUserScheme, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserCreateUserUserIdPost(createUserScheme, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a user from the system  Args:     user_id (UUID): The ID of the user to be deleted.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Delete a user
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserDeleteUserUserIdDelete(userId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Scheme>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserDeleteUserUserIdDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a user by their ID.  Args:     user_id (UUID): The user ID.     db_session (CustomAsyncSession): The database session.     jwt_payload (dict): The JWT payload obtained by the `JWTBearerAdmin`     dependency.  Returns:     UserResponse: A response object containing the user\'s information.  Raises:     HTTPException: If the user with the specified ID is not found.
         * @summary Get the user info
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserUserUserIdGet(userId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserUserUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a list of users filtered by various options. This endpoint allows you to retrieve a list of users based on various filter criteria. The following filters are available:  Args:     phone: Filter by phone number (string)     email: Filter by email address (string)     email_verified: Filter by email verification status (boolean, example:     true)     phone_verified: Filter by phone verification status (boolean, example:     true)     role_id: Filter by user role (integer)     created_at: Filter by creation date (datetime)     updated_at: Filter by update date (datetime)     db_session (CustomSession)     params (UserCreateSchema): Filter params the same as args     jwt_payload (dict)  Returns:     UserListResponse: A response object containing the list of users and     the applied filters.
         * @summary Get users
         * @param {any | null} [phone] Filter by phone number
         * @param {any | null} [email] Filter by email address
         * @param {any | null} [emailVerified] Filter by email verification status
         * @param {any | null} [phoneVerified] Filter by phone verification status
         * @param {any | null} [roleId] Filter by user role
         * @param {any | null} [createdAt] Filter by creation date
         * @param {any | null} [updatedAt] Filter by update date
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listUsersUsersGet(phone?: any | null, email?: any | null, emailVerified?: any | null, phoneVerified?: any | null, roleId?: any | null, createdAt?: any | null, updatedAt?: any | null, page?: any, size?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserListResponseInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listUsersUsersGet(phone, email, emailVerified, phoneVerified, roleId, createdAt, updatedAt, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user information  Args:     user_id(UUID): user id for update     updated_user (EditUserScheme): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     UserResponse: The updated user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Change the user info
         * @param {any} userId 
         * @param {EditUserScheme} editUserScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserUpdateUserUserIdPost(userId: any, editUserScheme: EditUserScheme, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserUpdateUserUserIdPost(userId, editUserScheme, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Creates a new user.  Args:     new_user (UserCreateSchema): data for the new user.     db_session (CustomSession).     jwt_payload (dict).  Returns:     UserResponse: Newly created user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Creates a new user
         * @param {CreateUserScheme} createUserScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserCreateUserUserIdPost(createUserScheme: CreateUserScheme, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.createUserCreateUserUserIdPost(createUserScheme, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a user from the system  Args:     user_id (UUID): The ID of the user to be deleted.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Delete a user
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserDeleteUserUserIdDelete(userId: any, options?: any): AxiosPromise<Response200Scheme> {
            return localVarFp.deleteUserDeleteUserUserIdDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a user by their ID.  Args:     user_id (UUID): The user ID.     db_session (CustomAsyncSession): The database session.     jwt_payload (dict): The JWT payload obtained by the `JWTBearerAdmin`     dependency.  Returns:     UserResponse: A response object containing the user\'s information.  Raises:     HTTPException: If the user with the specified ID is not found.
         * @summary Get the user info
         * @param {any} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUserUserIdGet(userId: any, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.getUserUserUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a list of users filtered by various options. This endpoint allows you to retrieve a list of users based on various filter criteria. The following filters are available:  Args:     phone: Filter by phone number (string)     email: Filter by email address (string)     email_verified: Filter by email verification status (boolean, example:     true)     phone_verified: Filter by phone verification status (boolean, example:     true)     role_id: Filter by user role (integer)     created_at: Filter by creation date (datetime)     updated_at: Filter by update date (datetime)     db_session (CustomSession)     params (UserCreateSchema): Filter params the same as args     jwt_payload (dict)  Returns:     UserListResponse: A response object containing the list of users and     the applied filters.
         * @summary Get users
         * @param {any | null} [phone] Filter by phone number
         * @param {any | null} [email] Filter by email address
         * @param {any | null} [emailVerified] Filter by email verification status
         * @param {any | null} [phoneVerified] Filter by phone verification status
         * @param {any | null} [roleId] Filter by user role
         * @param {any | null} [createdAt] Filter by creation date
         * @param {any | null} [updatedAt] Filter by update date
         * @param {any} [page] 
         * @param {any} [size] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listUsersUsersGet(phone?: any | null, email?: any | null, emailVerified?: any | null, phoneVerified?: any | null, roleId?: any | null, createdAt?: any | null, updatedAt?: any | null, page?: any, size?: any, options?: any): AxiosPromise<UserListResponseInput> {
            return localVarFp.listUsersUsersGet(phone, email, emailVerified, phoneVerified, roleId, createdAt, updatedAt, page, size, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user information  Args:     user_id(UUID): user id for update     updated_user (EditUserScheme): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     UserResponse: The updated user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
         * @summary Change the user info
         * @param {any} userId 
         * @param {EditUserScheme} editUserScheme 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserUpdateUserUserIdPost(userId: any, editUserScheme: EditUserScheme, options?: any): AxiosPromise<UserResponse> {
            return localVarFp.updateUserUpdateUserUserIdPost(userId, editUserScheme, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Creates a new user.  Args:     new_user (UserCreateSchema): data for the new user.     db_session (CustomSession).     jwt_payload (dict).  Returns:     UserResponse: Newly created user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
     * @summary Creates a new user
     * @param {CreateUserScheme} createUserScheme 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserCreateUserUserIdPost(createUserScheme: CreateUserScheme, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUserCreateUserUserIdPost(createUserScheme, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a user from the system  Args:     user_id (UUID): The ID of the user to be deleted.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     Response200Scheme: Success message indicating that the user was deleted  Raises:     HTTPException: dynamic according to provided schemes
     * @summary Delete a user
     * @param {any} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUserDeleteUserUserIdDelete(userId: any, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUserDeleteUserUserIdDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a user by their ID.  Args:     user_id (UUID): The user ID.     db_session (CustomAsyncSession): The database session.     jwt_payload (dict): The JWT payload obtained by the `JWTBearerAdmin`     dependency.  Returns:     UserResponse: A response object containing the user\'s information.  Raises:     HTTPException: If the user with the specified ID is not found.
     * @summary Get the user info
     * @param {any} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserUserUserIdGet(userId: any, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserUserUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a list of users filtered by various options. This endpoint allows you to retrieve a list of users based on various filter criteria. The following filters are available:  Args:     phone: Filter by phone number (string)     email: Filter by email address (string)     email_verified: Filter by email verification status (boolean, example:     true)     phone_verified: Filter by phone verification status (boolean, example:     true)     role_id: Filter by user role (integer)     created_at: Filter by creation date (datetime)     updated_at: Filter by update date (datetime)     db_session (CustomSession)     params (UserCreateSchema): Filter params the same as args     jwt_payload (dict)  Returns:     UserListResponse: A response object containing the list of users and     the applied filters.
     * @summary Get users
     * @param {any | null} [phone] Filter by phone number
     * @param {any | null} [email] Filter by email address
     * @param {any | null} [emailVerified] Filter by email verification status
     * @param {any | null} [phoneVerified] Filter by phone verification status
     * @param {any | null} [roleId] Filter by user role
     * @param {any | null} [createdAt] Filter by creation date
     * @param {any | null} [updatedAt] Filter by update date
     * @param {any} [page] 
     * @param {any} [size] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public listUsersUsersGet(phone?: any | null, email?: any | null, emailVerified?: any | null, phoneVerified?: any | null, roleId?: any | null, createdAt?: any | null, updatedAt?: any | null, page?: any, size?: any, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).listUsersUsersGet(phone, email, emailVerified, phoneVerified, roleId, createdAt, updatedAt, page, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user information  Args:     user_id(UUID): user id for update     updated_user (EditUserScheme): updated data for the user.     db_session (CustomAsyncSession)     jwt_payload (dict)  Returns:     UserResponse: The updated user\'s information.  Raises:     HTTPException: dynamic according to provided schemes
     * @summary Change the user info
     * @param {any} userId 
     * @param {EditUserScheme} editUserScheme 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserUpdateUserUserIdPost(userId: any, editUserScheme: EditUserScheme, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserUpdateUserUserIdPost(userId, editUserScheme, options).then((request) => request(this.axios, this.basePath));
    }
}



