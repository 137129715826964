export enum AppPaths {
    LOGIN = '/login',
    REGISTRATION = '/registration',
    RESET = '/reset',
    CONFIRM = '/confirm',
    NEW_PASSWORD = '/new-password',
    NOT_FOUND = '*',

    HOME = '/',
    PROFILE = '/profile',
    ORDERS = '/orders',
    PRODUCTS = '/products',
    COLLECTIONS = '/collections',
    CATEGORIES = '/categories',
    STORES = '/stores',
    CLIENTS = '/clients',
    PROMOCODES = '/promocodes',
    DISCOUNTS = '/discounts',
    TOOLS = '/tools',
    CONNECTIONS = '/connections',
    TELEGRAM = '/telegram',
    SUPPORT = '/support',
    HELP = '/help',
    DATA_EXCHANGE = '/data-exchange',
    IMPORT_DATA = '/import',
    EXPORT_DATA = '/export',
    SHIFTS = '/shifts',
    CHART_SHIFTS = '/chart-shifts',
    ROUTES = '/routes',

    INFOBOX = '/infobox',
    PAYMENTS = '/payments',

    ADMIN_WORKERS = '/admin/workers',
    ADMIN_USERS_ANALYTICS = '/admin/users/analytics',
    ADMIN_SETTINGS = '/admin/settings',
    ADMIN_NOTIFICATIONS = '/admin/notifications',
    ADMIN_TICKETS = '/admin/tickets',
    ADMIN_TICKET_DETAIL = '/admin/ticketdetail',
    ADMIN_ANALYTICS = '/admin/analytics',
    ADMIN_CONTENT = '/admin/content',
    ADMIN_ROLES = '/admin/roles',

    ARTICLE = '/article',
    ARTICLE_FORM = '/article-form',
    ARTICLE_LIST = '/article-list',
}

export enum AuthUrl {
    login = '/auth/login/',
    refresh = '/auth/refresh/',
    logout = '/auth/logout/',
}

export const ClientUrl = {
    CLIENT_EDIT: (id: number) => `/clients/${id}/edit`,
    CLIENT: (id: number) => `/clients/${id}`,
}
