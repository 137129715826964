import {createSlice,PayloadAction} from '@reduxjs/toolkit'

import {ILayoutState} from '@/store/actionTypes/layout'

const initialState: ILayoutState = {
    burgerMenu: true,
}

const layoutSlice = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        setBurgerMenu: (state, action: PayloadAction<boolean>) => {
            state.burgerMenu = action.payload
        },
    },
})

export default layoutSlice
