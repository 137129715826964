import {FC} from 'react'

import CenterPage from '@/ui/CenterPage/CenterPage'

import SignInForm from './SignInForm/SignInForm'

const SignIn: FC = () => {

    return (
        <CenterPage>
            <SignInForm />
        </CenterPage>
    )
}

export default SignIn
