import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchArticles } from './actions';
import { IResponse, IBaseStateField } from '@/types/type'; // Предполагается, что эти интерфейсы находятся в type.ts

interface ArticleListState extends IBaseStateField {
  articles: any[];
  errorMessage: string;
}

const initialState: ArticleListState = {
  articles: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorMessage: '',
};

export const articleListSlice = createSlice({
  name: 'articleList',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchArticles.pending.type]: (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = '';
    },
    [fetchArticles.fulfilled.type]: (state, action: PayloadAction<IResponse<any[]>>) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.articles = action.payload.result;
    },
    [fetchArticles.rejected.type]: (state, action: PayloadAction<string | undefined>) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload || 'Ошибка при получении статей';
    },
  },
});

export default articleListSlice.reducer;