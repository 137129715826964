import {FC, Suspense} from 'react'
import {Route, Routes} from 'react-router-dom'
import {Center, Loader} from '@mantine/core'

import useGetForbiddenPaths from '@/routes/useGetForbiddenPaths'

import {AdminRoutes} from './AdminRoutes'
import {PrivateRoutes} from './PrivateRoutes'
import {PublicRoutes} from './PublicRoutes'
import {adminRoutes, privateRoutes, publicRoutes, routes} from './routes'

const AppRouter: FC = () => {
    const {handleForbiddenPath} = useGetForbiddenPaths()

    return (
        <Suspense
            fallback={
                <Center style={{height: '100vh'}}>
                    <Loader variant='dots' />
                </Center>
            }
        >
            <Routes>
                {publicRoutes.map((route) =>
                    route.path ? (
                        <Route
                            path={route.path}
                            element={<PublicRoutes>{route.component}</PublicRoutes>}
                            key={route.path}
                        />
                    ) : null
                )}
                {privateRoutes.map((route) =>
                    route.path && handleForbiddenPath(route.path) ? (
                        <Route
                            path={route.path}
                            element={<PrivateRoutes>{route.component}</PrivateRoutes>}
                            key={route.path}
                        />
                    ) : null
                )}

                {adminRoutes.map((route) =>
                    route.path && handleForbiddenPath(route.path) ? (
                        <Route
                            path={route.path}
                            element={<AdminRoutes>{route.component}</AdminRoutes>}
                            key={route.path}
                        />
                    ) : null
                )}

                {routes.map((route) =>
                    route.path && handleForbiddenPath(route.path) ? (
                        <Route path={route.path} element={route.component} key={route.path} />
                    ) : null
                )}
            </Routes>
        </Suspense>
    )
}

export default AppRouter
