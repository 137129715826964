import {Global} from '@mantine/core'

// import light from '@/assets/fonts/GTEestiProDisplayLight.woff2';
// import medium from '@/assets/fonts/GTEestiProDisplayMedium.woff2';
// import regular from '@/assets/fonts/GTEestiProDisplayRegular.woff2';
import light from '@/assets/fonts/Gilroy-Light.woff2'
import medium from '@/assets/fonts/Gilroy-Medium.woff2'
import regular from '@/assets/fonts/Gilroy-Regular.woff2'

export function AppFonts() {
    return (
        // <Global
        //   styles={[
        //     {
        //       '@font-face': {
        //         fontFamily: 'GT Eesti Pro Display',
        //         src: `url('${light}') format("woff2")`,
        //         fontWeight: 300,
        //         fontStyle: 'normal',
        //       },
        //     },
        //     {
        //       '@font-face': {
        //         fontFamily: 'GT Eesti Pro Display',
        //         src: `url('${regular}') format("woff2")`,
        //         fontWeight: 400,
        //         fontStyle: 'normal',
        //       },
        //     },
        //     {
        //       '@font-face': {
        //         fontFamily: 'GT Eesti Pro Display',
        //         src: `url('${medium}') format("woff2")`,
        //         fontWeight: 500,
        //         fontStyle: 'normal',
        //       },
        //     },
        //   ]}
        // />
        <Global
            styles={[
                {
                    '@font-face': {
                        fontFamily: 'Gilroy',
                        src: `url('${light}') format("woff2")`,
                        fontWeight: 300,
                        fontStyle: 'normal',
                    },
                },
                {
                    '@font-face': {
                        fontFamily: 'Gilroy',
                        src: `url('${regular}') format("woff2")`,
                        fontWeight: 400,
                        fontStyle: 'normal',
                    },
                },
                {
                    '@font-face': {
                        fontFamily: 'Gilroy',
                        src: `url('${medium}') format("woff2")`,
                        fontWeight: 500,
                        fontStyle: 'normal',
                    },
                },
            ]}
        />
    )
}
