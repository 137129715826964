import {MantineThemeOverride} from '@mantine/core'

import {BREAKPOINTS} from './breakpoints'

export const appTheme: MantineThemeOverride = {
    breakpoints: BREAKPOINTS,
    cursorType: 'pointer',
    // fontFamily: 'Rubik, sans-serif',
    // lineHeight: 1.35,
    // headings: {
    //     fontWeight: 600,
    //     fontFamily: 'Rubik',
    //     sizes: {
    //         h1: {fontSize: 26, lineHeight: 1.1},
    //         h2: {lineHeight: 1.1},
    //         h3: {lineHeight: 1.35},
    //         h4: {lineHeight: 1.35},
    //         h5: {lineHeight: 1.35},
    //         h6: {lineHeight: 1.35},
    //     },
    // },
    // colors: {
    //     blue: [
    //         '#F3F3FC',
    //         '#CECDF3',
    //         '#A6A4EA',
    //         '#7D7BE0',
    //         '#5552D6',
    //         '#322FC6',
    //         '#28259D',
    //         '#1D1B73',
    //         '#13124A',
    //         '#080821',
    //     ],
    // },
    // primaryShade: {light: 5},
    colors: {
        cyan: [
            '#E5F3FF',
            '#B8DDFF',
            '#8AC7FF',
            '#5CB1FF',
            '#2E9BFF',
            '#0085FF',
            '#006ACC',
            '#005099',
            '#003566',
            '#001B33',
        ],
    },
    datesLocale: 'ru',

    globalStyles: (theme) => ({
        body: {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : '#F0F1F2',
            letterSpacing: 0.5,
        },
    }),

    components: {
        AppShell: {
            defaultProps: {
                fixed: false,
            },
            styles: () => ({
                root: {
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                },
                body: {
                    flex: '1 1 0%',
                },
                main: {
                    minWidth: 0,
                },
            }),
        },
        Button: {
            styles: () => ({
                // root: {
                //     height: 34,

                // },
                label: {
                    fontWeight: 500,
                },
            }),
        },
        FileInput: {
            styles: () => ({
                placeholder: {
                    display: 'block',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                },
            }),
        },

        TextInput: {
            styles: () => ({
                label: {
                    fontSize: 14,
                    fontWeight: 400,
                    letterSpacing: 0,
                    color: '#64676F',
                    lineHeight: '19px',
                    marginBottom: 3,
                },
                input: {
                    // height: 45,
                    borderColor: '#CED4DA',
                    padding: '8px auto 8px 16px',
                },
            }),
        },

        PasswordInput: {
            styles: () => ({
                label: {
                    fontSize: 14,
                    fontWeight: 400,
                    letterSpacing: 0,
                    color: '#64676F',
                    lineHeight: '19px',
                    marginBottom: 3,
                },
                input: {
                    // height: 45,
                    borderColor: '#CED4DA',
                    padding: '8px 8px 8px 16px',
                },
            }),
        },

        Textarea: {
            styles: () => ({
                label: {
                    fontSize: 14,
                    fontWeight: 400,
                    letterSpacing: 0,
                    color: '#64676F',
                    lineHeight: '19px',
                    marginBottom: 3,
                },
            }),
        },

        Select: {
            styles: () => ({
                label: {
                    fontSize: 14,
                    fontWeight: 400,
                    letterSpacing: 0,
                    color: '#64676F',
                    lineHeight: '19px',
                    marginBottom: 3,
                },
                input: {
                    // height: 45,
                    borderColor: '#CED4DA',
                    padding: '8px 8px 8px 16px',
                },
            }),
        },

        MultiSelect: {
            styles: () => ({
                label: {
                    fontSize: 14,
                    fontWeight: 400,
                    letterSpacing: 0,
                    color: '#64676F',
                    lineHeight: '19px',
                    marginBottom: 3,
                },
                input: {
                    // height: 45,
                    borderColor: '#CED4DA',
                    padding: '8px 8px 8px 16px',
                },
            }),
        },

        DatePicker: {
            styles: () => ({
                label: {
                    fontSize: 14,
                    fontWeight: 400,
                    letterSpacing: 0,
                    color: '#64676F',
                    lineHeight: '19px',
                    marginBottom: 3,
                },
                input: {
                    // height: 45,
                    borderColor: '#CED4DA',
                    padding: '8px 8px 8px 16px',
                },
            }),
        },

        DateRangePicker: {
            styles: () => ({
                label: {
                    fontSize: 14,
                    fontWeight: 400,
                    letterSpacing: 0,
                    color: '#64676F',
                    lineHeight: '19px',
                    marginBottom: 3,
                },
                input: {
                    // height: 45,
                    borderColor: '#CED4DA',
                    padding: '8px 8px 8px 16px',
                },
            }),
        },
    },
}
