import { createAsyncThunk } from '@reduxjs/toolkit';
import { Api } from '@/api/apiService';

export const fetchArticleById = createAsyncThunk(
  'articles/fetchArticleById',
  async (id: string, thunkAPI) => {
    try {
      const { data } = await Api.Articles.getArticleArticlesArticleIdGet(id);
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при получении статьи');
    }
  }
);