import {FC} from 'react'
import {AppShell, AppShellProps} from '@mantine/core'

import LayoutFooter from './components/LayoutFooter/LayoutFooter'

const LayoutPublic: FC<AppShellProps> = ({children, padding = 16}) => {
    return (
        <AppShell footer={<LayoutFooter />} padding={padding}>
            {children}
        </AppShell>
    )
}

export default LayoutPublic
