import { IResponse, PaymentLink } from "@/types/type";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { purchaseItem } from "./actions";

export interface PurchaseState {
    isSuccess: boolean
    isError: boolean
    purchaseIsLoading: boolean
    paymentLink: PaymentLink | null
    errorMessage: string
}
const initialState: PurchaseState = {
    isSuccess: false,
    purchaseIsLoading: false,
    isError: false,
    paymentLink: null,
    errorMessage: ''
}

export const purchaseSlice = createSlice({
    name: 'purchase',
    initialState, 
    reducers: {
        resetPaymentLink: (state) => {
            state.paymentLink = null
        }
    },
    extraReducers: {
        [purchaseItem.pending.type]: (state) => {
            state.purchaseIsLoading = true

        },
        [purchaseItem.fulfilled.type]: (state, action: PayloadAction<IResponse<PaymentLink>>) => {
            state.purchaseIsLoading = false
            state.isSuccess = true
            state.isError = false
            state.paymentLink = action.payload.result
        },
        [purchaseItem.rejected.type]: (state, action: PayloadAction<any>) => {
            state.purchaseIsLoading = false
            state.isError = true
            state.isSuccess = false
            state.errorMessage = action.payload.message || 'Неизвестная ошибка'
        }
    },
})

export const { resetPaymentLink } = purchaseSlice.actions