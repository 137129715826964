import { createAsyncThunk } from '@reduxjs/toolkit';
import { Api } from '@/api/apiService';

export const fetchArticles = createAsyncThunk('articles/fetchArticles', async (_, thunkAPI) => {
  try {
    const response = await Api.Articles.getArticlesArticlesGet(); 
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue('Ошибка при получении статей');
  }
});