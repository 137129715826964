import React, {lazy} from 'react'

import {AppPaths} from './routes-enums'

const HomePage = lazy(() => import('@/pages/Home/Home'))
// const Clients = lazy(() => import('@/pages/Clients/Clients'))
// const Support = lazy(() => import('@/pages/Support/Support'))
// const Help = lazy(() => import('@/pages/Help/Help'))
// const Workers = lazy(() => import('src/pages/Workers'))
// const UsersAnalytics = lazy(() => import('@/pages/Workers/UsersAnalytics'))
const Content = lazy(() => import('@/pages/Content/Content'))
// const Notifications = lazy(() => import('@/pages/Notifications/Notifications'))
// const Tickets = lazy(() => import('@/pages/Tickets/Tickets'))
// const TicketDetail = lazy(
//     () => import('@/pages/Tickets/TicketsTable/TicketsActions/TicketDetailModal/TicketDetail')
// )
// const Analytics = lazy(() => import('@/pages/Tickets/TicketsControls/Analytics/Analytics'))
// const Settings = lazy(() => import('@/pages/Settings/Settings'))
const ResetPassword = lazy(() => import('@/pages/ResetPassword/ResetPassword'))
const ConfirmEmail = lazy(() => import('@/pages/ConfirmEmail/ConfirmEmail'))
const ConfirmPassword = lazy(() => import('@/pages/ConfirmPassword/ConfirmPassword'))
const NewPassword = lazy(() => import('@/pages/NewPassword/NewPassword'))
const NotFound = lazy(() => import('@/pages/NotFound/NotFound'))
// const Tools = lazy(() => import('@/pages/Tools/Tools'))
const Connections = lazy(() => import('@/pages/Connections/Connections'))
// const Orders = lazy(() => import('@/pages/Orders'))
const Products = lazy(() => import('@/pages/Products/Products'))
// const Collections = lazy(() => import('@/pages/Collections/Collections'))
// const Categories = lazy(() => import('@/pages/Categories/Categories'))
// const Stores = lazy(() => import('@/pages/Stores/Stores'))
// const Promocodes = lazy(() => import('@/pages/Promocodes/Promocodes'))
// const Shifts = lazy(() => import('@/pages/Shifts'))
// const ChartShifts = lazy(() => import('@/pages/ChartShifts/ChartShifts'))
const Routes = lazy(() => import('@/pages/Routes/Routes'))
// const Infobox = lazy(() => import('@/pages/Infobox/Infobox'))

// const Payments = lazy(() => import('@/pages/Payments/Payments'))
// const Discounts = lazy(() => import('@/pages/Discounts/Discounts'))

// const DataExchange = lazy(() => import('@/pages/DataExchange/DataExchange'))
// const ExportData = lazy(() => import('@/pages/ExportData/ExportData'))
// const ImportData = lazy(() => import('@/pages/ImportData/ImportData'))

// const Roles = lazy(() => import('@/pages/Roles'))

// import {ChartShifts} from '@/pages/ChartShifts/ChartShifts'
import Registration from '@/pages/Registration/Registration'
import SignIn from '@/pages/SignIn/SignIn'

import ArticleForm from '@/pages/ArticleForm/ArticleForm'
import ArticleList from '@/pages/ArticleList/ArticleList'
import Article from '@/pages/Article/Article'

// import { ChartShifts } from '@/pages/ChartShifts/ChartShifts'

export interface IRoute {
    path: string
    component: JSX.Element
}

export const routes: IRoute[] = [
    {path: AppPaths.RESET + '/:token/', component: <ConfirmPassword />},
    {path: AppPaths.CONFIRM + '/:token?/', component: <ConfirmEmail />},
    {path: AppPaths.NEW_PASSWORD, component: <NewPassword />},
    {path: AppPaths.NOT_FOUND, component: <NotFound />},
]

export const publicRoutes: IRoute[] = [
    {path: AppPaths.LOGIN, component: <SignIn />},
    {path: AppPaths.REGISTRATION, component: <Registration />},
    {path: AppPaths.RESET, component: <ResetPassword />},
]

export const privateRoutes: IRoute[] = [
    {path: AppPaths.HOME, component: <HomePage />},
    // {path: AppPaths.CLIENTS + '/*', component: <Clients />},
    // {path: AppPaths.SUPPORT, component: <Support />},
    // {path: AppPaths.HELP + '/*', component: <Help />},
    // {path: AppPaths.ADMIN_TICKET_DETAIL, component: <TicketDetail />},
    // {path: AppPaths.TOOLS + '/*', component: <Tools />},
    // {path: AppPaths.PAYMENTS + '/*', component: <Payments />},
    {path: AppPaths.CONNECTIONS + '/*', component: <Connections />},
    {path: AppPaths.ARTICLE_FORM, component: <ArticleForm />},
    {path: AppPaths.ARTICLE_LIST, component: <ArticleList />},
    {path: AppPaths.ARTICLE, component: <Article />},
    // {path: AppPaths.ORDERS + '/*', component: <Orders />},
    {path: AppPaths.PRODUCTS + '/*', component: <Products />},
    // {path: AppPaths.COLLECTIONS, component: <Collections />},
    // {path: AppPaths.CATEGORIES + '/*', component: <Categories />},
    // {path: AppPaths.STORES + '/*', component: <Stores />},
    // {path: AppPaths.DISCOUNTS + '/*', component: <Discounts />},
    // {path: AppPaths.PROMOCODES, component: <Promocodes />},
    // {path: AppPaths.SHIFTS, component: <Shifts />},
    {path: AppPaths.ROUTES, component: <Routes />},
    // {path: AppPaths.INFOBOX + '/*', component: <Infobox />},
]

export const adminRoutes: IRoute[] = [
    // {path: AppPaths.ADMIN_WORKERS + '/*', component: <Workers />},
    // {path: AppPaths.ADMIN_USERS_ANALYTICS, component: <UsersAnalytics />},
    {path: AppPaths.ADMIN_CONTENT + '/*', component: <Content />},
    // {path: AppPaths.ADMIN_NOTIFICATIONS + '/*', component: <Notifications />},
    // {path: AppPaths.ADMIN_TICKETS, component: <Tickets />},
    // {path: AppPaths.ADMIN_TICKET_DETAIL, component: <TicketDetail />},
    // {path: AppPaths.ADMIN_ANALYTICS, component: <Analytics />},
    // {path: AppPaths.ADMIN_SETTINGS + '/*', component: <Settings />},
    // {path: AppPaths.IMPORT_DATA + '/*', component: <ImportData />},
    // {path: AppPaths.EXPORT_DATA + '/*', component: <ExportData />},
    // {path: AppPaths.DATA_EXCHANGE + '/*', component: <DataExchange />},
    // {path: AppPaths.CHART_SHIFTS + '/*', component: <ChartShifts />},
    // {path: AppPaths.ADMIN_ROLES + '/*', component: <Roles />},
]

export const FORBIDDEN_SUPERVISOR = [AppPaths.ADMIN_NOTIFICATIONS, AppPaths.DATA_EXCHANGE]
export const FORBIDDEN_CONTENT_MANAGER = [
    AppPaths.ORDERS,
    AppPaths.ADMIN_NOTIFICATIONS,
    AppPaths.DATA_EXCHANGE,
    AppPaths.SHIFTS,
    AppPaths.CHART_SHIFTS,
    AppPaths.ADMIN_WORKERS,
]
export const FORBIDDEN_MANAGER = [AppPaths.ADMIN_NOTIFICATIONS, AppPaths.DATA_EXCHANGE]
export const FORBIDDEN_ADMIN: string[] = []
