import {FC} from 'react'
import {Navigate} from 'react-router-dom'

import { TokenService } from "@/api/TokenService";

import {AppPaths} from './routes-enums'

interface Props {
    children: JSX.Element
}

export const PublicRoutes: FC<Props> = ({children}) => {
    const token = TokenService.getToken()
    return !token ? children : <Navigate to={AppPaths.HOME} />
}
