import {FC, useLayoutEffect} from 'react'
import {BrowserRouter} from 'react-router-dom'

import {TokenService} from '@/api/TokenService'

import {AppFonts} from '@/utils/AppFonts'
import MantineProviders from '@/utils/MantineProviders'

import AppRouter from '@/routes/AppRouter'

import '@/utils/i18n'

import {api} from '@/index'
import {useActions} from '@/store/hooks'

const App: FC = () => {
    const {setToken} = useActions()
    const token = TokenService.getToken()
    const refreshToken = TokenService.getRefreshedToken()

    useLayoutEffect(() => {
        api.init()
        setToken({token, refreshToken})
    }, [])
    return (
        <MantineProviders>
            <AppFonts />
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
        </MantineProviders>
    )
}

export default App
