/* tslint:disable */
/* eslint-disable */
/**
 * ChatGPT Translator
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActiveConfigDataSchema
 */
export interface ActiveConfigDataSchema {
    /**
     * 
     * @type {ActiveConfigOutSchema}
     * @memberof ActiveConfigDataSchema
     */
    'active_config': ActiveConfigOutSchema;
}
/**
 * 
 * @export
 * @interface ActiveConfigListDataSchema
 */
export interface ActiveConfigListDataSchema {
    /**
     * 
     * @type {any}
     * @memberof ActiveConfigListDataSchema
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface ActiveConfigListResponseSchema
 */
export interface ActiveConfigListResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof ActiveConfigListResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ActiveConfigListResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {ActiveConfigListDataSchema}
     * @memberof ActiveConfigListResponseSchema
     */
    'data': ActiveConfigListDataSchema;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof ActiveConfigListResponseSchema
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface ActiveConfigOutSchema
 */
export interface ActiveConfigOutSchema {
    /**
     * 
     * @type {any}
     * @memberof ActiveConfigOutSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof ActiveConfigOutSchema
     */
    'prompt_id': any;
    /**
     * 
     * @type {any}
     * @memberof ActiveConfigOutSchema
     */
    'config_id': any;
    /**
     * 
     * @type {any}
     * @memberof ActiveConfigOutSchema
     */
    'is_active': any;
}
/**
 * 
 * @export
 * @interface ActiveConfigResponseSchema
 */
export interface ActiveConfigResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof ActiveConfigResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ActiveConfigResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {ActiveConfigDataSchema}
     * @memberof ActiveConfigResponseSchema
     */
    'data': ActiveConfigDataSchema;
}
/**
 * 
 * @export
 * @interface ActiveConfigSchema
 */
export interface ActiveConfigSchema {
    /**
     * 
     * @type {any}
     * @memberof ActiveConfigSchema
     */
    'prompt_id': any;
    /**
     * 
     * @type {any}
     * @memberof ActiveConfigSchema
     */
    'config_id': any;
    /**
     * 
     * @type {any}
     * @memberof ActiveConfigSchema
     */
    'is_active': any;
}
/**
 * 
 * @export
 * @interface AppsApiOrderSchemasOrderListTotalPriceDataSchema1
 */
export interface AppsApiOrderSchemasOrderListTotalPriceDataSchema1 {
    /**
     * 
     * @type {any}
     * @memberof AppsApiOrderSchemasOrderListTotalPriceDataSchema1
     */
    'list': any;
    /**
     * 
     * @type {any}
     * @memberof AppsApiOrderSchemasOrderListTotalPriceDataSchema1
     */
    'total_price': any;
}
/**
 * 
 * @export
 * @interface AppsApiOrderSchemasOrderListTotalPriceDataSchema2
 */
export interface AppsApiOrderSchemasOrderListTotalPriceDataSchema2 {
    /**
     * 
     * @type {any}
     * @memberof AppsApiOrderSchemasOrderListTotalPriceDataSchema2
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof AppsApiOrderSchemasOrderListTotalPriceDataSchema2
     */
    'message'?: any;
    /**
     * 
     * @type {AppsApiOrderSchemasOrderListTotalPriceDataSchema1}
     * @memberof AppsApiOrderSchemasOrderListTotalPriceDataSchema2
     */
    'data': AppsApiOrderSchemasOrderListTotalPriceDataSchema1;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof AppsApiOrderSchemasOrderListTotalPriceDataSchema2
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface AppsApiOrderSchemasSortOrderOptions
 */
export interface AppsApiOrderSchemasSortOrderOptions {
}
/**
 * 
 * @export
 * @interface AppsApiSchemasSortOrderOptions
 */
export interface AppsApiSchemasSortOrderOptions {
}
/**
 * 
 * @export
 * @interface ArticleDataSchema
 */
export interface ArticleDataSchema {
    /**
     * 
     * @type {ArticleOutSchema}
     * @memberof ArticleDataSchema
     */
    'article': ArticleOutSchema;
}
/**
 * 
 * @export
 * @interface ArticleListDataSchema
 */
export interface ArticleListDataSchema {
    /**
     * 
     * @type {any}
     * @memberof ArticleListDataSchema
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface ArticleListResponseSchema
 */
export interface ArticleListResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof ArticleListResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ArticleListResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {ArticleListDataSchema}
     * @memberof ArticleListResponseSchema
     */
    'data': ArticleListDataSchema;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof ArticleListResponseSchema
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface ArticleOutSchema
 */
export interface ArticleOutSchema {
    /**
     * 
     * @type {any}
     * @memberof ArticleOutSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof ArticleOutSchema
     */
    'title': any;
    /**
     * 
     * @type {any}
     * @memberof ArticleOutSchema
     */
    'text': any;
    /**
     * 
     * @type {any}
     * @memberof ArticleOutSchema
     */
    'image': any | null;
    /**
     * 
     * @type {any}
     * @memberof ArticleOutSchema
     */
    'image_url': any | null;
    /**
     * 
     * @type {any}
     * @memberof ArticleOutSchema
     */
    'user_id': any | null;
    /**
     * 
     * @type {any}
     * @memberof ArticleOutSchema
     */
    'language_id': any | null;
    /**
     * 
     * @type {any}
     * @memberof ArticleOutSchema
     */
    'is_original': any;
    /**
     * 
     * @type {any}
     * @memberof ArticleOutSchema
     */
    'original_article_id': any | null;
    /**
     * 
     * @type {any}
     * @memberof ArticleOutSchema
     */
    'like': any | null;
}
/**
 * 
 * @export
 * @interface ArticleResponseSchema
 */
export interface ArticleResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof ArticleResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ArticleResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {ArticleDataSchema}
     * @memberof ArticleResponseSchema
     */
    'data': ArticleDataSchema;
}
/**
 * 
 * @export
 * @interface ArticleSchema
 */
export interface ArticleSchema {
    /**
     * 
     * @type {any}
     * @memberof ArticleSchema
     */
    'title': any;
    /**
     * 
     * @type {any}
     * @memberof ArticleSchema
     */
    'text': any;
    /**
     * 
     * @type {any}
     * @memberof ArticleSchema
     */
    'language_id'?: any | null;
}
/**
 * 
 * @export
 * @interface BalanceDataResponseSchema
 */
export interface BalanceDataResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof BalanceDataResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof BalanceDataResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {BalanceDataSchema}
     * @memberof BalanceDataResponseSchema
     */
    'data': BalanceDataSchema;
}
/**
 * 
 * @export
 * @interface BalanceDataSchema
 */
export interface BalanceDataSchema {
    /**
     * 
     * @type {BalanceResponseSchema}
     * @memberof BalanceDataSchema
     */
    'balance': BalanceResponseSchema;
}
/**
 * 
 * @export
 * @interface BalanceResponseSchema
 */
export interface BalanceResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof BalanceResponseSchema
     */
    'token_balance': any;
}
/**
 * 
 * @export
 * @interface BodyConfirmOrderOrdersConfirmPost
 */
export interface BodyConfirmOrderOrdersConfirmPost {
    /**
     * 
     * @type {any}
     * @memberof BodyConfirmOrderOrdersConfirmPost
     */
    'order_id_list': any;
}
/**
 * 
 * @export
 * @interface BodyExportArticleToPdfViewArticlesExportPdfPost
 */
export interface BodyExportArticleToPdfViewArticlesExportPdfPost {
    /**
     * ID of the article to export
     * @type {any}
     * @memberof BodyExportArticleToPdfViewArticlesExportPdfPost
     */
    'article_id': any;
}
/**
 * 
 * @export
 * @interface BodyVerifyPurhasePurchaseVerifyPost
 */
export interface BodyVerifyPurhasePurchaseVerifyPost {
    /**
     * 
     * @type {any}
     * @memberof BodyVerifyPurhasePurchaseVerifyPost
     */
    'order_id': any;
}
/**
 * 
 * @export
 * @interface CalculatedPriceDataResponseSchema
 */
export interface CalculatedPriceDataResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof CalculatedPriceDataResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof CalculatedPriceDataResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {CalculatedPriceDataSchema}
     * @memberof CalculatedPriceDataResponseSchema
     */
    'data': CalculatedPriceDataSchema;
}
/**
 * 
 * @export
 * @interface CalculatedPriceDataSchema
 */
export interface CalculatedPriceDataSchema {
    /**
     * 
     * @type {CalculatedPriceResponseSchema}
     * @memberof CalculatedPriceDataSchema
     */
    'price': CalculatedPriceResponseSchema;
}
/**
 * 
 * @export
 * @interface CalculatedPriceResponseSchema
 */
export interface CalculatedPriceResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof CalculatedPriceResponseSchema
     */
    'tokens': any;
}
/**
 * 
 * @export
 * @interface CommentDataSchema
 */
export interface CommentDataSchema {
    /**
     * 
     * @type {CommentOutSchema}
     * @memberof CommentDataSchema
     */
    'comment': CommentOutSchema;
}
/**
 * 
 * @export
 * @interface CommentListDataSchema
 */
export interface CommentListDataSchema {
    /**
     * 
     * @type {any}
     * @memberof CommentListDataSchema
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface CommentListResponseSchemaInput
 */
export interface CommentListResponseSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof CommentListResponseSchemaInput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof CommentListResponseSchemaInput
     */
    'message'?: any;
    /**
     * 
     * @type {CommentListDataSchema}
     * @memberof CommentListResponseSchemaInput
     */
    'data': CommentListDataSchema;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof CommentListResponseSchemaInput
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface CommentListResponseSchemaOutput
 */
export interface CommentListResponseSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof CommentListResponseSchemaOutput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof CommentListResponseSchemaOutput
     */
    'message'?: any;
    /**
     * 
     * @type {CommentListDataSchema}
     * @memberof CommentListResponseSchemaOutput
     */
    'data': CommentListDataSchema;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof CommentListResponseSchemaOutput
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface CommentOutSchema
 */
export interface CommentOutSchema {
    /**
     * 
     * @type {any}
     * @memberof CommentOutSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof CommentOutSchema
     */
    'text': any;
    /**
     * 
     * @type {any}
     * @memberof CommentOutSchema
     */
    'report_id': any;
    /**
     * 
     * @type {any}
     * @memberof CommentOutSchema
     */
    'previous_comment_id': any | null;
    /**
     * 
     * @type {any}
     * @memberof CommentOutSchema
     */
    'sender_id': any;
}
/**
 * 
 * @export
 * @interface CommentResponseSchemaInput
 */
export interface CommentResponseSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof CommentResponseSchemaInput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof CommentResponseSchemaInput
     */
    'message'?: any;
    /**
     * 
     * @type {CommentDataSchema}
     * @memberof CommentResponseSchemaInput
     */
    'data': CommentDataSchema;
}
/**
 * 
 * @export
 * @interface CommentResponseSchemaOutput
 */
export interface CommentResponseSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof CommentResponseSchemaOutput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof CommentResponseSchemaOutput
     */
    'message'?: any;
    /**
     * 
     * @type {CommentDataSchema}
     * @memberof CommentResponseSchemaOutput
     */
    'data': CommentDataSchema;
}
/**
 * 
 * @export
 * @interface ConfigDataSchema
 */
export interface ConfigDataSchema {
    /**
     * 
     * @type {ConfigOutSchema}
     * @memberof ConfigDataSchema
     */
    'config': ConfigOutSchema;
}
/**
 * 
 * @export
 * @interface ConfigListDataSchema
 */
export interface ConfigListDataSchema {
    /**
     * 
     * @type {any}
     * @memberof ConfigListDataSchema
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface ConfigListResponseSchema
 */
export interface ConfigListResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof ConfigListResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ConfigListResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {ConfigListDataSchema}
     * @memberof ConfigListResponseSchema
     */
    'data': ConfigListDataSchema;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof ConfigListResponseSchema
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface ConfigOutSchema
 */
export interface ConfigOutSchema {
    /**
     * 
     * @type {any}
     * @memberof ConfigOutSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof ConfigOutSchema
     */
    'model': any;
    /**
     * 
     * @type {any}
     * @memberof ConfigOutSchema
     */
    'max_tokens': any;
    /**
     * 
     * @type {any}
     * @memberof ConfigOutSchema
     */
    'frequency_penalty': any;
    /**
     * 
     * @type {any}
     * @memberof ConfigOutSchema
     */
    'presence_penalty': any;
    /**
     * 
     * @type {any}
     * @memberof ConfigOutSchema
     */
    'temperature': any;
    /**
     * 
     * @type {any}
     * @memberof ConfigOutSchema
     */
    'top_p': any;
    /**
     * 
     * @type {any}
     * @memberof ConfigOutSchema
     */
    'stop': any;
}
/**
 * 
 * @export
 * @interface ConfigResponseSchema
 */
export interface ConfigResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof ConfigResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ConfigResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {ConfigDataSchema}
     * @memberof ConfigResponseSchema
     */
    'data': ConfigDataSchema;
}
/**
 * 
 * @export
 * @interface ConfigSchema
 */
export interface ConfigSchema {
    /**
     * 
     * @type {any}
     * @memberof ConfigSchema
     */
    'model'?: ConfigSchemaModelEnum;
    /**
     * 
     * @type {any}
     * @memberof ConfigSchema
     */
    'frequency_penalty'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof ConfigSchema
     */
    'presence_penalty'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof ConfigSchema
     */
    'max_tokens'?: any;
    /**
     * 
     * @type {any}
     * @memberof ConfigSchema
     */
    'temperature'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof ConfigSchema
     */
    'top_p'?: any | null;
    /**
     * 
     * @type {any}
     * @memberof ConfigSchema
     */
    'stop'?: any | null;
}

export const ConfigSchemaModelEnum = {
    _4o: 'gpt-4o',
    _4oMini: 'gpt-4o-mini'
} as const;

export type ConfigSchemaModelEnum = typeof ConfigSchemaModelEnum[keyof typeof ConfigSchemaModelEnum];

/**
 * 
 * @export
 * @interface CreateCommentSchema
 */
export interface CreateCommentSchema {
    /**
     * Comment text
     * @type {any}
     * @memberof CreateCommentSchema
     */
    'text': any;
    /**
     * ID of previous comment for the report
     * @type {any}
     * @memberof CreateCommentSchema
     */
    'previous_comment_id': any | null;
}
/**
 * 
 * @export
 * @interface CreateReportSchema
 */
export interface CreateReportSchema {
    /**
     * 
     * @type {any}
     * @memberof CreateReportSchema
     */
    'article_id': any;
    /**
     * ID of report reason
     * @type {any}
     * @memberof CreateReportSchema
     */
    'reason_id': any;
    /**
     * Report text
     * @type {any}
     * @memberof CreateReportSchema
     */
    'text': any;
}
/**
 * 
 * @export
 * @interface Currency
 */
export interface Currency {
}
/**
 * 
 * @export
 * @interface DebugResponse500Schema
 */
export interface DebugResponse500Schema {
    /**
     * 
     * @type {any}
     * @memberof DebugResponse500Schema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof DebugResponse500Schema
     */
    'message': any;
    /**
     * 
     * @type {any}
     * @memberof DebugResponse500Schema
     */
    'trace': any;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {any}
     * @memberof HTTPValidationError
     */
    'detail'?: any;
}
/**
 * 
 * @export
 * @interface ItemCreateSchema
 */
export interface ItemCreateSchema {
    /**
     * 
     * @type {any}
     * @memberof ItemCreateSchema
     */
    'name': any;
    /**
     * 
     * @type {ItemType}
     * @memberof ItemCreateSchema
     */
    'type': ItemType;
    /**
     * Additional element data for type \'tokens_package\', must contain mandatory field \'token_amount\'.
     * @type {any}
     * @memberof ItemCreateSchema
     */
    'data': any;
}


/**
 * 
 * @export
 * @interface ItemDataResponseSchemaInput
 */
export interface ItemDataResponseSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof ItemDataResponseSchemaInput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ItemDataResponseSchemaInput
     */
    'message'?: any;
    /**
     * 
     * @type {ItemDataSchemaInput}
     * @memberof ItemDataResponseSchemaInput
     */
    'data': ItemDataSchemaInput;
}
/**
 * 
 * @export
 * @interface ItemDataResponseSchemaOutput
 */
export interface ItemDataResponseSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof ItemDataResponseSchemaOutput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ItemDataResponseSchemaOutput
     */
    'message'?: any;
    /**
     * 
     * @type {ItemDataSchemaOutput}
     * @memberof ItemDataResponseSchemaOutput
     */
    'data': ItemDataSchemaOutput;
}
/**
 * 
 * @export
 * @interface ItemDataSchemaInput
 */
export interface ItemDataSchemaInput {
    /**
     * 
     * @type {ItemResponseSchemaInput}
     * @memberof ItemDataSchemaInput
     */
    'item': ItemResponseSchemaInput;
}
/**
 * 
 * @export
 * @interface ItemDataSchemaOutput
 */
export interface ItemDataSchemaOutput {
    /**
     * 
     * @type {ItemResponseSchemaOutput}
     * @memberof ItemDataSchemaOutput
     */
    'item': ItemResponseSchemaOutput;
}
/**
 * 
 * @export
 * @interface ItemListDataResponseSchemaInput
 */
export interface ItemListDataResponseSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof ItemListDataResponseSchemaInput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ItemListDataResponseSchemaInput
     */
    'message'?: any;
    /**
     * 
     * @type {ItemListDataSchemaInput}
     * @memberof ItemListDataResponseSchemaInput
     */
    'data': ItemListDataSchemaInput;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof ItemListDataResponseSchemaInput
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface ItemListDataResponseSchemaOutput
 */
export interface ItemListDataResponseSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof ItemListDataResponseSchemaOutput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ItemListDataResponseSchemaOutput
     */
    'message'?: any;
    /**
     * 
     * @type {ItemListDataSchemaOutput}
     * @memberof ItemListDataResponseSchemaOutput
     */
    'data': ItemListDataSchemaOutput;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof ItemListDataResponseSchemaOutput
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface ItemListDataSchemaInput
 */
export interface ItemListDataSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof ItemListDataSchemaInput
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface ItemListDataSchemaOutput
 */
export interface ItemListDataSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof ItemListDataSchemaOutput
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface ItemPriceCreateSchema
 */
export interface ItemPriceCreateSchema {
    /**
     * 
     * @type {any}
     * @memberof ItemPriceCreateSchema
     */
    'payment_method_id': any;
    /**
     * 
     * @type {any}
     * @memberof ItemPriceCreateSchema
     */
    'price': any;
    /**
     * 
     * @type {any}
     * @memberof ItemPriceCreateSchema
     */
    'discount_price': any;
    /**
     * 
     * @type {Currency}
     * @memberof ItemPriceCreateSchema
     */
    'currency': Currency;
}


/**
 * 
 * @export
 * @interface ItemPriceDataResponseSchemaInput
 */
export interface ItemPriceDataResponseSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof ItemPriceDataResponseSchemaInput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ItemPriceDataResponseSchemaInput
     */
    'message'?: any;
    /**
     * 
     * @type {ItemPriceDataSchemaInput}
     * @memberof ItemPriceDataResponseSchemaInput
     */
    'data': ItemPriceDataSchemaInput;
}
/**
 * 
 * @export
 * @interface ItemPriceDataResponseSchemaOutput
 */
export interface ItemPriceDataResponseSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof ItemPriceDataResponseSchemaOutput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ItemPriceDataResponseSchemaOutput
     */
    'message'?: any;
    /**
     * 
     * @type {ItemPriceDataSchemaOutput}
     * @memberof ItemPriceDataResponseSchemaOutput
     */
    'data': ItemPriceDataSchemaOutput;
}
/**
 * 
 * @export
 * @interface ItemPriceDataSchemaInput
 */
export interface ItemPriceDataSchemaInput {
    /**
     * 
     * @type {ItemPriceResponseSchemaInput}
     * @memberof ItemPriceDataSchemaInput
     */
    'price': ItemPriceResponseSchemaInput;
}
/**
 * 
 * @export
 * @interface ItemPriceDataSchemaOutput
 */
export interface ItemPriceDataSchemaOutput {
    /**
     * 
     * @type {ItemPriceResponseSchemaOutput}
     * @memberof ItemPriceDataSchemaOutput
     */
    'price': ItemPriceResponseSchemaOutput;
}
/**
 * 
 * @export
 * @interface ItemPriceListDataResponseSchemaInput
 */
export interface ItemPriceListDataResponseSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof ItemPriceListDataResponseSchemaInput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ItemPriceListDataResponseSchemaInput
     */
    'message'?: any;
    /**
     * 
     * @type {ItemPriceListDataSchemaInput}
     * @memberof ItemPriceListDataResponseSchemaInput
     */
    'data': ItemPriceListDataSchemaInput;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof ItemPriceListDataResponseSchemaInput
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface ItemPriceListDataResponseSchemaOutput
 */
export interface ItemPriceListDataResponseSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof ItemPriceListDataResponseSchemaOutput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ItemPriceListDataResponseSchemaOutput
     */
    'message'?: any;
    /**
     * 
     * @type {ItemPriceListDataSchemaOutput}
     * @memberof ItemPriceListDataResponseSchemaOutput
     */
    'data': ItemPriceListDataSchemaOutput;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof ItemPriceListDataResponseSchemaOutput
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface ItemPriceListDataSchemaInput
 */
export interface ItemPriceListDataSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof ItemPriceListDataSchemaInput
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface ItemPriceListDataSchemaOutput
 */
export interface ItemPriceListDataSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof ItemPriceListDataSchemaOutput
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface ItemPriceResponseSchemaInput
 */
export interface ItemPriceResponseSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof ItemPriceResponseSchemaInput
     */
    'payment_method_id': any;
    /**
     * 
     * @type {any}
     * @memberof ItemPriceResponseSchemaInput
     */
    'price': any;
    /**
     * 
     * @type {any}
     * @memberof ItemPriceResponseSchemaInput
     */
    'discount_price': any;
    /**
     * 
     * @type {Currency}
     * @memberof ItemPriceResponseSchemaInput
     */
    'currency': Currency;
    /**
     * 
     * @type {any}
     * @memberof ItemPriceResponseSchemaInput
     */
    'id': any;
}


/**
 * 
 * @export
 * @interface ItemPriceResponseSchemaOutput
 */
export interface ItemPriceResponseSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof ItemPriceResponseSchemaOutput
     */
    'payment_method_id': any;
    /**
     * 
     * @type {any}
     * @memberof ItemPriceResponseSchemaOutput
     */
    'price': any;
    /**
     * 
     * @type {any}
     * @memberof ItemPriceResponseSchemaOutput
     */
    'discount_price': any;
    /**
     * 
     * @type {Currency}
     * @memberof ItemPriceResponseSchemaOutput
     */
    'currency': Currency;
    /**
     * 
     * @type {any}
     * @memberof ItemPriceResponseSchemaOutput
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof ItemPriceResponseSchemaOutput
     */
    'discount_percent': any;
}


/**
 * 
 * @export
 * @interface ItemResponseSchemaInput
 */
export interface ItemResponseSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof ItemResponseSchemaInput
     */
    'name': any;
    /**
     * 
     * @type {ItemType}
     * @memberof ItemResponseSchemaInput
     */
    'type': ItemType;
    /**
     * Additional element data for type \'tokens_package\', must contain mandatory field \'token_amount\'.
     * @type {any}
     * @memberof ItemResponseSchemaInput
     */
    'data': any;
    /**
     * 
     * @type {any}
     * @memberof ItemResponseSchemaInput
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof ItemResponseSchemaInput
     */
    'prices'?: any | null;
}


/**
 * 
 * @export
 * @interface ItemResponseSchemaOutput
 */
export interface ItemResponseSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof ItemResponseSchemaOutput
     */
    'name': any;
    /**
     * 
     * @type {ItemType}
     * @memberof ItemResponseSchemaOutput
     */
    'type': ItemType | null;
    /**
     * Additional element data for type \'tokens_package\', must contain mandatory field \'token_amount\'.
     * @type {any}
     * @memberof ItemResponseSchemaOutput
     */
    'data': any;
    /**
     * 
     * @type {any}
     * @memberof ItemResponseSchemaOutput
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof ItemResponseSchemaOutput
     */
    'prices'?: any | null;
}


/**
 * 
 * @export
 * @interface ItemType
 */
export interface ItemType {
}
/**
 * 
 * @export
 * @interface LanguageListResponseSchema
 */
export interface LanguageListResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof LanguageListResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof LanguageListResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof LanguageListResponseSchema
     */
    'data': { [key: string]: any; };
    /**
     * 
     * @type {PaginationSchema}
     * @memberof LanguageListResponseSchema
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface LanguagesOutSchema
 */
export interface LanguagesOutSchema {
    /**
     * 
     * @type {any}
     * @memberof LanguagesOutSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof LanguagesOutSchema
     */
    'name': any;
    /**
     * 
     * @type {any}
     * @memberof LanguagesOutSchema
     */
    'iso_code': any;
}
/**
 * 
 * @export
 * @interface LikeOutSchema
 */
export interface LikeOutSchema {
    /**
     * 
     * @type {any}
     * @memberof LikeOutSchema
     */
    'like': any | null;
}
/**
 * 
 * @export
 * @interface LikeResponseSchema
 */
export interface LikeResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof LikeResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof LikeResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {LikeOutSchema}
     * @memberof LikeResponseSchema
     */
    'data': LikeOutSchema;
}
/**
 * 
 * @export
 * @interface NotificationListDataSchema
 */
export interface NotificationListDataSchema {
    /**
     * 
     * @type {any}
     * @memberof NotificationListDataSchema
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface NotificationListResponseSchema
 */
export interface NotificationListResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof NotificationListResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof NotificationListResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {NotificationListDataSchema}
     * @memberof NotificationListResponseSchema
     */
    'data': NotificationListDataSchema;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof NotificationListResponseSchema
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface NotificationOutSchema
 */
export interface NotificationOutSchema {
    /**
     * 
     * @type {any}
     * @memberof NotificationOutSchema
     */
    'subject': any;
    /**
     * 
     * @type {any}
     * @memberof NotificationOutSchema
     */
    'message': any;
}
/**
 * This class represents a notification schema with attributes for subject, message, and notify_type.  Attributes: subject (str): The subject of the notification. Does not support any formatters message (str): The content of the notification. Does not support any formatters
 * @export
 * @interface NotificationSchema
 */
export interface NotificationSchema {
    /**
     * 
     * @type {any}
     * @memberof NotificationSchema
     */
    'subject': any;
    /**
     * 
     * @type {any}
     * @memberof NotificationSchema
     */
    'message': any;
}
/**
 * 
 * @export
 * @interface OrderCreateSchema
 */
export interface OrderCreateSchema {
    /**
     * 
     * @type {any}
     * @memberof OrderCreateSchema
     */
    'article_id_list': any;
    /**
     * 
     * @type {any}
     * @memberof OrderCreateSchema
     */
    'language_id_list': any;
}
/**
 * 
 * @export
 * @interface OrderListDataResponseSchema
 */
export interface OrderListDataResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof OrderListDataResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof OrderListDataResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {OrderListDataSchema}
     * @memberof OrderListDataResponseSchema
     */
    'data': OrderListDataSchema;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof OrderListDataResponseSchema
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface OrderListDataSchema
 */
export interface OrderListDataSchema {
    /**
     * 
     * @type {any}
     * @memberof OrderListDataSchema
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface OrderResponseSchema
 */
export interface OrderResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof OrderResponseSchema
     */
    'article_id': any;
    /**
     * 
     * @type {any}
     * @memberof OrderResponseSchema
     */
    'target_lang_id': any;
    /**
     * 
     * @type {any}
     * @memberof OrderResponseSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof OrderResponseSchema
     */
    'price': any;
    /**
     * 
     * @type {OrderStatus}
     * @memberof OrderResponseSchema
     */
    'status': OrderStatus;
    /**
     * 
     * @type {any}
     * @memberof OrderResponseSchema
     */
    'group_id'?: any | null;
}


/**
 * 
 * @export
 * @interface OrderStatus
 */
export interface OrderStatus {
}
/**
 * Pagination data schema  Attributes:     total_items (int): total number of elements     page (int): current page     items_per_page (int): number of items per page     next_page (int | None): number of the next page     prev_page (int | None): number of the previous page     total_pages (int): number of total pages
 * @export
 * @interface PaginationSchema
 */
export interface PaginationSchema {
    /**
     * Total number of elements (must be positive)
     * @type {any}
     * @memberof PaginationSchema
     */
    'total_items': any;
    /**
     * Current page (must be at least 1)
     * @type {any}
     * @memberof PaginationSchema
     */
    'page': any;
    /**
     * Number of items per page (must be positive)
     * @type {any}
     * @memberof PaginationSchema
     */
    'items_per_page': any;
    /**
     * Number of the next page (can be None)
     * @type {any}
     * @memberof PaginationSchema
     */
    'next_page'?: any | null;
    /**
     * Number of the previous page (can be None)
     * @type {any}
     * @memberof PaginationSchema
     */
    'prev_page'?: any | null;
    /**
     * Number of total pages (must be positive)
     * @type {any}
     * @memberof PaginationSchema
     */
    'total_pages': any;
}
/**
 * 
 * @export
 * @interface PaymentMethodCreateSchema
 */
export interface PaymentMethodCreateSchema {
    /**
     * 
     * @type {any}
     * @memberof PaymentMethodCreateSchema
     */
    'name': any;
    /**
     * 
     * @type {any}
     * @memberof PaymentMethodCreateSchema
     */
    'is_active': any;
}
/**
 * 
 * @export
 * @interface PaymentMethodDataResponseSchema
 */
export interface PaymentMethodDataResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof PaymentMethodDataResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof PaymentMethodDataResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {PaymentMethodDataSchema}
     * @memberof PaymentMethodDataResponseSchema
     */
    'data': PaymentMethodDataSchema;
}
/**
 * 
 * @export
 * @interface PaymentMethodDataSchema
 */
export interface PaymentMethodDataSchema {
    /**
     * 
     * @type {PaymentMethodResponseSchema}
     * @memberof PaymentMethodDataSchema
     */
    'payment_method': PaymentMethodResponseSchema;
}
/**
 * 
 * @export
 * @interface PaymentMethodListDataResponseSchema
 */
export interface PaymentMethodListDataResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof PaymentMethodListDataResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof PaymentMethodListDataResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {PaymentMethodListDataSchema}
     * @memberof PaymentMethodListDataResponseSchema
     */
    'data': PaymentMethodListDataSchema;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof PaymentMethodListDataResponseSchema
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface PaymentMethodListDataSchema
 */
export interface PaymentMethodListDataSchema {
    /**
     * 
     * @type {any}
     * @memberof PaymentMethodListDataSchema
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface PaymentMethodResponseSchema
 */
export interface PaymentMethodResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof PaymentMethodResponseSchema
     */
    'name': any;
    /**
     * 
     * @type {any}
     * @memberof PaymentMethodResponseSchema
     */
    'is_active': any;
    /**
     * 
     * @type {any}
     * @memberof PaymentMethodResponseSchema
     */
    'id': any;
}
/**
 * 
 * @export
 * @interface PaymentMethodUpdateSchema
 */
export interface PaymentMethodUpdateSchema {
    /**
     * 
     * @type {any}
     * @memberof PaymentMethodUpdateSchema
     */
    'name': any;
    /**
     * 
     * @type {any}
     * @memberof PaymentMethodUpdateSchema
     */
    'is_active': any;
}
/**
 * 
 * @export
 * @interface PromptDataSchema
 */
export interface PromptDataSchema {
    /**
     * 
     * @type {PromptOutSchema}
     * @memberof PromptDataSchema
     */
    'prompt': PromptOutSchema;
}
/**
 * 
 * @export
 * @interface PromptListDataSchema
 */
export interface PromptListDataSchema {
    /**
     * 
     * @type {any}
     * @memberof PromptListDataSchema
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface PromptListResponseSchema
 */
export interface PromptListResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof PromptListResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof PromptListResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {PromptListDataSchema}
     * @memberof PromptListResponseSchema
     */
    'data': PromptListDataSchema;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof PromptListResponseSchema
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface PromptOutSchema
 */
export interface PromptOutSchema {
    /**
     * 
     * @type {any}
     * @memberof PromptOutSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof PromptOutSchema
     */
    'text': any;
}
/**
 * 
 * @export
 * @interface PromptResponseSchema
 */
export interface PromptResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof PromptResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof PromptResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {PromptDataSchema}
     * @memberof PromptResponseSchema
     */
    'data': PromptDataSchema;
}
/**
 * 
 * @export
 * @interface PromptSchema
 */
export interface PromptSchema {
    /**
     * 
     * @type {any}
     * @memberof PromptSchema
     */
    'text': any;
}
/**
 * 
 * @export
 * @interface PurchaseItemSchema
 */
export interface PurchaseItemSchema {
    /**
     * 
     * @type {any}
     * @memberof PurchaseItemSchema
     */
    'price_id': any;
    /**
     * 
     * @type {any}
     * @memberof PurchaseItemSchema
     */
    'amount': any;
}
/**
 * 
 * @export
 * @interface PurchasePaymnetLinkDataResponseSchema
 */
export interface PurchasePaymnetLinkDataResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof PurchasePaymnetLinkDataResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof PurchasePaymnetLinkDataResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {PurchasePaymnetLinkDataSchema}
     * @memberof PurchasePaymnetLinkDataResponseSchema
     */
    'data': PurchasePaymnetLinkDataSchema;
}
/**
 * 
 * @export
 * @interface PurchasePaymnetLinkDataSchema
 */
export interface PurchasePaymnetLinkDataSchema {
    /**
     * 
     * @type {PurchasePaymnetLinkResponseSchema}
     * @memberof PurchasePaymnetLinkDataSchema
     */
    'payemnt_link': PurchasePaymnetLinkResponseSchema;
}
/**
 * 
 * @export
 * @interface PurchasePaymnetLinkResponseSchema
 */
export interface PurchasePaymnetLinkResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof PurchasePaymnetLinkResponseSchema
     */
    'payment_id': any;
    /**
     * 
     * @type {any}
     * @memberof PurchasePaymnetLinkResponseSchema
     */
    'redirect_url': any;
}
/**
 * 
 * @export
 * @interface QueueDataResponseSchema
 */
export interface QueueDataResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof QueueDataResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof QueueDataResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {QueueDataSchema}
     * @memberof QueueDataResponseSchema
     */
    'data': QueueDataSchema;
}
/**
 * 
 * @export
 * @interface QueueDataSchema
 */
export interface QueueDataSchema {
    /**
     * 
     * @type {QueueOutSchema}
     * @memberof QueueDataSchema
     */
    'task': QueueOutSchema;
}
/**
 * 
 * @export
 * @interface QueueOutSchema
 */
export interface QueueOutSchema {
    /**
     * 
     * @type {any}
     * @memberof QueueOutSchema
     */
    'task_uuid': any;
}
/**
 * 
 * @export
 * @interface ReportDataSchemaInput
 */
export interface ReportDataSchemaInput {
    /**
     * 
     * @type {ReportOutSchema}
     * @memberof ReportDataSchemaInput
     */
    'report': ReportOutSchema;
}
/**
 * 
 * @export
 * @interface ReportDataSchemaOutput
 */
export interface ReportDataSchemaOutput {
    /**
     * 
     * @type {ReportOutSchema}
     * @memberof ReportDataSchemaOutput
     */
    'report': ReportOutSchema;
}
/**
 * 
 * @export
 * @interface ReportListDataSchemaInput
 */
export interface ReportListDataSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof ReportListDataSchemaInput
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface ReportListDataSchemaOutput
 */
export interface ReportListDataSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof ReportListDataSchemaOutput
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface ReportListResponseSchemaInput
 */
export interface ReportListResponseSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof ReportListResponseSchemaInput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ReportListResponseSchemaInput
     */
    'message'?: any;
    /**
     * 
     * @type {ReportListDataSchemaInput}
     * @memberof ReportListResponseSchemaInput
     */
    'data': ReportListDataSchemaInput;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof ReportListResponseSchemaInput
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface ReportListResponseSchemaOutput
 */
export interface ReportListResponseSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof ReportListResponseSchemaOutput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ReportListResponseSchemaOutput
     */
    'message'?: any;
    /**
     * 
     * @type {ReportListDataSchemaOutput}
     * @memberof ReportListResponseSchemaOutput
     */
    'data': ReportListDataSchemaOutput;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof ReportListResponseSchemaOutput
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface ReportOutSchema
 */
export interface ReportOutSchema {
    /**
     * 
     * @type {any}
     * @memberof ReportOutSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof ReportOutSchema
     */
    'text': any;
    /**
     * 
     * @type {any}
     * @memberof ReportOutSchema
     */
    'article_id': any;
    /**
     * 
     * @type {ReportStatus}
     * @memberof ReportOutSchema
     */
    'status': ReportStatus;
    /**
     * 
     * @type {any}
     * @memberof ReportOutSchema
     */
    'reason_id': any;
    /**
     * 
     * @type {any}
     * @memberof ReportOutSchema
     */
    'closed_by_user_id': any | null;
}


/**
 * 
 * @export
 * @interface ReportResponseSchemaInput
 */
export interface ReportResponseSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof ReportResponseSchemaInput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ReportResponseSchemaInput
     */
    'message'?: any;
    /**
     * 
     * @type {ReportDataSchemaInput}
     * @memberof ReportResponseSchemaInput
     */
    'data': ReportDataSchemaInput;
}
/**
 * 
 * @export
 * @interface ReportResponseSchemaOutput
 */
export interface ReportResponseSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof ReportResponseSchemaOutput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof ReportResponseSchemaOutput
     */
    'message'?: any;
    /**
     * 
     * @type {ReportDataSchemaOutput}
     * @memberof ReportResponseSchemaOutput
     */
    'data': ReportDataSchemaOutput;
}
/**
 * 
 * @export
 * @interface ReportStatus
 */
export interface ReportStatus {
}
/**
 * 
 * @export
 * @interface Response200Schema
 */
export interface Response200Schema {
    /**
     * 
     * @type {any}
     * @memberof Response200Schema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response200Schema
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response400Schema
 */
export interface Response400Schema {
    /**
     * 
     * @type {any}
     * @memberof Response400Schema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response400Schema
     */
    'message': any;
    /**
     * 
     * @type {any}
     * @memberof Response400Schema
     */
    'details'?: any | null;
}
/**
 * 
 * @export
 * @interface Response401Schema
 */
export interface Response401Schema {
    /**
     * 
     * @type {any}
     * @memberof Response401Schema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response401Schema
     */
    'message': any;
}
/**
 * 
 * @export
 * @interface Response403Schema
 */
export interface Response403Schema {
    /**
     * 
     * @type {any}
     * @memberof Response403Schema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response403Schema
     */
    'message': any;
}
/**
 * 
 * @export
 * @interface Response404Schema
 */
export interface Response404Schema {
    /**
     * 
     * @type {any}
     * @memberof Response404Schema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response404Schema
     */
    'message': any;
}
/**
 * 
 * @export
 * @interface Response413Schema
 */
export interface Response413Schema {
    /**
     * 
     * @type {any}
     * @memberof Response413Schema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response413Schema
     */
    'message': any;
}
/**
 * 
 * @export
 * @interface Response500Schema
 */
export interface Response500Schema {
    /**
     * 
     * @type {any}
     * @memberof Response500Schema
     */
    'success'?: any;
    /**
     * A brief description of the error.
     * @type {any}
     * @memberof Response500Schema
     */
    'message'?: any;
}
/**
 * 
 * @export
 * @interface Response503Schema
 */
export interface Response503Schema {
    /**
     * 
     * @type {any}
     * @memberof Response503Schema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof Response503Schema
     */
    'message': any;
}
/**
 * 
 * @export
 * @interface SortArticleOptions
 */
export interface SortArticleOptions {
}
/**
 * 
 * @export
 * @interface SortCommentOptions
 */
export interface SortCommentOptions {
}
/**
 * 
 * @export
 * @interface SortNotificationOptions
 */
export interface SortNotificationOptions {
}
/**
 * 
 * @export
 * @interface SortReportOptions
 */
export interface SortReportOptions {
}
/**
 * 
 * @export
 * @interface SortTranslationOptions
 */
export interface SortTranslationOptions {
}
/**
 * 
 * @export
 * @interface SubscriptionResponseSchema
 */
export interface SubscriptionResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof SubscriptionResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof SubscriptionResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {SubscriptionSettingsDataSchema}
     * @memberof SubscriptionResponseSchema
     */
    'data': SubscriptionSettingsDataSchema;
}
/**
 * 
 * @export
 * @interface SubscriptionSettingsDataSchema
 */
export interface SubscriptionSettingsDataSchema {
    /**
     * 
     * @type {SubscriptionSettingsOutSchema}
     * @memberof SubscriptionSettingsDataSchema
     */
    'settings': SubscriptionSettingsOutSchema;
}
/**
 * 
 * @export
 * @interface SubscriptionSettingsOutSchema
 */
export interface SubscriptionSettingsOutSchema {
    /**
     * 
     * @type {any}
     * @memberof SubscriptionSettingsOutSchema
     */
    'send_marketing_email': any;
}
/**
 * 
 * @export
 * @interface TransactionListDataResponseSchemaInput
 */
export interface TransactionListDataResponseSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof TransactionListDataResponseSchemaInput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof TransactionListDataResponseSchemaInput
     */
    'message'?: any;
    /**
     * 
     * @type {TransactionListDataSchemaInput}
     * @memberof TransactionListDataResponseSchemaInput
     */
    'data': TransactionListDataSchemaInput;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof TransactionListDataResponseSchemaInput
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface TransactionListDataResponseSchemaOutput
 */
export interface TransactionListDataResponseSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof TransactionListDataResponseSchemaOutput
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof TransactionListDataResponseSchemaOutput
     */
    'message'?: any;
    /**
     * 
     * @type {TransactionListDataSchemaOutput}
     * @memberof TransactionListDataResponseSchemaOutput
     */
    'data': TransactionListDataSchemaOutput;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof TransactionListDataResponseSchemaOutput
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface TransactionListDataSchemaInput
 */
export interface TransactionListDataSchemaInput {
    /**
     * 
     * @type {any}
     * @memberof TransactionListDataSchemaInput
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface TransactionListDataSchemaOutput
 */
export interface TransactionListDataSchemaOutput {
    /**
     * 
     * @type {any}
     * @memberof TransactionListDataSchemaOutput
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface TransactionResponseSchema
 */
export interface TransactionResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof TransactionResponseSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof TransactionResponseSchema
     */
    'user_id': any;
    /**
     * 
     * @type {any}
     * @memberof TransactionResponseSchema
     */
    'data': any;
    /**
     * 
     * @type {any}
     * @memberof TransactionResponseSchema
     */
    'order_id'?: any | null;
    /**
     * 
     * @type {TransactionStatus}
     * @memberof TransactionResponseSchema
     */
    'status': TransactionStatus;
}


/**
 * 
 * @export
 * @interface TransactionStatus
 */
export interface TransactionStatus {
}
/**
 * 
 * @export
 * @interface TranslationListDataSchema
 */
export interface TranslationListDataSchema {
    /**
     * 
     * @type {any}
     * @memberof TranslationListDataSchema
     */
    'list': any;
}
/**
 * 
 * @export
 * @interface TranslationListResponseSchema
 */
export interface TranslationListResponseSchema {
    /**
     * 
     * @type {any}
     * @memberof TranslationListResponseSchema
     */
    'success'?: any;
    /**
     * 
     * @type {any}
     * @memberof TranslationListResponseSchema
     */
    'message'?: any;
    /**
     * 
     * @type {TranslationListDataSchema}
     * @memberof TranslationListResponseSchema
     */
    'data': TranslationListDataSchema;
    /**
     * 
     * @type {PaginationSchema}
     * @memberof TranslationListResponseSchema
     */
    'pagination'?: PaginationSchema | null;
}
/**
 * 
 * @export
 * @interface TranslationOutSchema
 */
export interface TranslationOutSchema {
    /**
     * 
     * @type {any}
     * @memberof TranslationOutSchema
     */
    'id': any;
    /**
     * 
     * @type {any}
     * @memberof TranslationOutSchema
     */
    'prompt_id': any;
    /**
     * 
     * @type {any}
     * @memberof TranslationOutSchema
     */
    'config_id': any;
    /**
     * 
     * @type {any}
     * @memberof TranslationOutSchema
     */
    'input_text': any;
    /**
     * 
     * @type {any}
     * @memberof TranslationOutSchema
     */
    'output_text': any;
    /**
     * 
     * @type {any}
     * @memberof TranslationOutSchema
     */
    'source_lang': any;
    /**
     * 
     * @type {any}
     * @memberof TranslationOutSchema
     */
    'target_lang': any;
}
/**
 * 
 * @export
 * @interface UpdateCommentSchema
 */
export interface UpdateCommentSchema {
    /**
     * New comment text
     * @type {any}
     * @memberof UpdateCommentSchema
     */
    'text': any;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {any}
     * @memberof ValidationError
     */
    'loc': any;
    /**
     * 
     * @type {any}
     * @memberof ValidationError
     */
    'msg': any;
    /**
     * 
     * @type {any}
     * @memberof ValidationError
     */
    'type': any;
}

/**
 * ActiveConfigApi - axios parameter creator
 * @export
 */
export const ActiveConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new active config.
         * @summary Creates a new active config.
         * @param {ActiveConfigSchema} activeConfigSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActiveConfigActiveConfigsPost: async (activeConfigSchema: ActiveConfigSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeConfigSchema' is not null or undefined
            assertParamExists('createActiveConfigActiveConfigsPost', 'activeConfigSchema', activeConfigSchema)
            const localVarPath = `/active-configs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activeConfigSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing active config by its ID.
         * @summary Deletes an existing active config by its ID.
         * @param {any} activeConfigId Active config primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActiveConfigActiveConfigsActiveConfigIdDelete: async (activeConfigId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeConfigId' is not null or undefined
            assertParamExists('deleteActiveConfigActiveConfigsActiveConfigIdDelete', 'activeConfigId', activeConfigId)
            const localVarPath = `/active-configs/{active_config_id}/`
                .replace(`{${"active_config_id"}}`, encodeURIComponent(String(activeConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a specific active config by its ID.
         * @summary Retrieves a specific active config by its ID.
         * @param {any} activeConfigId Active config primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveConfigActiveConfigsActiveConfigIdGet: async (activeConfigId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeConfigId' is not null or undefined
            assertParamExists('getActiveConfigActiveConfigsActiveConfigIdGet', 'activeConfigId', activeConfigId)
            const localVarPath = `/active-configs/{active_config_id}/`
                .replace(`{${"active_config_id"}}`, encodeURIComponent(String(activeConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of active configs.
         * @summary Retrieves a list of active configs.
         * @param {any | null} [isActive] Filter by activity
         * @param {any | null} [promptId] Filter by prompt ID
         * @param {any | null} [configId] Filter by config ID
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveConfigsActiveConfigsGet: async (isActive?: any | null, promptId?: any | null, configId?: any | null, page?: any, perPage?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/active-configs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isActive !== undefined) {
                localVarQueryParameter['is_active'] = isActive;
            }

            if (promptId !== undefined) {
                localVarQueryParameter['prompt_id'] = promptId;
            }

            if (configId !== undefined) {
                localVarQueryParameter['config_id'] = configId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing active config by its ID.
         * @summary Updates an existing active config by its ID.
         * @param {any} activeConfigId Active config primary key
         * @param {ActiveConfigSchema} activeConfigSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActiveConfigActiveConfigsActiveConfigIdPut: async (activeConfigId: any, activeConfigSchema: ActiveConfigSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activeConfigId' is not null or undefined
            assertParamExists('updateActiveConfigActiveConfigsActiveConfigIdPut', 'activeConfigId', activeConfigId)
            // verify required parameter 'activeConfigSchema' is not null or undefined
            assertParamExists('updateActiveConfigActiveConfigsActiveConfigIdPut', 'activeConfigSchema', activeConfigSchema)
            const localVarPath = `/active-configs/{active_config_id}/`
                .replace(`{${"active_config_id"}}`, encodeURIComponent(String(activeConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activeConfigSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActiveConfigApi - functional programming interface
 * @export
 */
export const ActiveConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActiveConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new active config.
         * @summary Creates a new active config.
         * @param {ActiveConfigSchema} activeConfigSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createActiveConfigActiveConfigsPost(activeConfigSchema: ActiveConfigSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveConfigResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createActiveConfigActiveConfigsPost(activeConfigSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing active config by its ID.
         * @summary Deletes an existing active config by its ID.
         * @param {any} activeConfigId Active config primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActiveConfigActiveConfigsActiveConfigIdDelete(activeConfigId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActiveConfigActiveConfigsActiveConfigIdDelete(activeConfigId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a specific active config by its ID.
         * @summary Retrieves a specific active config by its ID.
         * @param {any} activeConfigId Active config primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveConfigActiveConfigsActiveConfigIdGet(activeConfigId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveConfigResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveConfigActiveConfigsActiveConfigIdGet(activeConfigId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a list of active configs.
         * @summary Retrieves a list of active configs.
         * @param {any | null} [isActive] Filter by activity
         * @param {any | null} [promptId] Filter by prompt ID
         * @param {any | null} [configId] Filter by config ID
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActiveConfigsActiveConfigsGet(isActive?: any | null, promptId?: any | null, configId?: any | null, page?: any, perPage?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveConfigListResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveConfigsActiveConfigsGet(isActive, promptId, configId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing active config by its ID.
         * @summary Updates an existing active config by its ID.
         * @param {any} activeConfigId Active config primary key
         * @param {ActiveConfigSchema} activeConfigSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActiveConfigActiveConfigsActiveConfigIdPut(activeConfigId: any, activeConfigSchema: ActiveConfigSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActiveConfigResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActiveConfigActiveConfigsActiveConfigIdPut(activeConfigId, activeConfigSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActiveConfigApi - factory interface
 * @export
 */
export const ActiveConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActiveConfigApiFp(configuration)
    return {
        /**
         * Creates a new active config.
         * @summary Creates a new active config.
         * @param {ActiveConfigSchema} activeConfigSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createActiveConfigActiveConfigsPost(activeConfigSchema: ActiveConfigSchema, options?: any): AxiosPromise<ActiveConfigResponseSchema> {
            return localVarFp.createActiveConfigActiveConfigsPost(activeConfigSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing active config by its ID.
         * @summary Deletes an existing active config by its ID.
         * @param {any} activeConfigId Active config primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActiveConfigActiveConfigsActiveConfigIdDelete(activeConfigId: any, options?: any): AxiosPromise<Response200Schema> {
            return localVarFp.deleteActiveConfigActiveConfigsActiveConfigIdDelete(activeConfigId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a specific active config by its ID.
         * @summary Retrieves a specific active config by its ID.
         * @param {any} activeConfigId Active config primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveConfigActiveConfigsActiveConfigIdGet(activeConfigId: any, options?: any): AxiosPromise<ActiveConfigResponseSchema> {
            return localVarFp.getActiveConfigActiveConfigsActiveConfigIdGet(activeConfigId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of active configs.
         * @summary Retrieves a list of active configs.
         * @param {any | null} [isActive] Filter by activity
         * @param {any | null} [promptId] Filter by prompt ID
         * @param {any | null} [configId] Filter by config ID
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActiveConfigsActiveConfigsGet(isActive?: any | null, promptId?: any | null, configId?: any | null, page?: any, perPage?: any, options?: any): AxiosPromise<ActiveConfigListResponseSchema> {
            return localVarFp.getActiveConfigsActiveConfigsGet(isActive, promptId, configId, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing active config by its ID.
         * @summary Updates an existing active config by its ID.
         * @param {any} activeConfigId Active config primary key
         * @param {ActiveConfigSchema} activeConfigSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActiveConfigActiveConfigsActiveConfigIdPut(activeConfigId: any, activeConfigSchema: ActiveConfigSchema, options?: any): AxiosPromise<ActiveConfigResponseSchema> {
            return localVarFp.updateActiveConfigActiveConfigsActiveConfigIdPut(activeConfigId, activeConfigSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActiveConfigApi - object-oriented interface
 * @export
 * @class ActiveConfigApi
 * @extends {BaseAPI}
 */
export class ActiveConfigApi extends BaseAPI {
    /**
     * Creates a new active config.
     * @summary Creates a new active config.
     * @param {ActiveConfigSchema} activeConfigSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveConfigApi
     */
    public createActiveConfigActiveConfigsPost(activeConfigSchema: ActiveConfigSchema, options?: AxiosRequestConfig) {
        return ActiveConfigApiFp(this.configuration).createActiveConfigActiveConfigsPost(activeConfigSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing active config by its ID.
     * @summary Deletes an existing active config by its ID.
     * @param {any} activeConfigId Active config primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveConfigApi
     */
    public deleteActiveConfigActiveConfigsActiveConfigIdDelete(activeConfigId: any, options?: AxiosRequestConfig) {
        return ActiveConfigApiFp(this.configuration).deleteActiveConfigActiveConfigsActiveConfigIdDelete(activeConfigId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a specific active config by its ID.
     * @summary Retrieves a specific active config by its ID.
     * @param {any} activeConfigId Active config primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveConfigApi
     */
    public getActiveConfigActiveConfigsActiveConfigIdGet(activeConfigId: any, options?: AxiosRequestConfig) {
        return ActiveConfigApiFp(this.configuration).getActiveConfigActiveConfigsActiveConfigIdGet(activeConfigId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of active configs.
     * @summary Retrieves a list of active configs.
     * @param {any | null} [isActive] Filter by activity
     * @param {any | null} [promptId] Filter by prompt ID
     * @param {any | null} [configId] Filter by config ID
     * @param {any} [page] Page number (must be 1 or greater)
     * @param {any} [perPage] Number of items per page (must be 1 or greater)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveConfigApi
     */
    public getActiveConfigsActiveConfigsGet(isActive?: any | null, promptId?: any | null, configId?: any | null, page?: any, perPage?: any, options?: AxiosRequestConfig) {
        return ActiveConfigApiFp(this.configuration).getActiveConfigsActiveConfigsGet(isActive, promptId, configId, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing active config by its ID.
     * @summary Updates an existing active config by its ID.
     * @param {any} activeConfigId Active config primary key
     * @param {ActiveConfigSchema} activeConfigSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActiveConfigApi
     */
    public updateActiveConfigActiveConfigsActiveConfigIdPut(activeConfigId: any, activeConfigSchema: ActiveConfigSchema, options?: AxiosRequestConfig) {
        return ActiveConfigApiFp(this.configuration).updateActiveConfigActiveConfigsActiveConfigIdPut(activeConfigId, activeConfigSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ArticleApi - axios parameter creator
 * @export
 */
export const ArticleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new article. **Required fields**: title, text.
         * @summary Creates a new article.
         * @param {ArticleSchema} article 
         * @param {any | null} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArticleArticlesPost: async (article: ArticleSchema, image?: any | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'article' is not null or undefined
            assertParamExists('createArticleArticlesPost', 'article', article)
            const localVarPath = `/articles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (article !== undefined) { 
                localVarFormParams.append('article', new Blob([JSON.stringify(article)], { type: "application/json", }));
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', new Blob([JSON.stringify(image)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing article by its ID.
         * @summary Deletes an existing article by its ID.
         * @param {any} articleId Article primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArticleArticlesArticleIdDelete: async (articleId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('deleteArticleArticlesArticleIdDelete', 'articleId', articleId)
            const localVarPath = `/articles/{article_id}/`
                .replace(`{${"article_id"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Export the text of an article as a PDF.
         * @summary Export article text as PDF.
         * @param {BodyExportArticleToPdfViewArticlesExportPdfPost} bodyExportArticleToPdfViewArticlesExportPdfPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportArticleToPdfViewArticlesExportPdfPost: async (bodyExportArticleToPdfViewArticlesExportPdfPost: BodyExportArticleToPdfViewArticlesExportPdfPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyExportArticleToPdfViewArticlesExportPdfPost' is not null or undefined
            assertParamExists('exportArticleToPdfViewArticlesExportPdfPost', 'bodyExportArticleToPdfViewArticlesExportPdfPost', bodyExportArticleToPdfViewArticlesExportPdfPost)
            const localVarPath = `/articles/export-pdf/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyExportArticleToPdfViewArticlesExportPdfPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a specific article by its ID.
         * @summary Retrieves a specific article by its ID.
         * @param {any} articleId Article primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleArticlesArticleIdGet: async (articleId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getArticleArticlesArticleIdGet', 'articleId', articleId)
            const localVarPath = `/articles/{article_id}/`
                .replace(`{${"article_id"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of articles.
         * @summary Retrieves a list of articles.
         * @param {any | null} [isOriginal] Filter by originality
         * @param {any | null} [originalArticleId] Filter by original article ID
         * @param {any | null} [languageId] Filter by language ID
         * @param {any | null} [title] Search by title
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticlesArticlesGet: async (isOriginal?: any | null, originalArticleId?: any | null, languageId?: any | null, title?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/articles/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isOriginal !== undefined) {
                localVarQueryParameter['is_original'] = isOriginal;
            }

            if (originalArticleId !== undefined) {
                localVarQueryParameter['original_article_id'] = originalArticleId;
            }

            if (languageId !== undefined) {
                localVarQueryParameter['language_id'] = languageId;
            }

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing article by its ID. **Required fields**: title, text.
         * @summary Updates an existing article by its ID.
         * @param {any} articleId Article primary key
         * @param {ArticleSchema} articleSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticleArticlesArticleIdPut: async (articleId: any, articleSchema: ArticleSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('updateArticleArticlesArticleIdPut', 'articleId', articleId)
            // verify required parameter 'articleSchema' is not null or undefined
            assertParamExists('updateArticleArticlesArticleIdPut', 'articleSchema', articleSchema)
            const localVarPath = `/articles/{article_id}/`
                .replace(`{${"article_id"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(articleSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates article Like mark
         * @param {any} articleId 
         * @param {any | null} [newLikeMark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLikeArticlesArticleIdLikePatch: async (articleId: any, newLikeMark?: any | null, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('updateLikeArticlesArticleIdLikePatch', 'articleId', articleId)
            const localVarPath = `/articles/{article_id}/like/`
                .replace(`{${"article_id"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (newLikeMark !== undefined) {
                localVarQueryParameter['new_like_mark'] = newLikeMark;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a PDF and extract text from it, then save it as an article.
         * @summary Upload a PDF and extract text from it.
         * @param {any} file The PDF file to upload
         * @param {any} title The title of the article
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPdfArticlesImportPdfPost: async (file: any, title: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadPdfArticlesImportPdfPost', 'file', file)
            // verify required parameter 'title' is not null or undefined
            assertParamExists('uploadPdfArticlesImportPdfPost', 'title', title)
            const localVarPath = `/articles/import-pdf/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', new Blob([JSON.stringify(file)], { type: "application/json", }));
            }
    
            if (title !== undefined) { 
                localVarFormParams.append('title', new Blob([JSON.stringify(title)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ArticleApi - functional programming interface
 * @export
 */
export const ArticleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ArticleApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new article. **Required fields**: title, text.
         * @summary Creates a new article.
         * @param {ArticleSchema} article 
         * @param {any | null} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createArticleArticlesPost(article: ArticleSchema, image?: any | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createArticleArticlesPost(article, image, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing article by its ID.
         * @summary Deletes an existing article by its ID.
         * @param {any} articleId Article primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteArticleArticlesArticleIdDelete(articleId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteArticleArticlesArticleIdDelete(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Export the text of an article as a PDF.
         * @summary Export article text as PDF.
         * @param {BodyExportArticleToPdfViewArticlesExportPdfPost} bodyExportArticleToPdfViewArticlesExportPdfPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async exportArticleToPdfViewArticlesExportPdfPost(bodyExportArticleToPdfViewArticlesExportPdfPost: BodyExportArticleToPdfViewArticlesExportPdfPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.exportArticleToPdfViewArticlesExportPdfPost(bodyExportArticleToPdfViewArticlesExportPdfPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a specific article by its ID.
         * @summary Retrieves a specific article by its ID.
         * @param {any} articleId Article primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticleArticlesArticleIdGet(articleId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticleArticlesArticleIdGet(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a list of articles.
         * @summary Retrieves a list of articles.
         * @param {any | null} [isOriginal] Filter by originality
         * @param {any | null} [originalArticleId] Filter by original article ID
         * @param {any | null} [languageId] Filter by language ID
         * @param {any | null} [title] Search by title
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArticlesArticlesGet(isOriginal?: any | null, originalArticleId?: any | null, languageId?: any | null, title?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleListResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArticlesArticlesGet(isOriginal, originalArticleId, languageId, title, page, perPage, sortBy, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing article by its ID. **Required fields**: title, text.
         * @summary Updates an existing article by its ID.
         * @param {any} articleId Article primary key
         * @param {ArticleSchema} articleSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateArticleArticlesArticleIdPut(articleId: any, articleSchema: ArticleSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateArticleArticlesArticleIdPut(articleId, articleSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates article Like mark
         * @param {any} articleId 
         * @param {any | null} [newLikeMark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLikeArticlesArticleIdLikePatch(articleId: any, newLikeMark?: any | null, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LikeResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLikeArticlesArticleIdLikePatch(articleId, newLikeMark, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload a PDF and extract text from it, then save it as an article.
         * @summary Upload a PDF and extract text from it.
         * @param {any} file The PDF file to upload
         * @param {any} title The title of the article
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPdfArticlesImportPdfPost(file: any, title: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ArticleResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPdfArticlesImportPdfPost(file, title, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ArticleApi - factory interface
 * @export
 */
export const ArticleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ArticleApiFp(configuration)
    return {
        /**
         * Creates a new article. **Required fields**: title, text.
         * @summary Creates a new article.
         * @param {ArticleSchema} article 
         * @param {any | null} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createArticleArticlesPost(article: ArticleSchema, image?: any | null, options?: any): AxiosPromise<ArticleResponseSchema> {
            return localVarFp.createArticleArticlesPost(article, image, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing article by its ID.
         * @summary Deletes an existing article by its ID.
         * @param {any} articleId Article primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteArticleArticlesArticleIdDelete(articleId: any, options?: any): AxiosPromise<Response200Schema> {
            return localVarFp.deleteArticleArticlesArticleIdDelete(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Export the text of an article as a PDF.
         * @summary Export article text as PDF.
         * @param {BodyExportArticleToPdfViewArticlesExportPdfPost} bodyExportArticleToPdfViewArticlesExportPdfPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportArticleToPdfViewArticlesExportPdfPost(bodyExportArticleToPdfViewArticlesExportPdfPost: BodyExportArticleToPdfViewArticlesExportPdfPost, options?: any): AxiosPromise<any> {
            return localVarFp.exportArticleToPdfViewArticlesExportPdfPost(bodyExportArticleToPdfViewArticlesExportPdfPost, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a specific article by its ID.
         * @summary Retrieves a specific article by its ID.
         * @param {any} articleId Article primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticleArticlesArticleIdGet(articleId: any, options?: any): AxiosPromise<ArticleResponseSchema> {
            return localVarFp.getArticleArticlesArticleIdGet(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of articles.
         * @summary Retrieves a list of articles.
         * @param {any | null} [isOriginal] Filter by originality
         * @param {any | null} [originalArticleId] Filter by original article ID
         * @param {any | null} [languageId] Filter by language ID
         * @param {any | null} [title] Search by title
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArticlesArticlesGet(isOriginal?: any | null, originalArticleId?: any | null, languageId?: any | null, title?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: any): AxiosPromise<ArticleListResponseSchema> {
            return localVarFp.getArticlesArticlesGet(isOriginal, originalArticleId, languageId, title, page, perPage, sortBy, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing article by its ID. **Required fields**: title, text.
         * @summary Updates an existing article by its ID.
         * @param {any} articleId Article primary key
         * @param {ArticleSchema} articleSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateArticleArticlesArticleIdPut(articleId: any, articleSchema: ArticleSchema, options?: any): AxiosPromise<ArticleResponseSchema> {
            return localVarFp.updateArticleArticlesArticleIdPut(articleId, articleSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates article Like mark
         * @param {any} articleId 
         * @param {any | null} [newLikeMark] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLikeArticlesArticleIdLikePatch(articleId: any, newLikeMark?: any | null, options?: any): AxiosPromise<LikeResponseSchema> {
            return localVarFp.updateLikeArticlesArticleIdLikePatch(articleId, newLikeMark, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a PDF and extract text from it, then save it as an article.
         * @summary Upload a PDF and extract text from it.
         * @param {any} file The PDF file to upload
         * @param {any} title The title of the article
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPdfArticlesImportPdfPost(file: any, title: any, options?: any): AxiosPromise<ArticleResponseSchema> {
            return localVarFp.uploadPdfArticlesImportPdfPost(file, title, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ArticleApi - object-oriented interface
 * @export
 * @class ArticleApi
 * @extends {BaseAPI}
 */
export class ArticleApi extends BaseAPI {
    /**
     * Creates a new article. **Required fields**: title, text.
     * @summary Creates a new article.
     * @param {ArticleSchema} article 
     * @param {any | null} [image] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public createArticleArticlesPost(article: ArticleSchema, image?: any | null, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).createArticleArticlesPost(article, image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing article by its ID.
     * @summary Deletes an existing article by its ID.
     * @param {any} articleId Article primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public deleteArticleArticlesArticleIdDelete(articleId: any, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).deleteArticleArticlesArticleIdDelete(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Export the text of an article as a PDF.
     * @summary Export article text as PDF.
     * @param {BodyExportArticleToPdfViewArticlesExportPdfPost} bodyExportArticleToPdfViewArticlesExportPdfPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public exportArticleToPdfViewArticlesExportPdfPost(bodyExportArticleToPdfViewArticlesExportPdfPost: BodyExportArticleToPdfViewArticlesExportPdfPost, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).exportArticleToPdfViewArticlesExportPdfPost(bodyExportArticleToPdfViewArticlesExportPdfPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a specific article by its ID.
     * @summary Retrieves a specific article by its ID.
     * @param {any} articleId Article primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getArticleArticlesArticleIdGet(articleId: any, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).getArticleArticlesArticleIdGet(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of articles.
     * @summary Retrieves a list of articles.
     * @param {any | null} [isOriginal] Filter by originality
     * @param {any | null} [originalArticleId] Filter by original article ID
     * @param {any | null} [languageId] Filter by language ID
     * @param {any | null} [title] Search by title
     * @param {any} [page] Page number (must be 1 or greater)
     * @param {any} [perPage] Number of items per page (must be 1 or greater)
     * @param {any | null} [sortBy] Sort by field
     * @param {any} [orderBy] Sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public getArticlesArticlesGet(isOriginal?: any | null, originalArticleId?: any | null, languageId?: any | null, title?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).getArticlesArticlesGet(isOriginal, originalArticleId, languageId, title, page, perPage, sortBy, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing article by its ID. **Required fields**: title, text.
     * @summary Updates an existing article by its ID.
     * @param {any} articleId Article primary key
     * @param {ArticleSchema} articleSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public updateArticleArticlesArticleIdPut(articleId: any, articleSchema: ArticleSchema, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).updateArticleArticlesArticleIdPut(articleId, articleSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates article Like mark
     * @param {any} articleId 
     * @param {any | null} [newLikeMark] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public updateLikeArticlesArticleIdLikePatch(articleId: any, newLikeMark?: any | null, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).updateLikeArticlesArticleIdLikePatch(articleId, newLikeMark, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a PDF and extract text from it, then save it as an article.
     * @summary Upload a PDF and extract text from it.
     * @param {any} file The PDF file to upload
     * @param {any} title The title of the article
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ArticleApi
     */
    public uploadPdfArticlesImportPdfPost(file: any, title: any, options?: AxiosRequestConfig) {
        return ArticleApiFp(this.configuration).uploadPdfArticlesImportPdfPost(file, title, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BalanceApi - axios parameter creator
 * @export
 */
export const BalanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get User Balance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBalanceBalanceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/balance/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalanceApi - functional programming interface
 * @export
 */
export const BalanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BalanceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get User Balance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserBalanceBalanceGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BalanceDataResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserBalanceBalanceGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BalanceApi - factory interface
 * @export
 */
export const BalanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BalanceApiFp(configuration)
    return {
        /**
         * 
         * @summary Get User Balance
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBalanceBalanceGet(options?: any): AxiosPromise<BalanceDataResponseSchema> {
            return localVarFp.getUserBalanceBalanceGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BalanceApi - object-oriented interface
 * @export
 * @class BalanceApi
 * @extends {BaseAPI}
 */
export class BalanceApi extends BaseAPI {
    /**
     * 
     * @summary Get User Balance
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public getUserBalanceBalanceGet(options?: AxiosRequestConfig) {
        return BalanceApiFp(this.configuration).getUserBalanceBalanceGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConfigApi - axios parameter creator
 * @export
 */
export const ConfigApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new config.
         * @summary Creates a new config.
         * @param {ConfigSchema} configSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConfigConfigsPost: async (configSchema: ConfigSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configSchema' is not null or undefined
            assertParamExists('createConfigConfigsPost', 'configSchema', configSchema)
            const localVarPath = `/configs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing config by its ID.
         * @summary Deletes an existing config by its ID.
         * @param {any} configId Config primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfigConfigsConfigIdDelete: async (configId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configId' is not null or undefined
            assertParamExists('deleteConfigConfigsConfigIdDelete', 'configId', configId)
            const localVarPath = `/configs/{config_id}/`
                .replace(`{${"config_id"}}`, encodeURIComponent(String(configId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a specific config by its ID.
         * @summary Retrieves a specific config by its ID.
         * @param {any} configId Config primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigConfigsConfigIdGet: async (configId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configId' is not null or undefined
            assertParamExists('getConfigConfigsConfigIdGet', 'configId', configId)
            const localVarPath = `/configs/{config_id}/`
                .replace(`{${"config_id"}}`, encodeURIComponent(String(configId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of configs.
         * @summary Retrieves a list of configs.
         * @param {any | null} [model] Filter by model name
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigsConfigsGet: async (model?: any | null, page?: any, perPage?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/configs/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (model !== undefined) {
                localVarQueryParameter['model'] = model;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing config by its ID.
         * @summary Updates an existing config by its ID.
         * @param {any} configId Config primary key
         * @param {ConfigSchema} configSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfigConfigsConfigIdPut: async (configId: any, configSchema: ConfigSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'configId' is not null or undefined
            assertParamExists('updateConfigConfigsConfigIdPut', 'configId', configId)
            // verify required parameter 'configSchema' is not null or undefined
            assertParamExists('updateConfigConfigsConfigIdPut', 'configSchema', configSchema)
            const localVarPath = `/configs/{config_id}/`
                .replace(`{${"config_id"}}`, encodeURIComponent(String(configId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(configSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigApi - functional programming interface
 * @export
 */
export const ConfigApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new config.
         * @summary Creates a new config.
         * @param {ConfigSchema} configSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConfigConfigsPost(configSchema: ConfigSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConfigConfigsPost(configSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing config by its ID.
         * @summary Deletes an existing config by its ID.
         * @param {any} configId Config primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConfigConfigsConfigIdDelete(configId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConfigConfigsConfigIdDelete(configId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a specific config by its ID.
         * @summary Retrieves a specific config by its ID.
         * @param {any} configId Config primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigConfigsConfigIdGet(configId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigConfigsConfigIdGet(configId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a list of configs.
         * @summary Retrieves a list of configs.
         * @param {any | null} [model] Filter by model name
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfigsConfigsGet(model?: any | null, page?: any, perPage?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigListResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfigsConfigsGet(model, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing config by its ID.
         * @summary Updates an existing config by its ID.
         * @param {any} configId Config primary key
         * @param {ConfigSchema} configSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConfigConfigsConfigIdPut(configId: any, configSchema: ConfigSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConfigResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConfigConfigsConfigIdPut(configId, configSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigApi - factory interface
 * @export
 */
export const ConfigApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigApiFp(configuration)
    return {
        /**
         * Creates a new config.
         * @summary Creates a new config.
         * @param {ConfigSchema} configSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConfigConfigsPost(configSchema: ConfigSchema, options?: any): AxiosPromise<ConfigResponseSchema> {
            return localVarFp.createConfigConfigsPost(configSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing config by its ID.
         * @summary Deletes an existing config by its ID.
         * @param {any} configId Config primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConfigConfigsConfigIdDelete(configId: any, options?: any): AxiosPromise<Response200Schema> {
            return localVarFp.deleteConfigConfigsConfigIdDelete(configId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a specific config by its ID.
         * @summary Retrieves a specific config by its ID.
         * @param {any} configId Config primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigConfigsConfigIdGet(configId: any, options?: any): AxiosPromise<ConfigResponseSchema> {
            return localVarFp.getConfigConfigsConfigIdGet(configId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of configs.
         * @summary Retrieves a list of configs.
         * @param {any | null} [model] Filter by model name
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfigsConfigsGet(model?: any | null, page?: any, perPage?: any, options?: any): AxiosPromise<ConfigListResponseSchema> {
            return localVarFp.getConfigsConfigsGet(model, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing config by its ID.
         * @summary Updates an existing config by its ID.
         * @param {any} configId Config primary key
         * @param {ConfigSchema} configSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConfigConfigsConfigIdPut(configId: any, configSchema: ConfigSchema, options?: any): AxiosPromise<ConfigResponseSchema> {
            return localVarFp.updateConfigConfigsConfigIdPut(configId, configSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigApi - object-oriented interface
 * @export
 * @class ConfigApi
 * @extends {BaseAPI}
 */
export class ConfigApi extends BaseAPI {
    /**
     * Creates a new config.
     * @summary Creates a new config.
     * @param {ConfigSchema} configSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public createConfigConfigsPost(configSchema: ConfigSchema, options?: AxiosRequestConfig) {
        return ConfigApiFp(this.configuration).createConfigConfigsPost(configSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing config by its ID.
     * @summary Deletes an existing config by its ID.
     * @param {any} configId Config primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public deleteConfigConfigsConfigIdDelete(configId: any, options?: AxiosRequestConfig) {
        return ConfigApiFp(this.configuration).deleteConfigConfigsConfigIdDelete(configId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a specific config by its ID.
     * @summary Retrieves a specific config by its ID.
     * @param {any} configId Config primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getConfigConfigsConfigIdGet(configId: any, options?: AxiosRequestConfig) {
        return ConfigApiFp(this.configuration).getConfigConfigsConfigIdGet(configId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of configs.
     * @summary Retrieves a list of configs.
     * @param {any | null} [model] Filter by model name
     * @param {any} [page] Page number (must be 1 or greater)
     * @param {any} [perPage] Number of items per page (must be 1 or greater)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public getConfigsConfigsGet(model?: any | null, page?: any, perPage?: any, options?: AxiosRequestConfig) {
        return ConfigApiFp(this.configuration).getConfigsConfigsGet(model, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing config by its ID.
     * @summary Updates an existing config by its ID.
     * @param {any} configId Config primary key
     * @param {ConfigSchema} configSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigApi
     */
    public updateConfigConfigsConfigIdPut(configId: any, configSchema: ConfigSchema, options?: AxiosRequestConfig) {
        return ConfigApiFp(this.configuration).updateConfigConfigsConfigIdPut(configId, configSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ItemsApi - axios parameter creator
 * @export
 */
export const ItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This endpoint creates a new item. Available item types     include: \'tokens_package\', \'subscription\', and others. If the item type     is \'tokens_package\', the `data` field must include `token_amount`.
         * @summary Creates a new item
         * @param {ItemCreateSchema} itemCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addItemItemsPost: async (itemCreateSchema: ItemCreateSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemCreateSchema' is not null or undefined
            assertParamExists('addItemItemsPost', 'itemCreateSchema', itemCreateSchema)
            const localVarPath = `/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates or updates an item price.
         * @summary Creates or updates an item price
         * @param {any} itemId 
         * @param {ItemPriceCreateSchema} itemPriceCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateItemPriceItemsItemIdPricePost: async (itemId: any, itemPriceCreateSchema: ItemPriceCreateSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('createOrUpdateItemPriceItemsItemIdPricePost', 'itemId', itemId)
            // verify required parameter 'itemPriceCreateSchema' is not null or undefined
            assertParamExists('createOrUpdateItemPriceItemsItemIdPricePost', 'itemPriceCreateSchema', itemPriceCreateSchema)
            const localVarPath = `/items/{item_id}/price/`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemPriceCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an existing item by its ID.
         * @param {any} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemItemsItemIdDelete: async (itemId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('deleteItemItemsItemIdDelete', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes an existing item price by its ID.
         * @param {any} priceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemPriceItemsPricesPriceIdDelete: async (priceId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'priceId' is not null or undefined
            assertParamExists('deleteItemPriceItemsPricesPriceIdDelete', 'priceId', priceId)
            const localVarPath = `/items/prices/{price_id}/`
                .replace(`{${"price_id"}}`, encodeURIComponent(String(priceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Item
         * @param {any} itemId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getItemItemsItemIdGet: async (itemId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('getItemItemsItemIdGet', 'itemId', itemId)
            const localVarPath = `/items/{item_id}/`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Item Price
         * @param {any} itemId 
         * @param {any} paymentMethodId 
         * @param {Currency} currency 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getItemPriceItemsItemIdPriceGet: async (itemId: any, paymentMethodId: any, currency: Currency, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('getItemPriceItemsItemIdPriceGet', 'itemId', itemId)
            // verify required parameter 'paymentMethodId' is not null or undefined
            assertParamExists('getItemPriceItemsItemIdPriceGet', 'paymentMethodId', paymentMethodId)
            // verify required parameter 'currency' is not null or undefined
            assertParamExists('getItemPriceItemsItemIdPriceGet', 'currency', currency)
            const localVarPath = `/items/{item_id}/price/`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (paymentMethodId !== undefined) {
                localVarQueryParameter['payment_method_id'] = paymentMethodId;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of item prices
         * @summary Retrieves a list of item prices
         * @param {any | null} [itemId] Filter by item ID
         * @param {any | null} [itemType] Filter by item type
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemPriceListItemsPricesGet: async (itemId?: any | null, itemType?: any | null, page?: any, perPage?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/prices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (itemId !== undefined) {
                localVarQueryParameter['item_id'] = itemId;
            }

            if (itemType !== undefined) {
                localVarQueryParameter['item_type'] = itemType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves a list of items
         * @param {any | null} [type] Filter by type
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsItemsGet: async (type?: any | null, page?: any, perPage?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates an existing item by its ID.
         * @param {any} itemId 
         * @param {ItemCreateSchema} itemCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemItemsItemIdPut: async (itemId: any, itemCreateSchema: ItemCreateSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemId' is not null or undefined
            assertParamExists('updateItemItemsItemIdPut', 'itemId', itemId)
            // verify required parameter 'itemCreateSchema' is not null or undefined
            assertParamExists('updateItemItemsItemIdPut', 'itemCreateSchema', itemCreateSchema)
            const localVarPath = `/items/{item_id}/`
                .replace(`{${"item_id"}}`, encodeURIComponent(String(itemId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(itemCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemsApi - functional programming interface
 * @export
 */
export const ItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * This endpoint creates a new item. Available item types     include: \'tokens_package\', \'subscription\', and others. If the item type     is \'tokens_package\', the `data` field must include `token_amount`.
         * @summary Creates a new item
         * @param {ItemCreateSchema} itemCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addItemItemsPost(itemCreateSchema: ItemCreateSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDataResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addItemItemsPost(itemCreateSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates or updates an item price.
         * @summary Creates or updates an item price
         * @param {any} itemId 
         * @param {ItemPriceCreateSchema} itemPriceCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateItemPriceItemsItemIdPricePost(itemId: any, itemPriceCreateSchema: ItemPriceCreateSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemPriceDataResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateItemPriceItemsItemIdPricePost(itemId, itemPriceCreateSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an existing item by its ID.
         * @param {any} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteItemItemsItemIdDelete(itemId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteItemItemsItemIdDelete(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes an existing item price by its ID.
         * @param {any} priceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteItemPriceItemsPricesPriceIdDelete(priceId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteItemPriceItemsPricesPriceIdDelete(priceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Item
         * @param {any} itemId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getItemItemsItemIdGet(itemId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDataResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemItemsItemIdGet(itemId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Item Price
         * @param {any} itemId 
         * @param {any} paymentMethodId 
         * @param {Currency} currency 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getItemPriceItemsItemIdPriceGet(itemId: any, paymentMethodId: any, currency: Currency, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemPriceDataResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemPriceItemsItemIdPriceGet(itemId, paymentMethodId, currency, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a list of item prices
         * @summary Retrieves a list of item prices
         * @param {any | null} [itemId] Filter by item ID
         * @param {any | null} [itemType] Filter by item type
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemPriceListItemsPricesGet(itemId?: any | null, itemType?: any | null, page?: any, perPage?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemPriceListDataResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemPriceListItemsPricesGet(itemId, itemType, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves a list of items
         * @param {any | null} [type] Filter by type
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemsItemsGet(type?: any | null, page?: any, perPage?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemListDataResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemsItemsGet(type, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates an existing item by its ID.
         * @param {any} itemId 
         * @param {ItemCreateSchema} itemCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItemItemsItemIdPut(itemId: any, itemCreateSchema: ItemCreateSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemDataResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateItemItemsItemIdPut(itemId, itemCreateSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ItemsApi - factory interface
 * @export
 */
export const ItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemsApiFp(configuration)
    return {
        /**
         * This endpoint creates a new item. Available item types     include: \'tokens_package\', \'subscription\', and others. If the item type     is \'tokens_package\', the `data` field must include `token_amount`.
         * @summary Creates a new item
         * @param {ItemCreateSchema} itemCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addItemItemsPost(itemCreateSchema: ItemCreateSchema, options?: any): AxiosPromise<ItemDataResponseSchemaInput> {
            return localVarFp.addItemItemsPost(itemCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates or updates an item price.
         * @summary Creates or updates an item price
         * @param {any} itemId 
         * @param {ItemPriceCreateSchema} itemPriceCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateItemPriceItemsItemIdPricePost(itemId: any, itemPriceCreateSchema: ItemPriceCreateSchema, options?: any): AxiosPromise<ItemPriceDataResponseSchemaInput> {
            return localVarFp.createOrUpdateItemPriceItemsItemIdPricePost(itemId, itemPriceCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an existing item by its ID.
         * @param {any} itemId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemItemsItemIdDelete(itemId: any, options?: any): AxiosPromise<Response200Schema> {
            return localVarFp.deleteItemItemsItemIdDelete(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes an existing item price by its ID.
         * @param {any} priceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItemPriceItemsPricesPriceIdDelete(priceId: any, options?: any): AxiosPromise<Response200Schema> {
            return localVarFp.deleteItemPriceItemsPricesPriceIdDelete(priceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Item
         * @param {any} itemId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getItemItemsItemIdGet(itemId: any, options?: any): AxiosPromise<ItemDataResponseSchemaInput> {
            return localVarFp.getItemItemsItemIdGet(itemId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Item Price
         * @param {any} itemId 
         * @param {any} paymentMethodId 
         * @param {Currency} currency 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getItemPriceItemsItemIdPriceGet(itemId: any, paymentMethodId: any, currency: Currency, options?: any): AxiosPromise<ItemPriceDataResponseSchemaInput> {
            return localVarFp.getItemPriceItemsItemIdPriceGet(itemId, paymentMethodId, currency, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of item prices
         * @summary Retrieves a list of item prices
         * @param {any | null} [itemId] Filter by item ID
         * @param {any | null} [itemType] Filter by item type
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemPriceListItemsPricesGet(itemId?: any | null, itemType?: any | null, page?: any, perPage?: any, options?: any): AxiosPromise<ItemPriceListDataResponseSchemaInput> {
            return localVarFp.getItemPriceListItemsPricesGet(itemId, itemType, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves a list of items
         * @param {any | null} [type] Filter by type
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsItemsGet(type?: any | null, page?: any, perPage?: any, options?: any): AxiosPromise<ItemListDataResponseSchemaInput> {
            return localVarFp.getItemsItemsGet(type, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates an existing item by its ID.
         * @param {any} itemId 
         * @param {ItemCreateSchema} itemCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemItemsItemIdPut(itemId: any, itemCreateSchema: ItemCreateSchema, options?: any): AxiosPromise<ItemDataResponseSchemaInput> {
            return localVarFp.updateItemItemsItemIdPut(itemId, itemCreateSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ItemsApi - object-oriented interface
 * @export
 * @class ItemsApi
 * @extends {BaseAPI}
 */
export class ItemsApi extends BaseAPI {
    /**
     * This endpoint creates a new item. Available item types     include: \'tokens_package\', \'subscription\', and others. If the item type     is \'tokens_package\', the `data` field must include `token_amount`.
     * @summary Creates a new item
     * @param {ItemCreateSchema} itemCreateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public addItemItemsPost(itemCreateSchema: ItemCreateSchema, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).addItemItemsPost(itemCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates or updates an item price.
     * @summary Creates or updates an item price
     * @param {any} itemId 
     * @param {ItemPriceCreateSchema} itemPriceCreateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public createOrUpdateItemPriceItemsItemIdPricePost(itemId: any, itemPriceCreateSchema: ItemPriceCreateSchema, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).createOrUpdateItemPriceItemsItemIdPricePost(itemId, itemPriceCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an existing item by its ID.
     * @param {any} itemId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteItemItemsItemIdDelete(itemId: any, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).deleteItemItemsItemIdDelete(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes an existing item price by its ID.
     * @param {any} priceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public deleteItemPriceItemsPricesPriceIdDelete(priceId: any, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).deleteItemPriceItemsPricesPriceIdDelete(priceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Item
     * @param {any} itemId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public getItemItemsItemIdGet(itemId: any, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).getItemItemsItemIdGet(itemId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Item Price
     * @param {any} itemId 
     * @param {any} paymentMethodId 
     * @param {Currency} currency 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public getItemPriceItemsItemIdPriceGet(itemId: any, paymentMethodId: any, currency: Currency, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).getItemPriceItemsItemIdPriceGet(itemId, paymentMethodId, currency, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of item prices
     * @summary Retrieves a list of item prices
     * @param {any | null} [itemId] Filter by item ID
     * @param {any | null} [itemType] Filter by item type
     * @param {any} [page] Page number (must be 1 or greater)
     * @param {any} [perPage] Number of items per page (must be 1 or greater)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public getItemPriceListItemsPricesGet(itemId?: any | null, itemType?: any | null, page?: any, perPage?: any, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).getItemPriceListItemsPricesGet(itemId, itemType, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves a list of items
     * @param {any | null} [type] Filter by type
     * @param {any} [page] Page number (must be 1 or greater)
     * @param {any} [perPage] Number of items per page (must be 1 or greater)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public getItemsItemsGet(type?: any | null, page?: any, perPage?: any, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).getItemsItemsGet(type, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates an existing item by its ID.
     * @param {any} itemId 
     * @param {ItemCreateSchema} itemCreateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemsApi
     */
    public updateItemItemsItemIdPut(itemId: any, itemCreateSchema: ItemCreateSchema, options?: AxiosRequestConfig) {
        return ItemsApiFp(this.configuration).updateItemItemsItemIdPut(itemId, itemCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LanguageApi - axios parameter creator
 * @export
 */
export const LanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get all active languages
         * @param {any | null} [name] Search by title
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguagesLanguagesGet: async (name?: any | null, page?: any, perPage?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/languages/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguageApi - functional programming interface
 * @export
 */
export const LanguageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LanguageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get all active languages
         * @param {any | null} [name] Search by title
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLanguagesLanguagesGet(name?: any | null, page?: any, perPage?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LanguageListResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLanguagesLanguagesGet(name, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LanguageApi - factory interface
 * @export
 */
export const LanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LanguageApiFp(configuration)
    return {
        /**
         * 
         * @summary Get all active languages
         * @param {any | null} [name] Search by title
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLanguagesLanguagesGet(name?: any | null, page?: any, perPage?: any, options?: any): AxiosPromise<LanguageListResponseSchema> {
            return localVarFp.getLanguagesLanguagesGet(name, page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LanguageApi - object-oriented interface
 * @export
 * @class LanguageApi
 * @extends {BaseAPI}
 */
export class LanguageApi extends BaseAPI {
    /**
     * 
     * @summary Get all active languages
     * @param {any | null} [name] Search by title
     * @param {any} [page] Page number (must be 1 or greater)
     * @param {any} [perPage] Number of items per page (must be 1 or greater)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public getLanguagesLanguagesGet(name?: any | null, page?: any, perPage?: any, options?: AxiosRequestConfig) {
        return LanguageApiFp(this.configuration).getLanguagesLanguagesGet(name, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes an existing notification by its ID.
         * @summary Deletes an existing notification by its ID.
         * @param {any} notificationId Notification primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotificationNotificationsNotificationIdDelete: async (notificationId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationId' is not null or undefined
            assertParamExists('deleteNotificationNotificationsNotificationIdDelete', 'notificationId', notificationId)
            const localVarPath = `/notifications/{notification_id}/`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notificationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Fetch all notifications for the authenticated user with pagination and filter (default sort by created_at desc).
         * @summary Fetch all notifications for the authenticated user with pagination and filter by is_read (default sort by created_at desc).
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsNotificationsGet: async (page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send notification **Required fields**: subject, message.
         * @summary Send notification
         * @param {NotificationSchema} notificationSchema 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        sendNotificationNotificationsPost: async (notificationSchema: NotificationSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationSchema' is not null or undefined
            assertParamExists('sendNotificationNotificationsPost', 'notificationSchema', notificationSchema)
            const localVarPath = `/notifications/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes an existing notification by its ID.
         * @summary Deletes an existing notification by its ID.
         * @param {any} notificationId Notification primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotificationNotificationsNotificationIdDelete(notificationId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotificationNotificationsNotificationIdDelete(notificationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Fetch all notifications for the authenticated user with pagination and filter (default sort by created_at desc).
         * @summary Fetch all notifications for the authenticated user with pagination and filter by is_read (default sort by created_at desc).
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationsNotificationsGet(page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationListResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationsNotificationsGet(page, perPage, sortBy, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send notification **Required fields**: subject, message.
         * @summary Send notification
         * @param {NotificationSchema} notificationSchema 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async sendNotificationNotificationsPost(notificationSchema: NotificationSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueDataResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotificationNotificationsPost(notificationSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * Deletes an existing notification by its ID.
         * @summary Deletes an existing notification by its ID.
         * @param {any} notificationId Notification primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotificationNotificationsNotificationIdDelete(notificationId: any, options?: any): AxiosPromise<Response200Schema> {
            return localVarFp.deleteNotificationNotificationsNotificationIdDelete(notificationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Fetch all notifications for the authenticated user with pagination and filter (default sort by created_at desc).
         * @summary Fetch all notifications for the authenticated user with pagination and filter by is_read (default sort by created_at desc).
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsNotificationsGet(page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: any): AxiosPromise<NotificationListResponseSchema> {
            return localVarFp.getNotificationsNotificationsGet(page, perPage, sortBy, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Send notification **Required fields**: subject, message.
         * @summary Send notification
         * @param {NotificationSchema} notificationSchema 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        sendNotificationNotificationsPost(notificationSchema: NotificationSchema, options?: any): AxiosPromise<QueueDataResponseSchema> {
            return localVarFp.sendNotificationNotificationsPost(notificationSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * Deletes an existing notification by its ID.
     * @summary Deletes an existing notification by its ID.
     * @param {any} notificationId Notification primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public deleteNotificationNotificationsNotificationIdDelete(notificationId: any, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).deleteNotificationNotificationsNotificationIdDelete(notificationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Fetch all notifications for the authenticated user with pagination and filter (default sort by created_at desc).
     * @summary Fetch all notifications for the authenticated user with pagination and filter by is_read (default sort by created_at desc).
     * @param {any} [page] Page number (must be 1 or greater)
     * @param {any} [perPage] Number of items per page (must be 1 or greater)
     * @param {any | null} [sortBy] Sort by field
     * @param {any} [orderBy] Sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getNotificationsNotificationsGet(page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getNotificationsNotificationsGet(page, perPage, sortBy, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send notification **Required fields**: subject, message.
     * @summary Send notification
     * @param {NotificationSchema} notificationSchema 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public sendNotificationNotificationsPost(notificationSchema: NotificationSchema, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).sendNotificationNotificationsPost(notificationSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OrdersApi - axios parameter creator
 * @export
 */
export const OrdersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Calculate Price
         * @param {OrderCreateSchema} orderCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePriceOrdersCalculatePricePost: async (orderCreateSchema: OrderCreateSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCreateSchema' is not null or undefined
            assertParamExists('calculatePriceOrdersCalculatePricePost', 'orderCreateSchema', orderCreateSchema)
            const localVarPath = `/orders/calculate-price/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Confirm Order
         * @param {BodyConfirmOrderOrdersConfirmPost} bodyConfirmOrderOrdersConfirmPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmOrderOrdersConfirmPost: async (bodyConfirmOrderOrdersConfirmPost: BodyConfirmOrderOrdersConfirmPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyConfirmOrderOrdersConfirmPost' is not null or undefined
            assertParamExists('confirmOrderOrdersConfirmPost', 'bodyConfirmOrderOrdersConfirmPost', bodyConfirmOrderOrdersConfirmPost)
            const localVarPath = `/orders/confirm/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyConfirmOrderOrdersConfirmPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create Order
         * @param {OrderCreateSchema} orderCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderOrdersPost: async (orderCreateSchema: OrderCreateSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderCreateSchema' is not null or undefined
            assertParamExists('createOrderOrdersPost', 'orderCreateSchema', orderCreateSchema)
            const localVarPath = `/orders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Order List
         * @param {any | null} [orderStatus] Filter by type
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderListOrdersGet: async (orderStatus?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/orders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orderStatus !== undefined) {
                localVarQueryParameter['order_status'] = orderStatus;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrdersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Calculate Price
         * @param {OrderCreateSchema} orderCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculatePriceOrdersCalculatePricePost(orderCreateSchema: OrderCreateSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CalculatedPriceDataResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculatePriceOrdersCalculatePricePost(orderCreateSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Confirm Order
         * @param {BodyConfirmOrderOrdersConfirmPost} bodyConfirmOrderOrdersConfirmPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async confirmOrderOrdersConfirmPost(bodyConfirmOrderOrdersConfirmPost: BodyConfirmOrderOrdersConfirmPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.confirmOrderOrdersConfirmPost(bodyConfirmOrderOrdersConfirmPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create Order
         * @param {OrderCreateSchema} orderCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrderOrdersPost(orderCreateSchema: OrderCreateSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppsApiOrderSchemasOrderListTotalPriceDataSchema2>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrderOrdersPost(orderCreateSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Order List
         * @param {any | null} [orderStatus] Filter by type
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderListOrdersGet(orderStatus?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderListDataResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderListOrdersGet(orderStatus, page, perPage, sortBy, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrdersApiFp(configuration)
    return {
        /**
         * 
         * @summary Calculate Price
         * @param {OrderCreateSchema} orderCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculatePriceOrdersCalculatePricePost(orderCreateSchema: OrderCreateSchema, options?: any): AxiosPromise<CalculatedPriceDataResponseSchema> {
            return localVarFp.calculatePriceOrdersCalculatePricePost(orderCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Confirm Order
         * @param {BodyConfirmOrderOrdersConfirmPost} bodyConfirmOrderOrdersConfirmPost 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        confirmOrderOrdersConfirmPost(bodyConfirmOrderOrdersConfirmPost: BodyConfirmOrderOrdersConfirmPost, options?: any): AxiosPromise<Response200Schema> {
            return localVarFp.confirmOrderOrdersConfirmPost(bodyConfirmOrderOrdersConfirmPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create Order
         * @param {OrderCreateSchema} orderCreateSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderOrdersPost(orderCreateSchema: OrderCreateSchema, options?: any): AxiosPromise<AppsApiOrderSchemasOrderListTotalPriceDataSchema2> {
            return localVarFp.createOrderOrdersPost(orderCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Order List
         * @param {any | null} [orderStatus] Filter by type
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderListOrdersGet(orderStatus?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: any): AxiosPromise<OrderListDataResponseSchema> {
            return localVarFp.orderListOrdersGet(orderStatus, page, perPage, sortBy, orderBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * 
     * @summary Calculate Price
     * @param {OrderCreateSchema} orderCreateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public calculatePriceOrdersCalculatePricePost(orderCreateSchema: OrderCreateSchema, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).calculatePriceOrdersCalculatePricePost(orderCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Confirm Order
     * @param {BodyConfirmOrderOrdersConfirmPost} bodyConfirmOrderOrdersConfirmPost 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public confirmOrderOrdersConfirmPost(bodyConfirmOrderOrdersConfirmPost: BodyConfirmOrderOrdersConfirmPost, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).confirmOrderOrdersConfirmPost(bodyConfirmOrderOrdersConfirmPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create Order
     * @param {OrderCreateSchema} orderCreateSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public createOrderOrdersPost(orderCreateSchema: OrderCreateSchema, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).createOrderOrdersPost(orderCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Order List
     * @param {any | null} [orderStatus] Filter by type
     * @param {any} [page] Page number (must be 1 or greater)
     * @param {any} [perPage] Number of items per page (must be 1 or greater)
     * @param {any | null} [sortBy] Sort by field
     * @param {any} [orderBy] Sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public orderListOrdersGet(orderStatus?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: AxiosRequestConfig) {
        return OrdersApiFp(this.configuration).orderListOrdersGet(orderStatus, page, perPage, sortBy, orderBy, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PaymentMethodsApi - axios parameter creator
 * @export
 */
export const PaymentMethodsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Payment Method
         * @param {PaymentMethodCreateSchema} paymentMethodCreateSchema 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createPaymentMethodPaymentMethodsPost: async (paymentMethodCreateSchema: PaymentMethodCreateSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentMethodCreateSchema' is not null or undefined
            assertParamExists('createPaymentMethodPaymentMethodsPost', 'paymentMethodCreateSchema', paymentMethodCreateSchema)
            const localVarPath = `/payment-methods/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentMethodCreateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Payment Method
         * @param {any} paymentMethodId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaymentMethodPaymentMethodsPaymentMethodIdGet: async (paymentMethodId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentMethodId' is not null or undefined
            assertParamExists('getPaymentMethodPaymentMethodsPaymentMethodIdGet', 'paymentMethodId', paymentMethodId)
            const localVarPath = `/payment-methods/{payment_method_id}/`
                .replace(`{${"payment_method_id"}}`, encodeURIComponent(String(paymentMethodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Payment Methods List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodsListPaymentMethodsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/payment-methods/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Payment Method
         * @param {any} paymentMethodId 
         * @param {PaymentMethodUpdateSchema} paymentMethodUpdateSchema 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updatePaymentMethodPaymentMethodsPaymentMethodIdPut: async (paymentMethodId: any, paymentMethodUpdateSchema: PaymentMethodUpdateSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentMethodId' is not null or undefined
            assertParamExists('updatePaymentMethodPaymentMethodsPaymentMethodIdPut', 'paymentMethodId', paymentMethodId)
            // verify required parameter 'paymentMethodUpdateSchema' is not null or undefined
            assertParamExists('updatePaymentMethodPaymentMethodsPaymentMethodIdPut', 'paymentMethodUpdateSchema', paymentMethodUpdateSchema)
            const localVarPath = `/payment-methods/{payment_method_id}/`
                .replace(`{${"payment_method_id"}}`, encodeURIComponent(String(paymentMethodId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentMethodUpdateSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentMethodsApi - functional programming interface
 * @export
 */
export const PaymentMethodsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentMethodsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Payment Method
         * @param {PaymentMethodCreateSchema} paymentMethodCreateSchema 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createPaymentMethodPaymentMethodsPost(paymentMethodCreateSchema: PaymentMethodCreateSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodDataResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPaymentMethodPaymentMethodsPost(paymentMethodCreateSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Payment Method
         * @param {any} paymentMethodId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getPaymentMethodPaymentMethodsPaymentMethodIdGet(paymentMethodId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodDataResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentMethodPaymentMethodsPaymentMethodIdGet(paymentMethodId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Payment Methods List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPaymentMethodsListPaymentMethodsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodListDataResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPaymentMethodsListPaymentMethodsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Payment Method
         * @param {any} paymentMethodId 
         * @param {PaymentMethodUpdateSchema} paymentMethodUpdateSchema 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updatePaymentMethodPaymentMethodsPaymentMethodIdPut(paymentMethodId: any, paymentMethodUpdateSchema: PaymentMethodUpdateSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaymentMethodDataResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePaymentMethodPaymentMethodsPaymentMethodIdPut(paymentMethodId, paymentMethodUpdateSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PaymentMethodsApi - factory interface
 * @export
 */
export const PaymentMethodsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentMethodsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Payment Method
         * @param {PaymentMethodCreateSchema} paymentMethodCreateSchema 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createPaymentMethodPaymentMethodsPost(paymentMethodCreateSchema: PaymentMethodCreateSchema, options?: any): AxiosPromise<PaymentMethodDataResponseSchema> {
            return localVarFp.createPaymentMethodPaymentMethodsPost(paymentMethodCreateSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Payment Method
         * @param {any} paymentMethodId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPaymentMethodPaymentMethodsPaymentMethodIdGet(paymentMethodId: any, options?: any): AxiosPromise<PaymentMethodDataResponseSchema> {
            return localVarFp.getPaymentMethodPaymentMethodsPaymentMethodIdGet(paymentMethodId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Payment Methods List
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentMethodsListPaymentMethodsGet(options?: any): AxiosPromise<PaymentMethodListDataResponseSchema> {
            return localVarFp.getPaymentMethodsListPaymentMethodsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Payment Method
         * @param {any} paymentMethodId 
         * @param {PaymentMethodUpdateSchema} paymentMethodUpdateSchema 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updatePaymentMethodPaymentMethodsPaymentMethodIdPut(paymentMethodId: any, paymentMethodUpdateSchema: PaymentMethodUpdateSchema, options?: any): AxiosPromise<PaymentMethodDataResponseSchema> {
            return localVarFp.updatePaymentMethodPaymentMethodsPaymentMethodIdPut(paymentMethodId, paymentMethodUpdateSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentMethodsApi - object-oriented interface
 * @export
 * @class PaymentMethodsApi
 * @extends {BaseAPI}
 */
export class PaymentMethodsApi extends BaseAPI {
    /**
     * 
     * @summary Create Payment Method
     * @param {PaymentMethodCreateSchema} paymentMethodCreateSchema 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public createPaymentMethodPaymentMethodsPost(paymentMethodCreateSchema: PaymentMethodCreateSchema, options?: AxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).createPaymentMethodPaymentMethodsPost(paymentMethodCreateSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Payment Method
     * @param {any} paymentMethodId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public getPaymentMethodPaymentMethodsPaymentMethodIdGet(paymentMethodId: any, options?: AxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).getPaymentMethodPaymentMethodsPaymentMethodIdGet(paymentMethodId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Payment Methods List
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public getPaymentMethodsListPaymentMethodsGet(options?: AxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).getPaymentMethodsListPaymentMethodsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Payment Method
     * @param {any} paymentMethodId 
     * @param {PaymentMethodUpdateSchema} paymentMethodUpdateSchema 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PaymentMethodsApi
     */
    public updatePaymentMethodPaymentMethodsPaymentMethodIdPut(paymentMethodId: any, paymentMethodUpdateSchema: PaymentMethodUpdateSchema, options?: AxiosRequestConfig) {
        return PaymentMethodsApiFp(this.configuration).updatePaymentMethodPaymentMethodsPaymentMethodIdPut(paymentMethodId, paymentMethodUpdateSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PromptApi - axios parameter creator
 * @export
 */
export const PromptApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new prompt.
         * @summary Creates a new prompt.
         * @param {PromptSchema} promptSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPromptPromptsPost: async (promptSchema: PromptSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promptSchema' is not null or undefined
            assertParamExists('createPromptPromptsPost', 'promptSchema', promptSchema)
            const localVarPath = `/prompts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promptSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an existing prompt by its ID.
         * @summary Deletes an existing prompt by its ID.
         * @param {any} promptId Prompt primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromptPromptsPromptIdDelete: async (promptId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promptId' is not null or undefined
            assertParamExists('deletePromptPromptsPromptIdDelete', 'promptId', promptId)
            const localVarPath = `/prompts/{prompt_id}/`
                .replace(`{${"prompt_id"}}`, encodeURIComponent(String(promptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a specific prompt by its ID.
         * @summary Retrieves a specific prompt by its ID.
         * @param {any} promptId Prompt primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromptPromptsPromptIdGet: async (promptId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promptId' is not null or undefined
            assertParamExists('getPromptPromptsPromptIdGet', 'promptId', promptId)
            const localVarPath = `/prompts/{prompt_id}/`
                .replace(`{${"prompt_id"}}`, encodeURIComponent(String(promptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of prompts.
         * @summary Retrieves a list of prompts.
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromptsPromptsGet: async (page?: any, perPage?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/prompts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates an existing prompt by its ID.
         * @summary Updates an existing prompt by its ID.
         * @param {any} promptId Prompt primary key
         * @param {PromptSchema} promptSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePromptPromptsPromptIdPut: async (promptId: any, promptSchema: PromptSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promptId' is not null or undefined
            assertParamExists('updatePromptPromptsPromptIdPut', 'promptId', promptId)
            // verify required parameter 'promptSchema' is not null or undefined
            assertParamExists('updatePromptPromptsPromptIdPut', 'promptSchema', promptSchema)
            const localVarPath = `/prompts/{prompt_id}/`
                .replace(`{${"prompt_id"}}`, encodeURIComponent(String(promptId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promptSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PromptApi - functional programming interface
 * @export
 */
export const PromptApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PromptApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new prompt.
         * @summary Creates a new prompt.
         * @param {PromptSchema} promptSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPromptPromptsPost(promptSchema: PromptSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromptResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPromptPromptsPost(promptSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an existing prompt by its ID.
         * @summary Deletes an existing prompt by its ID.
         * @param {any} promptId Prompt primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePromptPromptsPromptIdDelete(promptId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePromptPromptsPromptIdDelete(promptId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a specific prompt by its ID.
         * @summary Retrieves a specific prompt by its ID.
         * @param {any} promptId Prompt primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromptPromptsPromptIdGet(promptId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromptResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromptPromptsPromptIdGet(promptId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a list of prompts.
         * @summary Retrieves a list of prompts.
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPromptsPromptsGet(page?: any, perPage?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromptListResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPromptsPromptsGet(page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates an existing prompt by its ID.
         * @summary Updates an existing prompt by its ID.
         * @param {any} promptId Prompt primary key
         * @param {PromptSchema} promptSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePromptPromptsPromptIdPut(promptId: any, promptSchema: PromptSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromptResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePromptPromptsPromptIdPut(promptId, promptSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PromptApi - factory interface
 * @export
 */
export const PromptApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PromptApiFp(configuration)
    return {
        /**
         * Creates a new prompt.
         * @summary Creates a new prompt.
         * @param {PromptSchema} promptSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPromptPromptsPost(promptSchema: PromptSchema, options?: any): AxiosPromise<PromptResponseSchema> {
            return localVarFp.createPromptPromptsPost(promptSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an existing prompt by its ID.
         * @summary Deletes an existing prompt by its ID.
         * @param {any} promptId Prompt primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePromptPromptsPromptIdDelete(promptId: any, options?: any): AxiosPromise<Response200Schema> {
            return localVarFp.deletePromptPromptsPromptIdDelete(promptId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a specific prompt by its ID.
         * @summary Retrieves a specific prompt by its ID.
         * @param {any} promptId Prompt primary key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromptPromptsPromptIdGet(promptId: any, options?: any): AxiosPromise<PromptResponseSchema> {
            return localVarFp.getPromptPromptsPromptIdGet(promptId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of prompts.
         * @summary Retrieves a list of prompts.
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPromptsPromptsGet(page?: any, perPage?: any, options?: any): AxiosPromise<PromptListResponseSchema> {
            return localVarFp.getPromptsPromptsGet(page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates an existing prompt by its ID.
         * @summary Updates an existing prompt by its ID.
         * @param {any} promptId Prompt primary key
         * @param {PromptSchema} promptSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePromptPromptsPromptIdPut(promptId: any, promptSchema: PromptSchema, options?: any): AxiosPromise<PromptResponseSchema> {
            return localVarFp.updatePromptPromptsPromptIdPut(promptId, promptSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PromptApi - object-oriented interface
 * @export
 * @class PromptApi
 * @extends {BaseAPI}
 */
export class PromptApi extends BaseAPI {
    /**
     * Creates a new prompt.
     * @summary Creates a new prompt.
     * @param {PromptSchema} promptSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptApi
     */
    public createPromptPromptsPost(promptSchema: PromptSchema, options?: AxiosRequestConfig) {
        return PromptApiFp(this.configuration).createPromptPromptsPost(promptSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an existing prompt by its ID.
     * @summary Deletes an existing prompt by its ID.
     * @param {any} promptId Prompt primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptApi
     */
    public deletePromptPromptsPromptIdDelete(promptId: any, options?: AxiosRequestConfig) {
        return PromptApiFp(this.configuration).deletePromptPromptsPromptIdDelete(promptId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a specific prompt by its ID.
     * @summary Retrieves a specific prompt by its ID.
     * @param {any} promptId Prompt primary key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptApi
     */
    public getPromptPromptsPromptIdGet(promptId: any, options?: AxiosRequestConfig) {
        return PromptApiFp(this.configuration).getPromptPromptsPromptIdGet(promptId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of prompts.
     * @summary Retrieves a list of prompts.
     * @param {any} [page] Page number (must be 1 or greater)
     * @param {any} [perPage] Number of items per page (must be 1 or greater)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptApi
     */
    public getPromptsPromptsGet(page?: any, perPage?: any, options?: AxiosRequestConfig) {
        return PromptApiFp(this.configuration).getPromptsPromptsGet(page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates an existing prompt by its ID.
     * @summary Updates an existing prompt by its ID.
     * @param {any} promptId Prompt primary key
     * @param {PromptSchema} promptSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PromptApi
     */
    public updatePromptPromptsPromptIdPut(promptId: any, promptSchema: PromptSchema, options?: AxiosRequestConfig) {
        return PromptApiFp(this.configuration).updatePromptPromptsPromptIdPut(promptId, promptSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PurchaseApi - axios parameter creator
 * @export
 */
export const PurchaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Callback
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackPurchaseCallbackPost: async (body: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('callbackPurchaseCallbackPost', 'body', body)
            const localVarPath = `/purchase/callback/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Purchase Item
         * @param {PurchaseItemSchema} purchaseItemSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemPurchasePost: async (purchaseItemSchema: PurchaseItemSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'purchaseItemSchema' is not null or undefined
            assertParamExists('purchaseItemPurchasePost', 'purchaseItemSchema', purchaseItemSchema)
            const localVarPath = `/purchase/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(purchaseItemSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Verify Purhase
         * @param {BodyVerifyPurhasePurchaseVerifyPost} bodyVerifyPurhasePurchaseVerifyPost 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        verifyPurhasePurchaseVerifyPost: async (bodyVerifyPurhasePurchaseVerifyPost: BodyVerifyPurhasePurchaseVerifyPost, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bodyVerifyPurhasePurchaseVerifyPost' is not null or undefined
            assertParamExists('verifyPurhasePurchaseVerifyPost', 'bodyVerifyPurhasePurchaseVerifyPost', bodyVerifyPurhasePurchaseVerifyPost)
            const localVarPath = `/purchase/verify/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyVerifyPurhasePurchaseVerifyPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PurchaseApi - functional programming interface
 * @export
 */
export const PurchaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PurchaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Callback
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async callbackPurchaseCallbackPost(body: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.callbackPurchaseCallbackPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Purchase Item
         * @param {PurchaseItemSchema} purchaseItemSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async purchaseItemPurchasePost(purchaseItemSchema: PurchaseItemSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PurchasePaymnetLinkDataResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.purchaseItemPurchasePost(purchaseItemSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Verify Purhase
         * @param {BodyVerifyPurhasePurchaseVerifyPost} bodyVerifyPurhasePurchaseVerifyPost 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async verifyPurhasePurchaseVerifyPost(bodyVerifyPurhasePurchaseVerifyPost: BodyVerifyPurhasePurchaseVerifyPost, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verifyPurhasePurchaseVerifyPost(bodyVerifyPurhasePurchaseVerifyPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PurchaseApi - factory interface
 * @export
 */
export const PurchaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PurchaseApiFp(configuration)
    return {
        /**
         * 
         * @summary Callback
         * @param {any} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callbackPurchaseCallbackPost(body: any, options?: any): AxiosPromise<Response200Schema> {
            return localVarFp.callbackPurchaseCallbackPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Purchase Item
         * @param {PurchaseItemSchema} purchaseItemSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        purchaseItemPurchasePost(purchaseItemSchema: PurchaseItemSchema, options?: any): AxiosPromise<PurchasePaymnetLinkDataResponseSchema> {
            return localVarFp.purchaseItemPurchasePost(purchaseItemSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Verify Purhase
         * @param {BodyVerifyPurhasePurchaseVerifyPost} bodyVerifyPurhasePurchaseVerifyPost 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        verifyPurhasePurchaseVerifyPost(bodyVerifyPurhasePurchaseVerifyPost: BodyVerifyPurhasePurchaseVerifyPost, options?: any): AxiosPromise<Response200Schema> {
            return localVarFp.verifyPurhasePurchaseVerifyPost(bodyVerifyPurhasePurchaseVerifyPost, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PurchaseApi - object-oriented interface
 * @export
 * @class PurchaseApi
 * @extends {BaseAPI}
 */
export class PurchaseApi extends BaseAPI {
    /**
     * 
     * @summary Callback
     * @param {any} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    public callbackPurchaseCallbackPost(body: any, options?: AxiosRequestConfig) {
        return PurchaseApiFp(this.configuration).callbackPurchaseCallbackPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Purchase Item
     * @param {PurchaseItemSchema} purchaseItemSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    public purchaseItemPurchasePost(purchaseItemSchema: PurchaseItemSchema, options?: AxiosRequestConfig) {
        return PurchaseApiFp(this.configuration).purchaseItemPurchasePost(purchaseItemSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Verify Purhase
     * @param {BodyVerifyPurhasePurchaseVerifyPost} bodyVerifyPurhasePurchaseVerifyPost 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PurchaseApi
     */
    public verifyPurhasePurchaseVerifyPost(bodyVerifyPurhasePurchaseVerifyPost: BodyVerifyPurhasePurchaseVerifyPost, options?: AxiosRequestConfig) {
        return PurchaseApiFp(this.configuration).verifyPurhasePurchaseVerifyPost(bodyVerifyPurhasePurchaseVerifyPost, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Closes open report
         * @param {any} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeReportReportsReportIdClosePost: async (reportId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('closeReportReportsReportIdClosePost', 'reportId', reportId)
            const localVarPath = `/reports/{report_id}/close/`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * **Required fields**: text
         * @summary Creates comment
         * @param {any} reportId 
         * @param {CreateCommentSchema} createCommentSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentReportsReportIdCommentsPost: async (reportId: any, createCommentSchema: CreateCommentSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('createCommentReportsReportIdCommentsPost', 'reportId', reportId)
            // verify required parameter 'createCommentSchema' is not null or undefined
            assertParamExists('createCommentReportsReportIdCommentsPost', 'createCommentSchema', createCommentSchema)
            const localVarPath = `/reports/{report_id}/comments/`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createCommentSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Creates open report and returns created object
         * @param {CreateReportSchema} createReportSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportReportsPost: async (createReportSchema: CreateReportSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createReportSchema' is not null or undefined
            assertParamExists('createReportReportsPost', 'createReportSchema', createReportSchema)
            const localVarPath = `/reports/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createReportSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Deletes comment
         * @param {any} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentReportsCommentsCommentIdDelete: async (commentId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteCommentReportsCommentsCommentIdDelete', 'commentId', commentId)
            const localVarPath = `/reports/comments/{comment_id}/`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns list of all reports with filters and sorting applied
         * @param {any | null} [status] OPEN &#x3D; 1, CLOSED &#x3D; 2, REFUND &#x3D; 3
         * @param {any | null} [articleId] 
         * @param {any | null} [reasonId] 
         * @param {any | null} [closedByUserId] 
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReportsReportsGet: async (status?: any | null, articleId?: any | null, reasonId?: any | null, closedByUserId?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (articleId !== undefined) {
                localVarQueryParameter['article_id'] = articleId;
            }

            if (reasonId !== undefined) {
                localVarQueryParameter['reason_id'] = reasonId;
            }

            if (closedByUserId !== undefined) {
                localVarQueryParameter['closed_by_user_id'] = closedByUserId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns the list of comments
         * @param {any} reportId 
         * @param {any | null} [senderId] 
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsReportsReportIdCommentsGet: async (reportId: any, senderId?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getCommentsReportsReportIdCommentsGet', 'reportId', reportId)
            const localVarPath = `/reports/{report_id}/comments/`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (senderId !== undefined) {
                localVarQueryParameter['sender_id'] = senderId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns report by article id
         * @param {any} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyReportsReportsArticleArticleIdGet: async (articleId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'articleId' is not null or undefined
            assertParamExists('getMyReportsReportsArticleArticleIdGet', 'articleId', articleId)
            const localVarPath = `/reports/article/{article_id}/`
                .replace(`{${"article_id"}}`, encodeURIComponent(String(articleId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Returns report by its id
         * @param {any} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportReportsReportIdGet: async (reportId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportReportsReportIdGet', 'reportId', reportId)
            const localVarPath = `/reports/{report_id}/`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Refunds open report
         * @param {any} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundTranslationReportsReportIdRefundPost: async (reportId: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('refundTranslationReportsReportIdRefundPost', 'reportId', reportId)
            const localVarPath = `/reports/{report_id}/refund/`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates comment text
         * @param {any} commentId 
         * @param {UpdateCommentSchema} updateCommentSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommentReportsCommentsCommentIdPut: async (commentId: any, updateCommentSchema: UpdateCommentSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('updateCommentReportsCommentsCommentIdPut', 'commentId', commentId)
            // verify required parameter 'updateCommentSchema' is not null or undefined
            assertParamExists('updateCommentReportsCommentsCommentIdPut', 'updateCommentSchema', updateCommentSchema)
            const localVarPath = `/reports/comments/{comment_id}/`
                .replace(`{${"comment_id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommentSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Updates report\'s text and returns updated object
         * @param {any} reportId 
         * @param {any} text 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateReportReportsReportIdPut: async (reportId: any, text: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('updateReportReportsReportIdPut', 'reportId', reportId)
            // verify required parameter 'text' is not null or undefined
            assertParamExists('updateReportReportsReportIdPut', 'text', text)
            const localVarPath = `/reports/{report_id}/`
                .replace(`{${"report_id"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (text !== undefined) { 
                localVarFormParams.set('text', text as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Closes open report
         * @param {any} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeReportReportsReportIdClosePost(reportId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeReportReportsReportIdClosePost(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * **Required fields**: text
         * @summary Creates comment
         * @param {any} reportId 
         * @param {CreateCommentSchema} createCommentSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCommentReportsReportIdCommentsPost(reportId: any, createCommentSchema: CreateCommentSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentReportsReportIdCommentsPost(reportId, createCommentSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Creates open report and returns created object
         * @param {CreateReportSchema} createReportSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReportReportsPost(createReportSchema: CreateReportSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReportReportsPost(createReportSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Deletes comment
         * @param {any} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCommentReportsCommentsCommentIdDelete(commentId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCommentReportsCommentsCommentIdDelete(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns list of all reports with filters and sorting applied
         * @param {any | null} [status] OPEN &#x3D; 1, CLOSED &#x3D; 2, REFUND &#x3D; 3
         * @param {any | null} [articleId] 
         * @param {any | null} [reasonId] 
         * @param {any | null} [closedByUserId] 
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllReportsReportsGet(status?: any | null, articleId?: any | null, reasonId?: any | null, closedByUserId?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportListResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllReportsReportsGet(status, articleId, reasonId, closedByUserId, page, perPage, sortBy, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns the list of comments
         * @param {any} reportId 
         * @param {any | null} [senderId] 
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsReportsReportIdCommentsGet(reportId: any, senderId?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentListResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsReportsReportIdCommentsGet(reportId, senderId, page, perPage, sortBy, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns report by article id
         * @param {any} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyReportsReportsArticleArticleIdGet(articleId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportListResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyReportsReportsArticleArticleIdGet(articleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Returns report by its id
         * @param {any} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportReportsReportIdGet(reportId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportReportsReportIdGet(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Refunds open report
         * @param {any} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refundTranslationReportsReportIdRefundPost(reportId: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response200Schema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refundTranslationReportsReportIdRefundPost(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates comment text
         * @param {any} commentId 
         * @param {UpdateCommentSchema} updateCommentSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCommentReportsCommentsCommentIdPut(commentId: any, updateCommentSchema: UpdateCommentSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CommentResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCommentReportsCommentsCommentIdPut(commentId, updateCommentSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Updates report\'s text and returns updated object
         * @param {any} reportId 
         * @param {any} text 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateReportReportsReportIdPut(reportId: any, text: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReportResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateReportReportsReportIdPut(reportId, text, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 
         * @summary Closes open report
         * @param {any} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeReportReportsReportIdClosePost(reportId: any, options?: any): AxiosPromise<Response200Schema> {
            return localVarFp.closeReportReportsReportIdClosePost(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * **Required fields**: text
         * @summary Creates comment
         * @param {any} reportId 
         * @param {CreateCommentSchema} createCommentSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCommentReportsReportIdCommentsPost(reportId: any, createCommentSchema: CreateCommentSchema, options?: any): AxiosPromise<CommentResponseSchemaInput> {
            return localVarFp.createCommentReportsReportIdCommentsPost(reportId, createCommentSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Creates open report and returns created object
         * @param {CreateReportSchema} createReportSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReportReportsPost(createReportSchema: CreateReportSchema, options?: any): AxiosPromise<ReportResponseSchemaInput> {
            return localVarFp.createReportReportsPost(createReportSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Deletes comment
         * @param {any} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCommentReportsCommentsCommentIdDelete(commentId: any, options?: any): AxiosPromise<Response200Schema> {
            return localVarFp.deleteCommentReportsCommentsCommentIdDelete(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns list of all reports with filters and sorting applied
         * @param {any | null} [status] OPEN &#x3D; 1, CLOSED &#x3D; 2, REFUND &#x3D; 3
         * @param {any | null} [articleId] 
         * @param {any | null} [reasonId] 
         * @param {any | null} [closedByUserId] 
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllReportsReportsGet(status?: any | null, articleId?: any | null, reasonId?: any | null, closedByUserId?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: any): AxiosPromise<ReportListResponseSchemaInput> {
            return localVarFp.getAllReportsReportsGet(status, articleId, reasonId, closedByUserId, page, perPage, sortBy, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns the list of comments
         * @param {any} reportId 
         * @param {any | null} [senderId] 
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsReportsReportIdCommentsGet(reportId: any, senderId?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: any): AxiosPromise<CommentListResponseSchemaInput> {
            return localVarFp.getCommentsReportsReportIdCommentsGet(reportId, senderId, page, perPage, sortBy, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns report by article id
         * @param {any} articleId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyReportsReportsArticleArticleIdGet(articleId: any, options?: any): AxiosPromise<ReportListResponseSchemaInput> {
            return localVarFp.getMyReportsReportsArticleArticleIdGet(articleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Returns report by its id
         * @param {any} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportReportsReportIdGet(reportId: any, options?: any): AxiosPromise<ReportResponseSchemaInput> {
            return localVarFp.getReportReportsReportIdGet(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Refunds open report
         * @param {any} reportId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refundTranslationReportsReportIdRefundPost(reportId: any, options?: any): AxiosPromise<Response200Schema> {
            return localVarFp.refundTranslationReportsReportIdRefundPost(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates comment text
         * @param {any} commentId 
         * @param {UpdateCommentSchema} updateCommentSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCommentReportsCommentsCommentIdPut(commentId: any, updateCommentSchema: UpdateCommentSchema, options?: any): AxiosPromise<CommentResponseSchemaInput> {
            return localVarFp.updateCommentReportsCommentsCommentIdPut(commentId, updateCommentSchema, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Updates report\'s text and returns updated object
         * @param {any} reportId 
         * @param {any} text 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateReportReportsReportIdPut(reportId: any, text: any, options?: any): AxiosPromise<ReportResponseSchemaInput> {
            return localVarFp.updateReportReportsReportIdPut(reportId, text, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 
     * @summary Closes open report
     * @param {any} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public closeReportReportsReportIdClosePost(reportId: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).closeReportReportsReportIdClosePost(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * **Required fields**: text
     * @summary Creates comment
     * @param {any} reportId 
     * @param {CreateCommentSchema} createCommentSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public createCommentReportsReportIdCommentsPost(reportId: any, createCommentSchema: CreateCommentSchema, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).createCommentReportsReportIdCommentsPost(reportId, createCommentSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Creates open report and returns created object
     * @param {CreateReportSchema} createReportSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public createReportReportsPost(createReportSchema: CreateReportSchema, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).createReportReportsPost(createReportSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Deletes comment
     * @param {any} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public deleteCommentReportsCommentsCommentIdDelete(commentId: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).deleteCommentReportsCommentsCommentIdDelete(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns list of all reports with filters and sorting applied
     * @param {any | null} [status] OPEN &#x3D; 1, CLOSED &#x3D; 2, REFUND &#x3D; 3
     * @param {any | null} [articleId] 
     * @param {any | null} [reasonId] 
     * @param {any | null} [closedByUserId] 
     * @param {any} [page] Page number (must be 1 or greater)
     * @param {any} [perPage] Number of items per page (must be 1 or greater)
     * @param {any | null} [sortBy] Sort by field
     * @param {any} [orderBy] Sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getAllReportsReportsGet(status?: any | null, articleId?: any | null, reasonId?: any | null, closedByUserId?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).getAllReportsReportsGet(status, articleId, reasonId, closedByUserId, page, perPage, sortBy, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns the list of comments
     * @param {any} reportId 
     * @param {any | null} [senderId] 
     * @param {any} [page] Page number (must be 1 or greater)
     * @param {any} [perPage] Number of items per page (must be 1 or greater)
     * @param {any | null} [sortBy] Sort by field
     * @param {any} [orderBy] Sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getCommentsReportsReportIdCommentsGet(reportId: any, senderId?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).getCommentsReportsReportIdCommentsGet(reportId, senderId, page, perPage, sortBy, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns report by article id
     * @param {any} articleId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getMyReportsReportsArticleArticleIdGet(articleId: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).getMyReportsReportsArticleArticleIdGet(articleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Returns report by its id
     * @param {any} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public getReportReportsReportIdGet(reportId: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).getReportReportsReportIdGet(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Refunds open report
     * @param {any} reportId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public refundTranslationReportsReportIdRefundPost(reportId: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).refundTranslationReportsReportIdRefundPost(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates comment text
     * @param {any} commentId 
     * @param {UpdateCommentSchema} updateCommentSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public updateCommentReportsCommentsCommentIdPut(commentId: any, updateCommentSchema: UpdateCommentSchema, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).updateCommentReportsCommentsCommentIdPut(commentId, updateCommentSchema, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Updates report\'s text and returns updated object
     * @param {any} reportId 
     * @param {any} text 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public updateReportReportsReportIdPut(reportId: any, text: any, options?: AxiosRequestConfig) {
        return ReportApiFp(this.configuration).updateReportReportsReportIdPut(reportId, text, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the mail subscription settings for the current user.  Args:     user_info (UserInfo): The authenticated user\'s information extracted                           from the JWT token, providing access to                           `user_id`.     manager (SubscriptionManager): Dependency that handles the logic for                                    fetching and updating subscription                                    settings.  Returns:     SubscriptionResponseSchema: The user\'s subscription settings wrapped                                 in a standardized response format.  Raises:     HTTPException: If the user information or settings data is invalid                    (400 Bad Request).     HTTPException: If an unexpected error occurs during processing                    (500 Internal Server Error).
         * @summary Get user mail subscription settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionSettingsSettingsSubscriptionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the mail subscription settings for the current user.  Args:     settings_data (SubscriptionSettingsOutSchema): The new subscription                                                    settings provided by                                                    the user.     user_info (UserInfo): The authenticated user\'s information extracted                           from the JWT token, providing access to                           `user_id`.     manager (SubscriptionManager): Dependency that handles the logic for                                    fetching and updating subscription                                    settings.  Returns:     SubscriptionResponseSchema: The updated subscription settings wrapped                                 in a standardized response format.  Raises:     HTTPException: If the user information or settings data is invalid                    (400 Bad Request).     HTTPException: If an unexpected error occurs during processing                    (500 Internal Server Error).
         * @summary Update user mail subscription settings
         * @param {SubscriptionSettingsOutSchema} subscriptionSettingsOutSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionSettingsSettingsSubscriptionsPut: async (subscriptionSettingsOutSchema: SubscriptionSettingsOutSchema, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'subscriptionSettingsOutSchema' is not null or undefined
            assertParamExists('updateSubscriptionSettingsSettingsSubscriptionsPut', 'subscriptionSettingsOutSchema', subscriptionSettingsOutSchema)
            const localVarPath = `/settings/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionSettingsOutSchema, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the mail subscription settings for the current user.  Args:     user_info (UserInfo): The authenticated user\'s information extracted                           from the JWT token, providing access to                           `user_id`.     manager (SubscriptionManager): Dependency that handles the logic for                                    fetching and updating subscription                                    settings.  Returns:     SubscriptionResponseSchema: The user\'s subscription settings wrapped                                 in a standardized response format.  Raises:     HTTPException: If the user information or settings data is invalid                    (400 Bad Request).     HTTPException: If an unexpected error occurs during processing                    (500 Internal Server Error).
         * @summary Get user mail subscription settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionSettingsSettingsSubscriptionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionSettingsSettingsSubscriptionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the mail subscription settings for the current user.  Args:     settings_data (SubscriptionSettingsOutSchema): The new subscription                                                    settings provided by                                                    the user.     user_info (UserInfo): The authenticated user\'s information extracted                           from the JWT token, providing access to                           `user_id`.     manager (SubscriptionManager): Dependency that handles the logic for                                    fetching and updating subscription                                    settings.  Returns:     SubscriptionResponseSchema: The updated subscription settings wrapped                                 in a standardized response format.  Raises:     HTTPException: If the user information or settings data is invalid                    (400 Bad Request).     HTTPException: If an unexpected error occurs during processing                    (500 Internal Server Error).
         * @summary Update user mail subscription settings
         * @param {SubscriptionSettingsOutSchema} subscriptionSettingsOutSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscriptionSettingsSettingsSubscriptionsPut(subscriptionSettingsOutSchema: SubscriptionSettingsOutSchema, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscriptionSettingsSettingsSubscriptionsPut(subscriptionSettingsOutSchema, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * Retrieve the mail subscription settings for the current user.  Args:     user_info (UserInfo): The authenticated user\'s information extracted                           from the JWT token, providing access to                           `user_id`.     manager (SubscriptionManager): Dependency that handles the logic for                                    fetching and updating subscription                                    settings.  Returns:     SubscriptionResponseSchema: The user\'s subscription settings wrapped                                 in a standardized response format.  Raises:     HTTPException: If the user information or settings data is invalid                    (400 Bad Request).     HTTPException: If an unexpected error occurs during processing                    (500 Internal Server Error).
         * @summary Get user mail subscription settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionSettingsSettingsSubscriptionsGet(options?: any): AxiosPromise<SubscriptionResponseSchema> {
            return localVarFp.getSubscriptionSettingsSettingsSubscriptionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Update the mail subscription settings for the current user.  Args:     settings_data (SubscriptionSettingsOutSchema): The new subscription                                                    settings provided by                                                    the user.     user_info (UserInfo): The authenticated user\'s information extracted                           from the JWT token, providing access to                           `user_id`.     manager (SubscriptionManager): Dependency that handles the logic for                                    fetching and updating subscription                                    settings.  Returns:     SubscriptionResponseSchema: The updated subscription settings wrapped                                 in a standardized response format.  Raises:     HTTPException: If the user information or settings data is invalid                    (400 Bad Request).     HTTPException: If an unexpected error occurs during processing                    (500 Internal Server Error).
         * @summary Update user mail subscription settings
         * @param {SubscriptionSettingsOutSchema} subscriptionSettingsOutSchema 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionSettingsSettingsSubscriptionsPut(subscriptionSettingsOutSchema: SubscriptionSettingsOutSchema, options?: any): AxiosPromise<SubscriptionResponseSchema> {
            return localVarFp.updateSubscriptionSettingsSettingsSubscriptionsPut(subscriptionSettingsOutSchema, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * Retrieve the mail subscription settings for the current user.  Args:     user_info (UserInfo): The authenticated user\'s information extracted                           from the JWT token, providing access to                           `user_id`.     manager (SubscriptionManager): Dependency that handles the logic for                                    fetching and updating subscription                                    settings.  Returns:     SubscriptionResponseSchema: The user\'s subscription settings wrapped                                 in a standardized response format.  Raises:     HTTPException: If the user information or settings data is invalid                    (400 Bad Request).     HTTPException: If an unexpected error occurs during processing                    (500 Internal Server Error).
     * @summary Get user mail subscription settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getSubscriptionSettingsSettingsSubscriptionsGet(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getSubscriptionSettingsSettingsSubscriptionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update the mail subscription settings for the current user.  Args:     settings_data (SubscriptionSettingsOutSchema): The new subscription                                                    settings provided by                                                    the user.     user_info (UserInfo): The authenticated user\'s information extracted                           from the JWT token, providing access to                           `user_id`.     manager (SubscriptionManager): Dependency that handles the logic for                                    fetching and updating subscription                                    settings.  Returns:     SubscriptionResponseSchema: The updated subscription settings wrapped                                 in a standardized response format.  Raises:     HTTPException: If the user information or settings data is invalid                    (400 Bad Request).     HTTPException: If an unexpected error occurs during processing                    (500 Internal Server Error).
     * @summary Update user mail subscription settings
     * @param {SubscriptionSettingsOutSchema} subscriptionSettingsOutSchema 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateSubscriptionSettingsSettingsSubscriptionsPut(subscriptionSettingsOutSchema: SubscriptionSettingsOutSchema, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).updateSubscriptionSettingsSettingsSubscriptionsPut(subscriptionSettingsOutSchema, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a list of transactions according to status
         * @summary Retrieves a list of transactions
         * @param {any | null} [status] Filter by transaction status
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsTransactionsGet: async (status?: any | null, page?: any, perPage?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/transactions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a list of transactions according to status
         * @summary Retrieves a list of transactions
         * @param {any | null} [status] Filter by transaction status
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTransactionsTransactionsGet(status?: any | null, page?: any, perPage?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionListDataResponseSchemaInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTransactionsTransactionsGet(status, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionApiFp(configuration)
    return {
        /**
         * Retrieves a list of transactions according to status
         * @summary Retrieves a list of transactions
         * @param {any | null} [status] Filter by transaction status
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTransactionsTransactionsGet(status?: any | null, page?: any, perPage?: any, options?: any): AxiosPromise<TransactionListDataResponseSchemaInput> {
            return localVarFp.getTransactionsTransactionsGet(status, page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * Retrieves a list of transactions according to status
     * @summary Retrieves a list of transactions
     * @param {any | null} [status] Filter by transaction status
     * @param {any} [page] Page number (must be 1 or greater)
     * @param {any} [perPage] Number of items per page (must be 1 or greater)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public getTransactionsTransactionsGet(status?: any | null, page?: any, perPage?: any, options?: AxiosRequestConfig) {
        return TransactionApiFp(this.configuration).getTransactionsTransactionsGet(status, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TranslationApi - axios parameter creator
 * @export
 */
export const TranslationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves a list of translations.
         * @summary Retrieves a list of translations.
         * @param {any | null} [promptId] Filter by prompt ID
         * @param {any | null} [configId] Filter by config ID
         * @param {any | null} [targetLanguage] Filter by target language
         * @param {any | null} [sourceLanguage] Filter by source language
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationsTranslationGet: async (promptId?: any | null, configId?: any | null, targetLanguage?: any | null, sourceLanguage?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/translation/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWTBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (promptId !== undefined) {
                localVarQueryParameter['prompt_id'] = promptId;
            }

            if (configId !== undefined) {
                localVarQueryParameter['config_id'] = configId;
            }

            if (targetLanguage !== undefined) {
                localVarQueryParameter['target_language'] = targetLanguage;
            }

            if (sourceLanguage !== undefined) {
                localVarQueryParameter['source_language'] = sourceLanguage;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sort_by'] = sortBy;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['order_by'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationApi - functional programming interface
 * @export
 */
export const TranslationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TranslationApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves a list of translations.
         * @summary Retrieves a list of translations.
         * @param {any | null} [promptId] Filter by prompt ID
         * @param {any | null} [configId] Filter by config ID
         * @param {any | null} [targetLanguage] Filter by target language
         * @param {any | null} [sourceLanguage] Filter by source language
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslationsTranslationGet(promptId?: any | null, configId?: any | null, targetLanguage?: any | null, sourceLanguage?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TranslationListResponseSchema>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTranslationsTranslationGet(promptId, configId, targetLanguage, sourceLanguage, page, perPage, sortBy, orderBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TranslationApi - factory interface
 * @export
 */
export const TranslationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TranslationApiFp(configuration)
    return {
        /**
         * Retrieves a list of translations.
         * @summary Retrieves a list of translations.
         * @param {any | null} [promptId] Filter by prompt ID
         * @param {any | null} [configId] Filter by config ID
         * @param {any | null} [targetLanguage] Filter by target language
         * @param {any | null} [sourceLanguage] Filter by source language
         * @param {any} [page] Page number (must be 1 or greater)
         * @param {any} [perPage] Number of items per page (must be 1 or greater)
         * @param {any | null} [sortBy] Sort by field
         * @param {any} [orderBy] Sort order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslationsTranslationGet(promptId?: any | null, configId?: any | null, targetLanguage?: any | null, sourceLanguage?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: any): AxiosPromise<TranslationListResponseSchema> {
            return localVarFp.getTranslationsTranslationGet(promptId, configId, targetLanguage, sourceLanguage, page, perPage, sortBy, orderBy, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TranslationApi - object-oriented interface
 * @export
 * @class TranslationApi
 * @extends {BaseAPI}
 */
export class TranslationApi extends BaseAPI {
    /**
     * Retrieves a list of translations.
     * @summary Retrieves a list of translations.
     * @param {any | null} [promptId] Filter by prompt ID
     * @param {any | null} [configId] Filter by config ID
     * @param {any | null} [targetLanguage] Filter by target language
     * @param {any | null} [sourceLanguage] Filter by source language
     * @param {any} [page] Page number (must be 1 or greater)
     * @param {any} [perPage] Number of items per page (must be 1 or greater)
     * @param {any | null} [sortBy] Sort by field
     * @param {any} [orderBy] Sort order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationApi
     */
    public getTranslationsTranslationGet(promptId?: any | null, configId?: any | null, targetLanguage?: any | null, sourceLanguage?: any | null, page?: any, perPage?: any, sortBy?: any | null, orderBy?: any, options?: AxiosRequestConfig) {
        return TranslationApiFp(this.configuration).getTranslationsTranslationGet(promptId, configId, targetLanguage, sourceLanguage, page, perPage, sortBy, orderBy, options).then((request) => request(this.axios, this.basePath));
    }
}



