export class TokenService {
    /* Save a token in Local Storage
     *
     * @param {string} token
     */

    static storage = localStorage

    static setStorage(remember = false) {
        TokenService.storage = remember ? localStorage : sessionStorage
    }

    static setToken(token: string) {
        TokenService.storage.setItem('token', token)
    }

    static setRefreshedToken(token: string) {
        TokenService.storage.setItem('refresh-token', token)
    }

    static setIsEmulation() {
        TokenService.storage.setItem('_EdsSd5__dd', 'true')
    }

    static isTokenExists() {
        return TokenService.storage.getItem('token') !== null
    }

    static isTokenExistsSessionStorage() {
        return sessionStorage.getItem('token') !== null
    }

    static isRefreshedTokenExists() {
        return TokenService.storage.getItem('refresh-token') !== null
    }

    static isContinueUriExists() {
        return TokenService.storage.getItem('continue-uri') !== null
    }

    static isEmulationExists() {
        return (
            TokenService.storage.getItem('_EdsSd5__dd') !== null &&
            JSON.parse(TokenService.storage.getItem('_EdsSd5__dd') as string) !== false
        )
    }

    /* Remove a token from Local Storage.
     *
     */
    static removeToken() {
        TokenService.storage.removeItem('token')
    }

    static removeRefreshedToken() {
        TokenService.storage.removeItem('refresh-token')
    }

    static removeIsEmulation() {
        TokenService.storage.removeItem('_EdsSd5__dd')
    }

    /**
     * Get a token value.
     *
     * @returns {string}
     */

    static getToken() {
        return TokenService.storage.getItem('token') || ''
    }

    static getRefreshedToken() {
        return TokenService.storage.getItem('refresh-token') || ''
    }

    static getContinueUri() {
        return TokenService.storage.getItem('continur-uri') || ''
    }

    static getIsEmulation() {
        return JSON.parse(TokenService.storage.getItem('_EdsSd5__dd') as string) || ''
    }
}
