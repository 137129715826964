import { createSlice, PayloadAction } from '@reduxjs/toolkit'; 
import { fetchArticleById } from './actions';
import { IBaseStateField, IResponse } from '@/types/type'; 

interface ArticleType {
  id: number;
  title: string;
  content: string;
}

interface ArticleState extends IBaseStateField {
  article: ArticleType | null;
  errorMessage: string | null;
}

const initialState: ArticleState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  article: null,
  errorMessage: null,
};

export const articleSlice = createSlice({
  name: 'article',
  initialState,
  reducers: {
    resetArticleState: (state) => {
      state.article = null;
      state.isError = false;
      state.isSuccess = false;
      state.errorMessage = null;
    },
  },
  extraReducers: {
    [fetchArticleById.pending.type]: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = null;
    },
    [fetchArticleById.fulfilled.type]: (state, action: PayloadAction<IResponse<ArticleType>>) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.article = action.payload.result;
    },
    [fetchArticleById.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message || 'Ошибка при получении статьи';
    },
  },
});

export const { resetArticleState } = articleSlice.actions;
export default articleSlice.reducer;