import {FC, useEffect} from 'react'
import {Navigate} from 'react-router-dom'
import {Center, Loader} from '@mantine/core'
import {showNotification} from '@mantine/notifications'

import {AppPaths} from './routes-enums'

import {logoutUser} from '@/store/entities/auth/authSlice'
import {useAppDispatch, useAppSelector} from '@/store/hooks'

interface Props {
    children: JSX.Element
}

export const PrivateRoutes: FC<Props> = ({children}) => {
    const dispatch = useAppDispatch()
    const {isLoading, token} = useAppSelector(
        (state) => state.auth
    )


    if (isLoading) {
        return (
            <Center style={{height: '100vh'}}>
                <Loader variant='dots' />
            </Center>
        )
    }

    if (!token) {
        dispatch(logoutUser())
    }

    return token ? children : <Navigate to={AppPaths.LOGIN} />
}
