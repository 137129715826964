import {IBalance} from '@/store/actionTypes/balance'
import {IBaseStateField, IErrorType} from '@/types/type'
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getBalance} from './actions'

export interface BalanceState extends IBaseStateField {
    balance: number
    message: null | string
}

const initialState: BalanceState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    balance: 0,
    message: null,
}

export const balanceSlice = createSlice({
    name: 'balance',
    initialState,
    reducers: {},
    extraReducers: {
        // Получение баланса
        [getBalance.pending.type]: (state) => {
            state.isLoading = true
        },
        [getBalance.fulfilled.type]: (state, action: PayloadAction<IBalance>) => {
            state.isLoading = false
            state.isSuccess = true
            state.balance = action.payload?.data?.balance.token_balance
        },
        [getBalance.rejected.type]: (state, action) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload?.message || 'Failed to get a balance'
        },
    },
})

export const {} = balanceSlice.actions

export default balanceSlice.reducer
