import { createAsyncThunk } from '@reduxjs/toolkit';
import { Api } from '@/api/apiService';

export const createArticle = createAsyncThunk(
  'articles/createArticle',
  async (articleData: { title: string; text: string }, thunkAPI) => {
    try {
      const response = await Api.Articles.createArticleArticlesPost(articleData);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue('Ошибка при создании статьи');
    }
  }
);