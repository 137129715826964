import {createAsyncThunk} from '@reduxjs/toolkit'
import {AxiosError} from 'axios'

import {Api} from '@/api/apiService'

export const getPaymentMethod = createAsyncThunk('/payment-methods/', async (_, thunkAPI) => {
    try {
        const response = await Api.PaymentMethods.getPaymentMethodsListPaymentMethodsGet()
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})