
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

import {
    getItems
} from './actions'

import { IProduct, IProductsResponse, IProductsState } from '@/store/actionTypes/products'

const initialState: IProductsState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: null,
    products: [],
    pagination: null,
    loadedProductsPages: [],
}

export const productsSlice = createSlice({
    name: 'items',
    initialState,
    reducers: {
    },
    extraReducers: {
        // Взять список товаров
        [getItems.pending.type]: (state) => {
            state.isLoading = true
            state.isError = false
        },
        [getItems.fulfilled.type]: (state, action: PayloadAction<IProductsResponse<IProduct>>) => {
            state.isLoading = false
            state.isSuccess = true
            state.isError = false
            state.products = action.payload.data.list
            state.pagination = action.payload.pagination
        },
        [getItems.rejected.type]: (state) => {
            state.isError = true
            state.isLoading = false
            state.isSuccess = false
        },
    },
})

export default productsSlice.reducer
