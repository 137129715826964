import {FC, ReactNode} from 'react'
import {Box, Grid} from '@mantine/core'

import LayoutPublic from '@/ui/LayoutPublic/LayoutPublic'

import {useStyles} from './styles'

interface ICenterPage {
    children: ReactNode,
}

const CenterPage: FC<ICenterPage> = props => {
    const {classes} = useStyles()

    return(
        <LayoutPublic padding={0}>
            <Grid gutter={0} mih='100%'>
                <Grid.Col span={12} className={classes.formCol}>
                    <Box w='100%' maw={600} mx='auto'>
                        {props.children}
                    </Box>
                </Grid.Col>
            </Grid>
        </LayoutPublic>
    )
}

export default CenterPage
