import {showNotification} from '@mantine/notifications'
import {createSlice} from '@reduxjs/toolkit'

// import {
//     getDashboardGain,
//     getDashboardNewClients,
//     getDashboardReturns,
//     getDashboardStats,
// } from './actions'
import {IDashboardState} from '@/store/actionTypes/dashboard'

const initialState: IDashboardState = {
    isStatsLoading: false,
    stats: null,

    isGainLoading: false,
    gain: null,

    isReturnsLoading: false,
    returns: null,

    isNewClientsLoading: false,
    newClients: null,
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: {
        // getDashboardStats
        // [getDashboardStats.pending.type]: (state) => {
        //     state.isStatsLoading = true
        // },
        // [getDashboardStats.fulfilled.type]: (state, action) => {
        //     state.isStatsLoading = false
        //     state.stats = action.payload.result
        // },
        // [getDashboardStats.rejected.type]: (state, action) => {
        //     state.isStatsLoading = false
        //     showNotification({
        //         message: action.payload || 'System error',
        //         color: 'red',
        //     })
        // },

        // // getDashboardGain
        // [getDashboardGain.pending.type]: (state) => {
        //     state.isGainLoading = true
        // },
        // [getDashboardGain.fulfilled.type]: (state, action) => {
        //     state.isGainLoading = false
        //     state.gain = action.payload.result
        // },
        // [getDashboardGain.rejected.type]: (state, action) => {
        //     state.isGainLoading = false
        //     showNotification({
        //         message: action.payload || 'System error',
        //         color: 'red',
        //     })
        // },

        // // getDashboardReturns
        // [getDashboardReturns.pending.type]: (state) => {
        //     state.isReturnsLoading = true
        // },
        // [getDashboardReturns.fulfilled.type]: (state, action) => {
        //     state.isReturnsLoading = false
        //     state.returns = action.payload.result
        // },
        // [getDashboardReturns.rejected.type]: (state, action) => {
        //     state.isReturnsLoading = false
        //     showNotification({
        //         message: action.payload || 'System error',
        //         color: 'red',
        //     })
        // },

        // // getDashboardNewClients
        // [getDashboardNewClients.pending.type]: (state) => {
        //     state.isNewClientsLoading = true
        // },
        // [getDashboardNewClients.fulfilled.type]: (state, action) => {
        //     state.isNewClientsLoading = false
        //     state.newClients = action.payload.result
        // },
        // [getDashboardNewClients.rejected.type]: (state, action) => {
        //     state.isNewClientsLoading = false
        //     showNotification({
        //         message: action.payload || 'System error',
        //         color: 'red',
        //     })
        // },
    },
})

export default dashboardSlice
