import {FC, FormEvent, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {
    Anchor,
    Button,
    Divider,
    Group,
    Paper,
    PasswordInput,
    Text,
    TextInput,
    ThemeIcon,
} from '@mantine/core'
import {useForm} from '@mantine/form'
import {showNotification} from '@mantine/notifications'
import {BrandFacebook, BrandGithub, BrandGoogle, BrandInstagram, Mail} from 'tabler-icons-react'

import {regexp} from '@/utils/regexpUtils'

import {AppPaths} from '@/routes/routes-enums'

import {useActions, useAppSelector} from '@/store/hooks'

interface FormValues {
    email: string
    password: string
    repeat_password: string
}

const RegistrationForm: FC = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {registration, setVerifyEmail} = useActions()
    const {registrationState} = useAppSelector(store => store.auth)
    const form = useForm<FormValues>({
        initialValues: {
            email: '',
            password: '',
            repeat_password: '',
        },

        validate: {
            email: (value) => {
                if (value.length === 0) return  t('Signup.AlertMessage.Email should not be empty')
                if(!value.match(regexp.email)) return t('Signup.AlertMessage.Incorrect email')
                return null
            },
            password: (value) => (value.length > 0 ? null : t('Signup.AlertMessage.The password must not be empty')),
            repeat_password: (value, values) => {
                if (value.length === 0) return t('Signup.AlertMessage.Enter the password again')
                if (values.password !== value) return t('Signup.AlertMessage.Passwords don\'t match')
                return null
            }
        },
    })

    const handleRegistration = async (values: FormValues, event: FormEvent) => {
        event.preventDefault()
        setVerifyEmail({ email: values.email })
        registration(values)
    }

    useEffect(() => {
        if (registrationState.isError){
            showNotification({message: t('Signup.AlertMessage.Registration error'), color: 'red'})
        } else if(registrationState.isSuccess){
            showNotification({message: t('Signup.AlertMessage.Successfully'), color: 'green'})
            navigate(AppPaths.CONFIRM)
        }
    }, [registrationState.isError, registrationState.isSuccess])

    return(
        <Paper radius='md' p='xl' withBorder>
            <Text size='lg' fw={500}>
                {t('Signup.Register with')}
            </Text>
            <Group grow mb='md' mt='md'>
                <Button variant='outline' radius='xl' leftIcon={<BrandGithub size={18} />}>
                    Github
                </Button>
                <Button variant='outline' radius='xl' leftIcon={<BrandGoogle size={18} />}>
                    Google
                </Button>
                <Button variant='outline' radius='xl' leftIcon={<BrandFacebook size={18} />}>
                    Facebook
                </Button>
                <Button variant='outline' radius='xl' leftIcon={<BrandInstagram size={18} />}>
                    Instagram
                </Button>
            </Group>
            <Divider label={t('Signup.Or continue with phone number, email')} labelPosition='center' my='lg' />
            <form onSubmit={form.onSubmit((values, event) => handleRegistration(values, event))}>
                <TextInput
                    label={t('Signup.Email')}
                    {...form.getInputProps('email')}
                    size='md'
                    rightSection={
                        <ThemeIcon
                            variant='light'
                            size={'sm'}
                            style={{backgroundColor: 'transparent', color: '#495057'}}
                        >
                            <Mail strokeWidth={1} />
                        </ThemeIcon>
                    }
                    mt={15}
                />
                <PasswordInput
                    mt={12}
                    label={t('Signup.Password')}
                    {...form.getInputProps('password')}
                    size='md'
                />
                <PasswordInput
                    mt={12}
                    label={t('Signup.Repeat password')}
                    {...form.getInputProps('repeat_password')}
                    size='md'
                />
                <Group mt='xl' position='apart'>
                    <Anchor
                        component='button'
                        type='button'
                        c='dimmed'
                        onClick={() => navigate(AppPaths.LOGIN)}
                        size='xs'
                    >
                        {t('Signup.Already have account? Sign in')}
                    </Anchor>
                    <Button type='submit' radius='xl' loading={registrationState.isLoading}>
                        {t('Signup.Register')}
                    </Button>
                </Group>
            </form>
        </Paper>
    )
}

export default RegistrationForm
