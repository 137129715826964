import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import { Api } from "@/api/apiService";
import { PurchaseItemSchema } from "@/api/swaggerApi/translator";

export const purchaseItem = createAsyncThunk('/purchase/', async (arg: PurchaseItemSchema, thunkAPI) => {
    try {
        const response = await Api.Purchase.purchaseItemPurchasePost({
            price_id: arg.price_id,
            amount: arg.amount
        })
        console.log(response.data)
        return response.data
    } catch (e) {
        const error = e as AxiosError
        if (error.response?.data) {
            return thunkAPI.rejectWithValue(error.response.data)
        } else {
            return thunkAPI.rejectWithValue(error.response?.statusText)
        }
    }
})