import {initReactI18next} from 'react-i18next'
import i18n from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'

import translationRU from '@/assets/locales/ru/translation.json'

const resources = {
    ru: {translation: translationRU},
}

i18n.use(I18nextBrowserLanguageDetector)
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        resources,
        fallbackLng: 'ru',
        supportedLngs: Object.keys(resources),

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

export default i18n
