import {authActions} from './entities/auth'
import {balanceActions} from './entities/balance'
// import {clientsActions} from './entities/clients/clients'
// import {dashboardActions} from './entities/dashboard'
// import {documentsActions} from './entities/documents'
// import {faqActionsCombine} from './entities/faq'
import {layoutActions} from './entities/layout'
// import { marginsActions } from './entities/margins'
// import {reasonsActions} from './entities/reasons'
// import {settingsActions} from './entities/settings'
// import {statusesActions} from './entities/statuses'
// import {usersActions} from './entities/users'
import { fetchArticleById } from './entities/articles/article/actions';
import { createArticle } from './entities/articles/article-form/actions';
import { fetchArticles } from './entities/articles/article-list/actions';

// import {categoriesActions} from '@/store/entities/categories/categories'
// import {categoryActions} from '@/store/entities/categories/category'
// import {clientActions} from '@/store/entities/clients/client'
// import {discountsActions} from '@/store/entities/discounts/discounts'
// import {exportDataActions} from '@/store/entities/exportData'
// import {importDataActions} from '@/store/entities/importData'
// import {notificationsActions} from '@/store/entities/notifications'
// import {ordersActionsCombine} from '@/store/entities/orders'
import { purchaseActions } from './entities/purchase'

import { paymentsActions } from '@/store/entities/payments'
import {productsActionsCombine} from '@/store/entities/products'
// import {promocodesActions} from '@/store/entities/promocodes/promocodes'
// import {rolesActionsCombine} from '@/store/entities/roles'
// import {rubricatorActions} from '@/store/entities/rubricator'
// import {shiftActionsCombine} from '@/store/entities/shifts'
// import {shopActions} from '@/store/entities/shops/shop'
// import {shopsActions} from '@/store/entities/shops/shops'

export default {
    // ...usersActions,
    ...authActions,
    fetchArticleById,
    createArticle,
    fetchArticles,
    ...balanceActions,
    // ...clientsActions,
    // ...clientActions,
    ...productsActionsCombine,
    // ...rubricatorActions,
    // ...shopsActions,
    // ...shopActions,
    // ...categoriesActions,
    // ...shiftActionsCombine,
    // ...categoryActions,
    // ...ordersActionsCombine,
    ...paymentsActions,
    ...purchaseActions,
    // ...promocodesActions,
    // ...discountsActions,
    // ...notificationsActions,
    // ...reasonsActions,
    // ...documentsActions,
    // ...statusesActions,
    // ...faqActionsCombine,
    // ...settingsActions,
    // ...dashboardActions,
    // ...importDataActions,
    // ...exportDataActions,
    // ...rolesActionsCombine,
    ...layoutActions,
    // ...marginsActions,
}
