import React, { FC, useState } from 'react';
import { useActions, useAppSelector } from '@/store/hooks';
import styles from './ArticleForm.module.scss';
import { useTranslation } from 'react-i18next';

const ArticleForm: FC = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const { createArticle } = useActions();
  const { isLoading, isError, errorMessage, isSuccess } = useAppSelector((state) => state.articles.articleForm);
  const [successMessage, setSuccessMessage] = useState('');
  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setSuccessMessage('');
    createArticle({ title, text: content });

    // Установка сообщения об успехе будет происходить через Redux
  };

  return (
    <div className={styles.articleForm}>
      <h2 className={styles.titleArticleForm}>{t('articleForm.title')}</h2>
      <form onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <input
            className={styles.articleFormInput}
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <label htmlFor="title" className={`${styles.label} ${title ? styles.inputActive : ''}`}>
            {t('articleForm.titleLabel')}
          </label>
        </div>

        <div className={styles.formGroup}>
          <textarea
            className={styles.articleFormTextarea}
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            required
          />
          <label
            htmlFor="content"
            className={`${styles.label} ${styles.textareaLabel} ${content ? styles.inputActive : ''}`}
          >
            {t('articleForm.contentLabel')}
          </label>
        </div>

        <button type="submit" className={styles.linkButton} disabled={isLoading}>
          {isLoading ? t('articleForm.sending') : t('articleForm.create')}
        </button>
      </form>

      {isSuccess && <p className={styles.success}>{t('articleForm.successMessage')}</p>}
      {isError && <p className={styles.error}>{errorMessage || t('articleForm.errorMessage')}</p>}
    </div>
  );
};

export default ArticleForm;